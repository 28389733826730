import * as React from 'react';
import { AddressForm, FormattedMarkdown } from '@decodedhealth/react-library';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PageContainer from '../common/PageContainer';
import { ScrollableQuinnContainer } from '../common/ScrollableContainer';
import { notificationService } from '../../utils/notification';
import { TextH5Standard } from '../shared/Typography';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: '1em',
    color: theme.palette.secondary.main,
  },
}));

const Address = ({
  isLoading,
  doSubmit,
  handleBack,
  additionalFields,
  code,
  updateAddressChange,
  ...rest
}) => {
  const classes = useStyles();

  function onSubmit(newState) {
    if (!newState.addressPostcode || newState.addressPostcode.trim().length === 0) {
      notificationService.error('Please enter a valid zipcode.');
    }

    if (newState.addressCountry === 'US' && newState.addressPostcode.trim().length !== 5) {
      notificationService.error('Please enter a 5 digit zipcode.');
      return;
    }

    doSubmit(newState);
  }

  return (
    <PageContainer loading={isLoading} onBack={handleBack}>
      <ScrollableQuinnContainer
        messageId="registration.user.bubble.two"
        message={'Please enter the patients home address.'}
      >
        <AddressForm
          enableLine2OptionalField
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          updateAddressData={updateAddressChange}
          addressDefaultvalue={{
            addressLine1: rest.addressLine1,
            addressLine2: rest.addressLine2,
            addressAdministrativeArea: rest.addressAdministrativeArea,
            addressCity: rest.addressCity,
            addressPostcode: rest.addressPostcode,
            addressCountry: rest.addressCountry,
          }}
          heading={
            <TextH5Standard orange sx={{ paddingTop: '1em' }}>
              <FormattedMarkdown
                id="registration.user.step.two"
                defaultMessage="2 of 2 Personal Information"
              />
            </TextH5Standard>
          }
          additionalFields={additionalFields}
          isLoading={isLoading}
          doSubmit={onSubmit}
        />
      </ScrollableQuinnContainer>
    </PageContainer>
  );
};

export default Address;
