import * as React from 'react';
import {Wizard, Loading} from '../../shared-library';
import BookingWizard from './BookingWizard/BookingWizard';
import {
    LOGIN_ROUTE,
    routeUtil,
} from '../../utils/route.name';
import {CheckSession, SetSession, uriStorage} from '../../utils/storage';
import 'firebase/auth';
import {logger} from '../../utils/logging';
import {useEffect, useState} from 'react';
import {globalBloc} from '../global.bloc';
import useBookingBloc from './bloc/useBookingBloc';
import {isVirtual, isWalkin} from './bloc/helpers.bloc'


export const Booking = ({match, history}) => {
        const {appointmentType} = match.params;
        const {orgSelected, booking} = globalBloc.subject.value;
        const apptID = booking.appointmentId || match.params.draftId;
        const [goBack, setGoBack] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        const bookingState = useBookingBloc(apptID, appointmentType, history);


        useEffect(() => {
            logger.info('Booking has mounted');

            //--> prevents user from going back
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                window.history.go(1);
            };

            return () => {
                uriStorage.clearPath(true);
                logger.info('Booking has unmounted');
            };
        }, []);

        useEffect(() => {
            setIsLoading(bookingState?.subject?.value?.loadingData);
        }, [bookingState]);


        const handleBack = React.useCallback(() => {
            uriStorage.clearPath(true);
            history.goBack();
        }, [history]);

        const handleTimeSelect = () => {
            sessionStorage.setItem('appt', apptID);
            sessionStorage.setItem('action', 'login_after_setting_org');


            history.push(LOGIN_ROUTE);
        };


        const onSubmit = async () => {
            sessionStorage.setItem('appt', apptID);
            bookingState.bookingBloc.__updateSubject({
                loadingData: true,
            });
            try {
                const response = await bookingState.bookingBloc.confirmAppointment();
                bookingState.bookingBloc.__updateSubject({
                    loadingData: false,
                });
                if (isVirtual(bookingState.bookingBloc)) {
                    history.push(routeUtil.buildAppointmentCheckinConfirmation(apptID));
                } else if (isWalkin(bookingState.bookingBloc)) {
                    history.push(routeUtil.buildBookingIdentityDocument(apptID));
                } else {
                    SetSession('task', 'confirm_booking');
                    history.push(
                        routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
                            sessionStorage.getItem('appt'),
                            'IN_PERSON',
                        ),
                    );
                }
            } catch (e) {
                logger.error('Error trying to confirm reservation: ', e.message);
                bookingState.bookingBloc.__updateSubject({
                    loadingData: false,
                });
                globalBloc.updateBooking({
                    currentStep: "booking_slot"
                })
                setGoBack(true);
            }
        };


        if (!bookingState?.steps) {
            console.log({bloc: bookingState.bookingBloc.subject.value});
            return <Loading isFullscreen/>;
        }

        const startAtStep = CheckSession('task', 'confirm_booking') || booking.currentStep === 'booking_confirm' ? 2 : orgSelected || booking.currentStep === 'booking_slot' ? 1 : 0;

        return (
            <Wizard steps={bookingState.steps} startAtStep={startAtStep}>
                {!isLoading || bookingState.steps && bookingState.appointmentType ? (
                    <BookingWizard isGoBack={goBack} setGoBack={setGoBack} bloc={bookingState} onBack={handleBack}
                                   onSubmit={onSubmit} handleTimeSelect={handleTimeSelect}/>
                ) : (
                    <Loading isFullscreen/>
                )}
            </Wizard>
        );
    }
;
