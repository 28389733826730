import React, { useRef, useState } from 'react';
import { TextField, styled } from '@mui/material';
import { AnalyticsEvent, analyticsEventLogger } from './events';


const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'pinCodeFont',
})(({ theme, pinCodeFont }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '15px',
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
        },
    },
    '& .MuiInputLabel-outlined': {
        transform: 'translate(20px, 100%) translateY(-10%)',
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.8rem',
            transform: 'translate(20px, 100%) translateY(-70%)',

        },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(0px, -26px) scale(0.75)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '15px',
        marginTop: '5px',
        '& legend': {
            display: 'none',
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: '10px 24px',
        fontSize: pinCodeFont ? '3.5rem' : '1.8rem',
        textAlign: pinCodeFont ? 'center' : 'left',
    },
}));

const Container = styled('div')({
  position: 'relative',
  transition: 'all 0.3s',
  marginTop: '25px',
});

const DecodedTextField = React.forwardRef(
  ({ label, type, error, alwaysShrinkLabel, helperText, pinCodeFont = false, ...props }, ref) => {
    const labelContent = Boolean(error) ? helperText :  label;

    const [clickCount, setClickCount] = useState(0);
    const clickTimeout = useRef(null);
    const clickLimit = 5;
    const resetTime = 2000;

    const handleClick = () => {
        if (clickTimeout.current !== null) {
          clearTimeout(clickTimeout.current);
        }

        setClickCount((prevCount) => {
          const newCount = prevCount + 1;
          if (newCount > clickLimit) {
            console.error(`User clicked more than ${clickLimit}x @ ${label}!`);
            analyticsEventLogger.log(AnalyticsEvent.LOGIN_FORM_INPUT_ERROR, {
              reason: `User clicked more than ${clickLimit}x @ ${label}!`,
            });
          }
          return newCount;
        });

      clickTimeout.current = setTimeout(() => {
        setClickCount(0);
      }, resetTime);
    };

    return (
      <Container>
        <StyledTextField
          {...props}
          ref={ref}
          onClick={handleClick}
          autoComplete="off"
          type={type}
          pinCodeFont={pinCodeFont}
          fullWidth
          variant="outlined"
          error={Boolean(error)}
          label={labelContent}
          helperText={null}
          InputLabelProps={{
            ...props.InputLabelProps,
            shrink: alwaysShrinkLabel || props.InputLabelProps?.shrink,
          }}
        />
      </Container>
    );
  });

export default DecodedTextField;
