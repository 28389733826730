import {globalBloc} from '../../global.bloc'
import {appointmentApi} from '../../../utils/services/appointments.api'
import {LOGIN_ROUTE, routeUtil} from '../../../utils/route.name'
import {SetSession} from '../../../utils/storage'
import {notifyAndLog} from '../../../utils/services/notifyAndLog'
import {AnalyticsEvent, analyticsEventLogger} from '../../../utils/events'
import {BookingBlocEvent} from './events.bloc'
import {remoteSteps, walkinSteps} from '../stepOrchestrator'
import {notificationService} from '../../../utils/notification'


const handleGetInLinePreAuthBooking = async (bloc) => {
  try {
    await bloc.__saveAppointmentProgress('org-selector')
    await bloc.__saveAppointmentProgress('time-selector')
    //--> sets task to confirm_booking to start at confirm page on next booking step
    SetSession('task', 'confirm_booking')
  } catch (error) {
    console.log('handleGetInLinePreAuthBooking', error)
    notifyAndLog(
      'error',
      'Unable to save reservation details. Please try again and if the problem persists call the clinic.',
      AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR,
      error,
    )
  }
}


const handleSelectedOrgPreAuthBooking = async (bloc) => {
  console.log('handleSelectedOrgPreAuthBooking', bloc)
  try {
    await bloc.__saveAppointmentProgress('org-selector')
  } catch (error) {
    notifyAndLog(
      'error',
      'Unable to save reservation location. Please try again and if the problem persists call the clinic.',
      AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR,
      error,
    )
  }
}

const getAvailableOrganisations = (service, organisationId, bloc) => {
  return appointmentApi.getAvailableOrgs("ANY-INV")
    .then((res) => {
      const organisations = res.data.items
      bloc.__updateSubject({
        availableOrganisations: organisations.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
        ),
        steps: organisationId?.length > 0 ? walkinSteps(bloc) : remoteSteps(bloc),
      })

      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {
        locations: `${organisations.length}`,
      })
    })
    .catch((reason) => {
      notificationService.error(
        'Error loading available organisations for appointment type. Please refresh. If the problem persists please contact the clinic.',
      )
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {
        status: 'error',
        message: `${reason}`,
      })
    })
}

export const initialiseBooking = (appointmentId, organisationId, bloc) => {
  const {orgSelected, getInLine, manageAppointments, booking} = globalBloc.subject.value
  console.log('initialiseBooking')

  appointmentApi.getAppointmentStatus(appointmentId, manageAppointments ? 'isStatus' : null)
    .then((value) => {
      const appointment = value.data

      bloc.__updateSubject({appointment: appointment})

      if (getInLine && booking.currentStep !== 'booking_confirm') {
        handleGetInLinePreAuthBooking(bloc).then(() => {
          // globalBloc.updateBooking({currentStep: 'booking_confirm'})
          bloc.events.next({
            type: BookingBlocEvent.NAVIGATE_TO,
            data: {url: LOGIN_ROUTE},
          })
        })

      }

      if (orgSelected && !getInLine && booking.currentStep === 'booking_org') {
        handleSelectedOrgPreAuthBooking(bloc).then(() => {
          globalBloc.updateBooking({currentStep: 'booking_slot'})
        })
      }

      if (appointment?.type === 'IN_PERSON_WALK_IN' && appointment?.status === 'RESERVED') {
        bloc.events.next({
          type: BookingBlocEvent.NAVIGATE_TO,
          data: {
            url: routeUtil.buildBookingIdentityDocument(appointment.id),
          },
        })
      } else {
        getAvailableOrganisations(appointment?.service, organisationId, bloc).then(() =>
          bloc.events.next({
            type: BookingBlocEvent.INITIALISED,
            data: {appointment: appointment},
          }),
        )
      }
    })
    .finally(() => {
    globalBloc.updateGlobalBloc({allowLoginComponent: true})

    bloc.__updateSubject({
      loadingData: false,
      initialising: false,
      loading: false,
    })
  })
}
