import React, {useState} from 'react'
import {AppBar, Typography, Grid, Box, Button, CircularProgress, Divider} from '@mui/material'
import logo from '../../../../assets/full-logo.svg'
import {useMediaQueries} from '../../../../utils/hooks/useMediaQueries'
import QRCode from 'react-qr-code'
import {providerStorage} from '../../../../utils/provider.qs'
import kioskBG from './bg-demo-kiosk.svg'
import curologo from './curo-by-dh-logo.svg'
import {globalBloc} from "../../../global.bloc";

const red = '#FF3321 !important'

const font = 'Montserrat, sans-serif !important' //==> closest font to the design (Gotham Medium)

const baseFontColor = '#414042'

const gradientBG = 'linear-gradient(180deg, #01D0FE, #007C98)'

//==> FIRST SCREEN CHILD COMPONENT

const CheckInOptions = ({handleNext, checkin, walkin}) => {
    const [loading, setLoading] = useState(false)
    const [altLoading, setAltLoading] = useState(false)

    const mediaQuery = useMediaQueries()

    const handleClickGetInLine = () => {
        setAltLoading(true)
        setTimeout(() => {
            walkin()
            setAltLoading(false)
        }, 1000)
    }

    const handleClickCheckin = () => {
        setLoading(true)
        setTimeout(() => {
            checkin()
            setLoading(false)
        }, 1000)
    }

    return (
        <Box sx={{width: '100%', minHeight: '100vh'}}>
            <AppBar
                position="static"
                style={{
                    background: '#00A6CB',
                    height: '115px',
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'relative',
                    padding: '1rem 13em',
                }}
            >
                <Box sx={{flex: '1', display: 'flex'}}></Box>
                <img
                    src={logo}
                    alt="logo-white"
                />
            </AppBar>
            <Box mt={3} p={3}>
                <Grid container direction="column" spacing={3}>
                    <Grid xs={12} item>
                        <Typography
                            align="center"
                            sx={{
                                fontSize: '2rem !important',
                                fontWeight: '500',
                                fontFamily: font,
                                color: baseFontColor,
                            }}
                        >
                            Please choose from the options below for your preferred check-in method.
                        </Typography>
                    </Grid>

                    <Grid container display="flex" flexDirection="row" flexWrap="wrap" item spacing={2}>
                        <Grid
                            item
                            gap={3}
                            xs={6}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '3rem 3rem !important',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: red,
                                    fontSize: '4rem !important',
                                    fontWeight: '600',
                                }}
                                align="center"
                            >
                                Mobile Phone
                            </Typography>
                            <Typography
                                align="center"
                                sx={{
                                    fontSize: '2rem !important',
                                    fontWeight: '500',
                                    fontFamily: font,
                                    color: baseFontColor,
                                }}
                            >
                                Scan the QR code with your mobile device to complete check-in on your phone.
                            </Typography>
                            <QRCode
                                size={200}
                                value={`${window.location.protocol}//${
                                    window.location.host
                                }/link/qr/${providerStorage.getCurrentProvider()}`}
                            />
                        </Grid>
                        <Grid
                            item
                            gap={3}
                            xs={6}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '3rem 3rem !important',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: red,
                                    fontSize: '4rem !important',
                                    fontWeight: '600',
                                }}
                                align="center"
                            >
                                Kiosk
                            </Typography>
                            <Typography
                                align="center"
                                sx={{
                                    fontSize: '2rem !important',
                                    fontWeight: '500',
                                    fontFamily: font,
                                    color: baseFontColor,
                                }}
                            >
                                Lets get you checked in! <br/>
                                Press the button below to check-in on the kiosk.
                            </Typography>
                            <Button
                                onClick={handleClickCheckin}
                                variant="contained"
                                sx={{
                                    padding: mediaQuery.isMd ? null : '.61111em 1.22222em !important',
                                    fontSize: mediaQuery.isMd ? '2rem' : '3rem',
                                    backgroundColor: red,
                                    borderRadius: '20px !important',
                                    fontFamily: font,
                                }}
                            >
                                {loading ? (
                                    <CircularProgress color="inherit" size={mediaQuery.isMd ? '2.5rem' : '3.5rem'}/>
                                ) : (
                                    'Check-In >'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

//==> SECOND SCREEN CHILD COMPONENT

const LetsGetStarted = ({handleNextStep}) => {
    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        setLoading(true)
        setTimeout(() => {
            handleNextStep()
            setLoading(false)
        }, 1000)
    }

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                alignItems: 'center',
                gap: 3,
                backgroundImage: `url(${kioskBG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <img
                style={{
                    position: 'absolute',
                    top: '1rem',
                    left: '1rem',
                    margin: '5rem',
                    width: '300px',
                }}
                src={curologo} alt='curo by dh logo'
            />
            <Box sx={{
                width: '100vw',
                height: '80vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
            }}>
                <Typography
                    align="center"
                    sx={{
                        color: '#313131',
                        fontWeight: '600',
                        fontSize: '8rem !important',
                        lineHeight: '1.2',
                        marginBottom: '2rem',
                        fontFamily: font,
                        width: '80%',
                    }}
                >
                    Let's get you checked-in
                </Typography>
                <Button
                    onClick={handleClick}
                    variant="contained"
                    fullWidth
                    sx={{
                        // color: '#023541 !important',
                        borderRadius: '32px',
                        minWidth: '600px',
                        fontSize: '1.8rem',
                        padding: '1.5rem 0',
                        backgroundColor: red,
                        // background: 'linear-gradient(275deg, #00D1FF, #00BFE9)',
                        // boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.25), 0px 20px 40px 10px rgba(0, 209, 255, 0.5) !important',
                        fontFamily: font,
                        '&': {
                            color: 'white',
                        },
                        '&:hover': {
                            color: 'white',
                        },
                    }}
                >
                    {loading ? <CircularProgress color="inherit" size="2rem"/> : 'GET STARTED'}
                </Button>
            </Box>
        </Box>
    )
}

//==> PARENT COMPONENT

const KioskWelcome = ({handleNext, walkin, checkin}) => {
    const [step, setStep] = useState(0)

    globalBloc.resetGlobalBloc()

    const handleNextStep = () => {
        setStep((prevStep) => prevStep + 1)
    }

    return (
        <>
            {step === 0 && <LetsGetStarted handleNextStep={handleNextStep}/>}
            {step === 1 && <CheckInOptions handleNext={handleNext} walkin={walkin} checkin={checkin}/>}
        </>
    )
}

export default KioskWelcome


