class ProviderStorage {
  constructor() {
    this.storageKey = 'currentProvider';
    this.terminalKey = 'currentTerminal';
    this.queryString = 'provider';
  }

  setPersistent = (providerId, terminalId) => {
    if (providerId?.length > 0) {
      localStorage.setItem(this.storageKey, providerId);
    } else {
      localStorage.removeItem(this.storageKey);
    }
    if (terminalId?.length > 0) {
      localStorage.setItem(this.terminalKey, terminalId);
    } else {
      localStorage.removeItem(this.terminalKey);
    }
  };

  setUpStorage = () => {
    if (this.getCurrentProvider() == null) {
      sessionStorage.setItem(this.storageKey, '');
    }
  };

  getCurrentProvider = () => {
    if (this.isKiosk()) {
      return localStorage.getItem(this.storageKey);
    }
    return sessionStorage.getItem(this.storageKey);
  };
  getPersistedProvider = () => {
    return localStorage.getItem(this.storageKey) || '';
  };
  getPersistedTerminal = () => {
    return localStorage.getItem(this.terminalKey) || '';
  };
  getCurrentTerminal = () => {
    return localStorage.getItem(this.terminalKey) || '';
  };
  isKiosk = () => {
    const provider = this.getPersistedProvider();
    return provider?.length > 0;
  };
  isWalkin = () => {
    const provider = this.getCurrentProvider();
    return provider?.length > 0;
  };
  hasProvider = () => {
    let provider = this.getCurrentProvider();
    if (!provider) {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has(this.queryString)) {
        provider = urlParams.get(this.queryString);
        sessionStorage.setItem(this.storageKey, provider);
      }
    }
    return provider ? true : false;
  };

  setCurrentProvider = (paramsString) => {
    let searchParams = new URLSearchParams(paramsString.replace('?', ''));
    this.setUpStorage();

    if (searchParams.has(this.queryString) && searchParams.get(this.queryString)) {
      sessionStorage.setItem(this.storageKey, searchParams.get(this.queryString));
    }
  };

  clearProvider = () => {
    sessionStorage.clear();
  };

  clearProviderOnly = () => {
    sessionStorage.removeItem(this.storageKey);
  };

  clearPersistent = () => {
    localStorage.clear();
  };
}

export const providerStorage = new ProviderStorage();
