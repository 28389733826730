import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import {CheckSession, SetSession, uriStorage} from '../../utils/storage';
import BasicInformation from './BasicInformation';
import {FormControlLabel, Link} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import Address from './AddressForm';
import {registrationApi} from '../../utils/services/register.api';
import {authService} from '../../utils/auth';
import {AnalyticsEvent, analyticsEventLogger} from '../../utils/events';
import {notificationService} from '../../utils/notification';
import {ErrorMessage, errorResolver} from '../../utils/error.resolver';
import {userInfoUtil} from '../../utils/user';
import {phoneUtil} from '../../utils/phone';
import {
  BOOKING_LIST_ROUTE,
  BOOKING_ROUTE,
  QUINN_ROUTE,
  REMOTE_CONFIRMATION,
  ROOT_ROUTE,
  routeUtil,
  USER_INFORMATION_ROUTE,
} from '../../utils/route.name';
import {
  InvalidPhoneNumberModal,
  NotConnectedPhoneNumberModal,
} from './PhoneNumberVerificationModals';
import {Checkbox} from '../shared/components/Checkbox';
import DecodedComponent from '../shared/DecodedComponent';
import {EmergencyContact} from './EmergencyContact';
import {Bloc} from './bloc';
import {pharmacyApi} from '../../utils/services/pharmacy.api';
import {consumerApi} from '../../utils/services/consumers.api';
import Confirmation from './components/Confirmation';
import {AssistantBlocEvent} from '../Secure/Assistant/assistant.bloc';
import {logger} from '../../utils/logging';
import {providerStorage} from '../../utils/provider.qs';
import Loading from '../shared/Loading';
import {addUserDataHeap} from '../../utils/heap/heapUtils';
import {globalBloc} from "../global.bloc";

const styles = (theme) => ({
  grid: {
    flex: '1',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  checkBox: {
    transform: 'scale(2)',
    marginLeft: '1em',
    marginRight: '1em',
  },
  button: {
    width: '100%',
    padding: '3em',
    textAlign: 'center',
  },
});

const initState = {
  loading: false,
  activeStep: 0,
  scanSkipped: false,

  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  addressAdministrativeArea: '',
  addressCity: '',
  addressPostcode: '',
  addressCountry: 'US',
  codeCountry: 'US',
  code: '+1',
  number: '',
  email: '',
  gender: '',
  ssn: '',
  dateOfBirth: '',

  hearAboutUs: '',
  race: '',
  ethnicity: '',
  language: '',

  acceptTerms: false,

  registrationSuccess: false,

  invalidPhoneNumber: false,
  notConnectedPhoneNumber: false,

  frontImageValue: {},
  backImageValue: {},
  idVerificationReference: null,
  isPatientMyself: 'true',
  isFirstTimeVisit: 'true',

  pharmacy: {},
  responsibleParty: {relationship: 'SELF'},
  emergency: {},
  isKiosk: providerStorage.hasProvider()
};

class Registration extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = new Bloc({});
    this.state = initState;
  }

  componentDidMount() {
    super.componentDidMount();
    logger.debug('Registration -> componentDidMount');

    this.setState({loading: true});

    //! @ v=== PREVENT USER FROM GOING BACK TO PREVIOUS PAGE ===v
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };

    const {loginDetails} = this.bloc.subject.value;

    uriStorage.setCurrentPath(this.props.match.url);

    analyticsEventLogger.log(AnalyticsEvent.REGISTER_OPEN);

    if (loginDetails) {
      this.setState({
        ...this.props.location.state,
        firstName: loginDetails?.firstName,
        lastName: loginDetails?.lastName,
        dateOfBirth: this.__getCorrectDob(
          {data: {dob: 'DATE_OF_BIRTH'}},
          loginDetails?.dateOfBirth,
        ),
        email: loginDetails?.email,
        number: loginDetails?.number,
        gender: loginDetails?.gender,
      });
    }

    consumerApi
      .getPersonSummary()
      .then(
        (value) => {
          logger.debug('user already has an account, redirecting to ROOT_ROUTE');
          this.props.history.replace(USER_INFORMATION_ROUTE);
        },
        (reason) => {
        },
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  handleNext = () => {
    if (!providerStorage.hasProvider()) {
      SetSession('task', 'confirm_booking');
    }

    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  handleDataChange = (data) => {
    this.setState(data);
  };

  handleCodeChange = (callingCode, addressCountry, countryCode) => {
    this.setState({
      code: callingCode,
      codeCountry: countryCode,
      ...(addressCountry ? {addressCountry} : {}),
      number: '',
    });
  };

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  handleGenderChange = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };

  handlePhoneVerification = () => {
    const {loading} = this.state;

    if (loading) return;

    this.setState({
      loading: true,
    });
    const formattedNumber = phoneUtil.formatPhoneNumberForRegistration(
      this.state.number,
      this.state.code,
      this.state.codeCountry,
    );
    const number =
      formattedNumber.country +
      formattedNumber.area +
      formattedNumber.prefix +
      formattedNumber.line;
    registrationApi
      .verifyPhoneNumber(number)
      .then((response) => {
        const verificationStatus = response.data;
        if (verificationStatus.status === 3) {
          this.setState({
            invalidPhoneNumber: true,
          });
          return;
        } else if (verificationStatus.status === 0) {
          if (verificationStatus.current_carrier.network_type === 'mobile') {
            this.handleNext();
            return;
          } else {
            this.setState({
              notConnectedPhoneNumber: true,
            });
            return;
          }
        }
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_SUCCESS);
        this.handleNext();
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_PHONE_VERIFICATION_ERROR, {
          reason: error,
        });

        this.handleNext();
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleNextPersonalVerification = (_, submitWithPhoneVerification = true) => {
    if (submitWithPhoneVerification) {
      this.handlePhoneVerification();
    } else {
      this.handleNext();
    }
  };

  doSubmit = (formState) => {
    const {loading, isKiosk} = this.state;
    const {orgSelected, appointmentId} = globalBloc.subject.value;

    if (loading) return;

    this.setState({loading: true});
    const formRequest = this.createAccountRequest(formState);

    registrationApi
      .register(formRequest)
      .then((value) => {
        authService
          .loginWithToken(value.data.token)
          .then(() => {
            analyticsEventLogger.log(AnalyticsEvent.REGISTER_SUCCESS);

            consumerApi
              .getPersonSummary()
              .then((result) => {
                addUserDataHeap({
                  id: result.data.id,
                  dateOfBirth: result.data.dob,
                  firstName: result.data.name.given,
                });
              })
              .catch((error) => {
                logger.error('Error getting person summary', error);
              });

            if (orgSelected && appointmentId) {
              this.props.history.push(
                routeUtil.buildBookingRouteWithDraftAppointmentID(
                  appointmentId,
                  'IN_PERSON',
                ),
              );
            } else {
              this.props.history.replace(BOOKING_LIST_ROUTE);
            }
          })

          // if (providerStorage.hasProvider()) {
          //   logger.info('user to quinn from registration');
          //   SetSession('task', 'post-register');
          //   this.props.history.replace(QUINN_ROUTE);
          // }

          //   // TODO test this block
          //   if (manageAppointments && !appointmentId) {
          //     logger.info('user had no account and no appointment, redirecting to booking list');
          //     this.props.history.replace(BOOKING_LIST_ROUTE);
          //   }
          //
          //   if (appointmentId && !isKiosk) {
          //     logger.info('pushing to booking confirmation from registration');
          //     SetSession('task', 'confirm_booking');
          //     this.props.history.replace(routeUtil.buildBookingRouteWithDraftAppointmentID(appointmentId, 'IN_PERSON'));
          //   } else {
          //     logger.info('user had no account and no appointment, redirecting to booking list');
          //     this.props.history.replace(BOOKING_LIST_ROUTE);
          //   }
          // })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.REGISTER_AUTH_ERROR, {
              reason: `${error}`,
            });

            notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
            this.setState({
              registrationFailed: true,
              loading: false,
            });
          });
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.REGISTER_ERROR, {
          reason: error,
        });
        notificationService.error(errorResolver.resolveRegistrationErrorDisplay(error));
        this.setState({registrationFailed: true});
      })
      .finally(() => {
        this.setState({loading: false});
      });
  };

  submitPharmacyRequest = () => {
    const pharmacy = this.createPharmacyRequest();

    pharmacyApi
      .setPharmacy(pharmacy)
      .then((result) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_UPDATE_SUCCESS);
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.PHARMACY_UPDATE_ERROR, {
          reason: `${error}`,
        });
        notificationService.error(
          'Unable to update pharmacy information.' + ErrorMessage.CALL_SUPPORT,
        );
      });
  };

  createEmergencyContactRequest = (emergencyData) => {
    if (!emergencyData?.relationship) {
      return {relationship: 'SELF'};
    }

    const {relationship, firstName, lastName, phoneNumber, address, city, postCode, state} =
      emergencyData;

    if (relationship === 'SELF') return {relationship: relationship};

    return {
      relationship: relationship,
      name: userInfoUtil.formatName(firstName, lastName),
      contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
      address: {
        type: '',
        line1: address,
        line2: '',
        line3: '',
        city: city,
        postcode: postCode,
        administrativeArea: state,
        country: 'US',
      },
    };
  };

  createGuarantorRequest = (guarantorData) => {
    if (!guarantorData?.relationship) {
      return {relationship: 'SELF'};
    }

    const {
      relationship,
      firstName,
      lastName,
      phoneNumber,
      address,
      city,
      postCode,
      state,
      gender,
      dateOfBirth,
    } = guarantorData;

    if (relationship === 'SELF') return {relationship: relationship};

    return {
      relationship: relationship,
      name: userInfoUtil.formatName(firstName, lastName),
      dob: dateOfBirth ? userInfoUtil.formatDate(dateOfBirth) : null,
      gender: gender,
      contactNumber: phoneUtil.formatPhoneNumberForRegistration(phoneNumber, '+1', 'US'),
      address: {
        type: '',
        line1: address,
        line2: '',
        line3: '',
        city: city,
        postcode: postCode,
        administrativeArea: state,
        country: 'US',
      },
    };
  };

  createPharmacyRequest = () => {
    const {pharmacy} = this.state;

    if (pharmacy.contactInformation) {
      return pharmacy;
    }
    return {
      id: pharmacy.id,
      name: pharmacy.name,
      group: pharmacy.group,
      contactInformation: pharmacy.contactInformation,
    };
  };

  createAccountRequest = (emergencyData) => {
    let {
      firstName,
      lastName,
      gender,
      dateOfBirth,
      ssn,
      acceptTerms,
      number,
      email,
      code,
      codeCountry,
      idVerificationReference,
      addressLine1,
      addressLine2,
      addressCity,
      addressAdministrativeArea,
      addressPostcode,
      addressCountry,
      race,
      ethnicity,
      hearAboutUs,
    } = this.state;

    let externalReferences = [];

    if (ssn) {
      externalReferences.push({
        code: 'SS',
        reference: ssn,
      });
    }

    if (idVerificationReference) {
      externalReferences.push({
        code: 'verificationId',
        reference: idVerificationReference,
      });
    }

    let properties = [];
    if (hearAboutUs) {
      properties.push({
        code: 'marketingChannel',
        value: hearAboutUs,
      });
    }

    let demographic = [];
    if (race)
      demographic.push({
        code: 'race',
        value: race,
      });
    if (ethnicity)
      demographic.push({
        code: 'ethnicity',
        value: ethnicity,
      });

    const emergency = this.createEmergencyContactRequest(emergencyData);
    const responsibleParty = this.createGuarantorRequest(this.state.responsibleParty);

    return {
      code: 'enrollment.register',
      type: 'client',
      payload: {
        name: userInfoUtil.formatName(firstName, lastName),
        dob: userInfoUtil.formatDate(dateOfBirth),
        gender: gender,
        externalReferences: externalReferences,
        contactDetails: {
          address: {
            type: '',
            line1: addressLine1,
            line2: addressLine2,
            line3: '',
            city: addressCity,
            postcode: addressPostcode,
            administrativeArea: addressAdministrativeArea,
            country: addressCountry,
          },
          number: phoneUtil.formatPhoneNumberForRegistration(number, code, codeCountry),
          email: email,
        },
        acceptTerms: acceptTerms,
        communicationConsent: acceptTerms,
        acceptLocationTerm: true,
        emergency,
        guarantor: responsibleParty,
        demographic: demographic,
        properties: properties,
      },
    };
  };

  handleGoBackDialog = () => {
    this.setState({
      // activeStep: 0,
      notConnectedPhoneNumber: false,
      invalidPhoneNumber: false,
    });
  };

  handleContinueOnDialog = () => {
    this.setState((state, props) => ({
      activeStep: state.activeStep + 1,
      notConnectedPhoneNumber: false,
    }));
  };

  handleScanFinish = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      firstName:
        data?.name?.given.trim().length > 0 ? data?.name?.given.trim() : prevState.firstName,
      lastName:
        data?.name?.family.trim().length > 0 ? data?.name?.family.trim() : prevState.lastName,
      dateOfBirth: this.__getCorrectDob(data, prevState.dateOfBirth),
      addressLine1: data?.address?.line1?.trim() ?? prevState.addressLine1,
      addressLine2: data?.address?.line2?.trim() ?? prevState.addressLine2,
      addressAdministrativeArea:
        (data?.address?.administrativeArea || data?.address?.state) ??
        prevState.addressAdministrativeArea,
      addressCity: data?.address?.city ?? prevState.addressCity,
      addressPostcode:
        data?.address?.postcode?.length > 5
          ? ''
          : data?.address?.postcode ?? prevState.addressPostcode,
      idVerificationReference: data?.ref,
    }));
    this.handleNext();
  };

  __getCorrectDob = (data, originalDob) => {
    let result;
    if (
      data?.dob === undefined ||
      data?.dob === '' ||
      (data?.dob === 'DATE_OF_BIRTH' && originalDob)
    ) {
      result = originalDob;
    } else {
      let extractedDate = data?.dob?.replace('-', '/');
      const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
      result = dateRegex.test(extractedDate) ? extractedDate : originalDob;
    }

    return result;
  };

  basicInformationBack = () => {
    this.setState((ps) => ({
      ...ps,
      activeStep: ps.activeStep - 1,
    }));
  };

  handlePreScan = (newState) => {
    this.setState(newState);
  };

  getStepContent = (step) => {
    const isLoading = this.state.loading || authService.isLoading();

    switch (step) {
      case 0:
        return (
          <BasicInformation
            {...this.state}
            handleBack={this.basicInformationBack}
            handleNext={this.handleNextPersonalVerification}
            onCodeChange={this.handleCodeChange}
            handleTextChange={this.handleTextChange}
            handleGenderChange={this.handleGenderChange}
          />
        );
      case 1:
        return (
          <Address
            {...this.state}
            isLoading={isLoading}
            handleBack={this.handleBack}
            doSubmit={(newState) => {
              this.setState(newState);
              this.handleNext();
            }}
            code={this.state.code}
            updateAddressChange={this.handleDataChange}
            additionalFields={[
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={this.state.acceptTerms}
                    onChange={this.handleCheckboxChange}
                    className={this.props.classes.checkBox}
                    name="acceptTerms"
                    color="primary"
                  />
                }
                label={
                  <React.Fragment>
                    <FormattedMessage
                      id="registration.user.consent"
                      defaultMessage="I acknowledge and consent to"
                    />
                    <br/>
                    <FormattedMessage id="global.consent.link">
                      {(chunks) => (
                        <Link
                          rel="noopener noreferrer"
                          target="_blank"
                          href={chunks}
                          underline="hover"
                        >
                          <FormattedMessage
                            id="registration.user.tnc"
                            defaultMessage="The Terms of Use, Consent to Treatment, and Notice of Privacy Practices"
                          />
                        </Link>
                      )}
                    </FormattedMessage>
                  </React.Fragment>
                }
              />,
            ]}
          />
        );
      case 2:
        return (
          <EmergencyContact
            {...this.state}
            handleBack={this.handleBack}
            handleSubmit={(formState) => {
              this.doSubmit(formState);
            }}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  render() {
    let {activeStep, invalidPhoneNumber, notConnectedPhoneNumber, email, loading} = this.state;

    return (
      <>
        {this.getStepContent(activeStep)}
        <InvalidPhoneNumberModal open={invalidPhoneNumber} onGoBack={this.handleGoBackDialog}/>
        <NotConnectedPhoneNumberModal
          open={notConnectedPhoneNumber}
          email={email}
          onContinue={this.handleContinueOnDialog}
          onGoBack={this.handleGoBackDialog}
        />
      </>
    );
  }
}

export default withStyles(styles, {withTheme: true})(withRouter(Registration));
