import React from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../../shared/DecodedComponent';
import InsuranceContext from '../context';
import {withRouter} from 'react-router-dom';
import {Box, Button, Dialog, DialogActions, DialogTitle, Grid} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {FormattedMarkdown} from '@decodedhealth/react-library';
import PageContainer from '../../../common/PageContainer';

import {Bloc as NewInsuranceBloc} from './InsuranceAdd/bloc';
import InsuranceAdd from './InsuranceAdd';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../../common/ScrollableContainer';
import InsuranceItem from './InsuranceItem';
import InsuranceEmpty from './InsuranceEmpty';
import InsuranceChoice from './InsuranceChoice';
import DecodedButton, {DecodedSecondaryButton} from '../../../common/DecodedButton';
import InsuranceUnknown from './InsuranceUnknown';
import {AnalyticsEvent, analyticsEventLogger} from '../../../../utils/events';
import SelfPay from "../../../Secure/BookingCheckin/SelfPay";

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class InsuranceList extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.context.bloc;
    this.state = {
      ...this.bloc.subject.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    const {insurances} = this.state;
    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_PAYMENT_METHOD_LOADED, {
      count: insurances.length,
      type: 'insurance',
    });
    if (insurances.length === 0) {
      this.__paymentChoice();
    } else if (insurances.length > 0) {
      this.setState({edit: true});
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  __paymentChoice = () => {
    this.setState({
      component: (
        <>
          <InsuranceChoice
            bloc={this.bloc}
            addInsurance={this.__newInsurance}
            selfPay={this.__submit}
            goToFrontDesk={this.__insuranceUnknown}
            clearComponent={this.__clearComponent}
          />
        </>


      ),
    });
  };


  __insuranceUnknown = () => {
    this.setState({
      component: <InsuranceUnknown bloc={this.bloc} clearComponent={this.__clearComponent}/>,
    });
  };

  __newInsurance = () => {
    let newInsuranceBloc = new NewInsuranceBloc(this.bloc);
    newInsuranceBloc.registerCloseCallback(this.__clearComponent);
    newInsuranceBloc.registerUnknownCallback(this.__insuranceUnknown);
    this.setState({component: <InsuranceAdd bloc={newInsuranceBloc}/>});
  };

  clearInsurances = () => {
    this.bloc.removeInsurances();
    this.setState({edit: false});
  };

  __clearComponent = (next) => {
    this.setState({component: undefined});
  };

  __submit = () => {
    this.setState({dialogOpen: true});
    this.bloc.save();
  };

  render() {
    const {edit, insurances, component, loading} = this.state;


    if (component) {
      return <>{component}</>;
    }

    const size = insurances?.length ?? 0;

    const {message, messageId} = this.getQuinnMessage(size);

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer message={message} messageId={messageId}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {insurances.length === 0 ? (
                <InsuranceEmpty onClick={this.__newInsurance}></InsuranceEmpty>
              ) : (
                insurances.map((insurance) => (
                  <Box sx={{marginBottom: '24px', paddingLeft: '12px'}}>
                    <InsuranceItem disabled={edit} insurance={insurance} sx={{minWidth: 275}}/>
                  </Box>
                ))
              )}
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter>
          <Grid item xs={12}>
            {edit && insurances.length > 0 ? (
              <DecodedSecondaryButton loading={loading} onClick={this.clearInsurances}>
                Edit
              </DecodedSecondaryButton>
            ) : (
              <DecodedButton
                disabled={insurances.length > 1}
                loading={loading}
                onClick={this.__newInsurance}
              >
                {this.getAdInsuranceButtonText(insurances.length)}
              </DecodedButton>
            )}
          </Grid>
          <Grid item xs={12}>
            {!edit && insurances.length === 0 && (
              <DecodedButton variant="contained" color="primary" onClick={this.__insuranceUnknown}>
                Unsure
              </DecodedButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <DecodedButton loading={loading} onClick={this.__submit}>
              {insurances.length === 0 ? (
                <FormattedMessage
                  id="insurance.list.selfpay.button.label"
                  defaultMessage={'Self pay'}
                />
              ) : (
                <FormattedMessage
                  id="insurance.list.submit.button.label"
                  defaultMessage={'Continue'}
                />
              )}
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }

  getAdInsuranceButtonText(listSize) {
    if (listSize === 1) {
      return (
        <FormattedMarkdown
          id={'insurance.list.secondary.button.text'}
          defaultMessage={'Add secondary insurance'}
        />
      );
    }
    if (listSize === 2) {
      return (
        <FormattedMarkdown
          id={'insurance.list.tertiary.button.text'}
          defaultMessage={'Add tertiary insurance'}
        />
      );
    }

    return (
      <FormattedMarkdown
        id={'insurance.list.primary.button.text'}
        defaultMessage={'Add insurance'}
      />
    );
  }

  getQuinnMessage(size) {
    return size === 0
      ? {
        messageId: 'insurance.new.bubble',
        message: 'Lets add your insurance or how would you like to pay for this visit?',
      }
      : {
        messageId: 'insurance.update.bubble',
        message:
          'Great! Looks like we have your insurance information! Please verify or update your insurance(s) if necessary.',
      };
  }

}


export default withStyles(styles)(
  withRouter((props) => (
    <InsuranceContext.Consumer>
      {(value) => {
        return <InsuranceList context={value} {...props} />;
      }}
    </InsuranceContext.Consumer>
  )),
);
