import { appointmentApi } from '../../../utils/services/appointments.api';
import { notificationService } from '../../../utils/notification';
import { DecodedBloc } from '../../shared/DecodedComponent/bloc';
import { CheckSession, SetSession, uriStorage } from '../../../utils/storage';
import { authService } from '../../../utils/auth';
import { providerStorage } from '../../../utils/provider.qs';
import { dateUtil } from '../../../utils/date';
import { ErrorMessage } from '../../../utils/error.resolver';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { differenceInMinutes, isSameDay } from 'date-fns';
import { providerUtil } from '../../../utils/provider';
import { QUINN_SCHEDULED, RESCHEDULED, routeUtil } from '../../../utils/route.name';
import { tenantUtil } from '../../../utils/tenant';
import { logger } from '../../../utils/logging';

export class Bloc extends DecodedBloc {
  constructor(appointmentId) {
    super({
      appointmentId: appointmentId,
    });

    authService.getIdTokenResult().then((result) => {
      let isFullLogin = result?.claims?.scope?.some((scope) => scope === '*');
      this.__initialise(appointmentId, isFullLogin);
    });
  }

  __initialise = (appointmentId, isFullLogin) => {
    this.__updateSubject({
      loading: true,
      inClinic: providerStorage.isWalkin(),
      isFullLogin: isFullLogin,
    });

    Promise.all([
      appointmentApi.getAppointmentStatus(appointmentId),
      appointmentApi.getAppointmentQueueStatus(appointmentId),
    ])
      .then((results) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);
        const result = results[0];
        if (result.data.service === 'ANY-INV') {
          this.__updateSubject({
            isGetInLine: true,
          });
        }

        if (result.data.status === 'COMPLETE') {
          this.__updateSubject({
            loading: false,
          });

          authService.logout().then(() => {
            uriStorage.clearPath();
            providerStorage.clearProvider();
          });
          return;
        }

        const waitTimeInMinutes = results[1].data.waitTime;

        const startTime = new Date(
          new Date(result?.data?.start).getTime() + waitTimeInMinutes * 60000,
        );

        let newState = {};

        if (['WAITING'].includes(result.data.status)) newState.checkinSuccess = true;
        else
          newState.checkinAvailable =
            isSameDay(new Date(), startTime) && !(differenceInMinutes(startTime, new Date()) > 60);

        newState.queueNumber = result.data.waitingRoomSummary.numberInQueue;
        newState.waitTime = differenceInMinutes(startTime, new Date());
        newState.startTime = startTime;
        newState.timezone = result.data.timezone;
        newState.loading = false;
        newState.appointment = result.data;

        this.__updateSubject({
          ...newState,
        });

        if (result.data.provider) this._getProviderLocation(result.data.provider);
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR, {
          appointmentId: appointmentId,
          reason: error,
        });

        this.__updateSubject({
          checkinSuccess: false,
          waitTime: 'unknown',
          queueNumber: '_',
          checkinAvailable: false,
          loading: false,
        });

        notificationService.error('Error checking in for your visit. ' + ErrorMessage.CALL_SUPPORT);
      });
  };

  _getProviderLocation = (providerId) => {
    this.__updateSubject({
      loading: true,
    });
    appointmentApi
      .getAvailableProviders()
      .then((response) => {
        const providers = response.data.items.filter((place) => place.id === providerId);

        let providerDetails = providers.map((place) => ({
          lat: place.contactInformation.address.geoLocation.latitude,
          lng: place.contactInformation.address.geoLocation.longitude,
        }));

        if (providerDetails.length === 1) {
          this.__updateSubject({
            provider: providers[0],
            providerDetails: providerDetails[0],
            providerContactNumber: providerUtil.formatOrganisationContactNumber(providers[0]),
          });
        }
      })
      .catch((error) => {
        notificationService.error(
          'Error finding the clinic location. ' + ErrorMessage.CALL_SUPPORT,
        );
      })
      .finally(() => {
        this.__updateSubject({
          loading: false,
        });
      });
  };

  rescheduleDraft = () => {
    const { appointment } = this.subject.value;

    appointmentApi.getDraftAppointment(appointment.id).then(
      (res) => {
        const location = res.headers.location.split(':');
        const uuid = location[location.length - 1];
        const url = routeUtil.buildBookingRouteWithDraftAppointmentID(uuid, RESCHEDULED);
        SetSession('appt', uuid);
        uriStorage.setCurrentPath(url);

        this.__publishEvent(BlocEvent.NAVIGATE_TO, {
          url: url,
        });
      },
      (reason) => {
        notificationService.error(
          'There was an error trying to reschedule your reservation. Please try again.',
        );
      },
    );
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
