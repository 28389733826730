import {Grid, Typography} from '@mui/material';
import PageContainer from '../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import * as React from 'react';
import DecodedComponent from '../../shared/DecodedComponent';
import withStyles from '@mui/styles/withStyles';
import {FormattedMessage} from 'react-intl';
import AccountContext from './context';
import DecodedButton, {DecodedSecondaryButton} from '../../common/DecodedButton';
import {FormattedMarkdown} from '@decodedhealth/react-library';
import {withRouter} from 'react-router-dom';
import {H2TextTitleContent} from '../../shared/Typography';
import MockStripe from "../Stripe/MockStripe";

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class VisitCost extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.context.bloc;

    this.state = {
      visitPaymentType: this.bloc.subject.value.visitPaymentType,
      visitCost: this.bloc.subject.value.visitCost,
      appointment: this.bloc.subject.value.appointment,
      paymentDetails: this.bloc.subject.value.paymentDetails,
    };
  }

  lookupAdditionalWording = (paymentDetails, visitPaymentType, type) => {
    switch (visitPaymentType) {
      case 'insurance': {
        if (
          visitPaymentType === 'insurance' &&
          paymentDetails.insuranceEligibility === 'unknown' &&
          type !== 'VIRTUAL'
        ) {
          return (
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMarkdown
                  id={`payment.account.${visitPaymentType}.inperson.eligibility.unknown.text`}
                  defaultMessage={`Please proceed to the front desk to complete your checkin`}
                ></FormattedMarkdown>
              </H2TextTitleContent>
            </Grid>
          );
        } else if (
          visitPaymentType === 'insurance' &&
          paymentDetails.insuranceEligibility !== 'eligible' &&
          type === 'VIRTUAL'
        ) {
          return (
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMarkdown
                  id={`payment.account.${visitPaymentType}.virtual.eligibility.unknown.text`}
                  defaultMessage={`We were unable to verify your insurance eligibility. We will do our best to bill your insurance, but you will be held liable for the cost.`}
                ></FormattedMarkdown>
              </H2TextTitleContent>
            </Grid>
          );
        }
      }
      case 'subscription':
      case 'fixed':
      case 'selfpay': {
        return (
          <Grid item xs={12}>
            <H2TextTitleContent>
              <FormattedMarkdown
                id={`payment.account.${visitPaymentType}.text`}
                defaultMessage={``}
              ></FormattedMarkdown>
            </H2TextTitleContent>
          </Grid>
        );
      }
      default: {
        if (type === 'VIRTUAL') {
          return (
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={`payment.account.${visitPaymentType}.virtual.text`}
                  defaultMessage={`We were unable to verify your insurance eligibility. We will do our best to bill your insurance, but you will be held liable for the cost.`}
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
          );
        }

        return (
          <Grid item xs={12}>
            <H2TextTitleContent>
              <FormattedMessage
                id={`payment.account.${visitPaymentType}.text`}
                defaultMessage={``}
              ></FormattedMessage>
            </H2TextTitleContent>
          </Grid>
        );
      }
    }
  };

  lookupWording = (paymentDetails, visitPaymentType, appointmentType) => {
    switch (visitPaymentType) {
      case 'subscription': {
        return {
          id: `payment.account.${visitPaymentType}.bubble`,
          message: 'Thank you. We have validated your subscription.',
          contentId: `payment.account.${visitPaymentType}.heading`,
          content: `Your visit cost for today is`,
          subtextId: `payment.account.${visitPaymentType}.subtext`,
          subtext: ``,
        };
      }
      case 'insurance': {
        if (paymentDetails.insuranceEligibility === 'unknown') {
          if (appointmentType === 'VIRTUAL') {
            return {
              id: `payment.account.${visitPaymentType}.bubble`,
              message:
                'Thank you. We were unable to determine your insurance eligibility for your virtual visit.',
              contentId: `payment.account.${visitPaymentType}.heading`,
              content: `Your visit cost for today is`,
              subtextId: `payment.account.${visitPaymentType}.subtext`,
              subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists please select skip.`,
            };
          }
          return {
            id: `payment.account.${visitPaymentType}.bubble`,
            message: 'Thank you. We were unable to determine your insurance eligibility.',
            contentId: `payment.account.${visitPaymentType}.heading`,
            content: ` `,
            subtextId: `payment.account.${visitPaymentType}.subtext`,
            subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists, you can click End and go see the front desk to complete your check-in process.`,
          };
        }

        if (paymentDetails.insuranceEligibility === 'not_eligible') {
          if (appointmentType === 'VIRTUAL') {
            return {
              id: `payment.account.${visitPaymentType}.not_eligible.bubble`,
              message:
                'Thank you. We have validated your insurance eligibility for your a virtual visit.',
              contentId: `payment.account.${visitPaymentType}.not_eligible.heading`,
              content: `Your visit cost for today is`,
              subtextId: `payment.account.${visitPaymentType}.not_eligible.subtext`,
              subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists please select skip.`,
            };
          }
          return {
            id: `payment.account.${visitPaymentType}.not_eligible.bubble`,
            message: 'Thank you. We have validated your insurance eligibility.',
            contentId: `payment.account.${visitPaymentType}.not_eligible.heading`,
            content: `Your visit cost for today is`,
            subtextId: `payment.account.${visitPaymentType}.not_eligible.subtext`,
            subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists, you can click End and go see the front desk to complete your check-in process.`,
          };
        }

        if (appointmentType === 'VIRTUAL') {
          return {
            id: `payment.account.${visitPaymentType}.bubble`,
            message:
              'Thank you. We have validated your insurance eligibility for your virtual visit.',
            contentId: `payment.account.${visitPaymentType}.heading`,
            content: `Your visit cost for today is`,
            subtextId: `payment.account.${visitPaymentType}.subtext`,
            subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists please select skip.`,
          };
        }

        return {
          id: `payment.account.${visitPaymentType}.bubble`,
          message: 'Thank you. We have validated your insurance eligibility.',
          contentId: `payment.account.${visitPaymentType}.heading`,
          content: `Your visit cost for today is`,
          subtextId: `payment.account.${visitPaymentType}.subtext`,
          subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists, you can click End and go see the front desk to complete your check-in process.`,
        };
      }
      case 'selfpay': {
        return {
          id: `payment.account.${visitPaymentType}.bubble`,
          message: 'Thank you. This visit will be selfpay.',
          contentId: `payment.account.${visitPaymentType}.heading`,
          content: `Your visit cost for today is`,
          subtextId: `payment.account.${visitPaymentType}.subtext`,
          subtext: `\\* If this amount is wrong, please go back, make sure your insurance details are correct and try again. If the problem persists, you can click End and go see the front desk to complete your check-in process.`,
        };
      }
      default: {
        return {
          id: `payment.account.${visitPaymentType}.bubble`,
          message: 'Thank you. We were not able to determine how the visit will be paid for.',
          contentId: `payment.account.${visitPaymentType}.heading`,
          content: `Your visit cost for today is`,
          subtextId: `payment.account.${visitPaymentType}.subtext`,
          subtext: `\\* If this amount is wrong, please go back, make sure your details are correct and try again. If the problem persists, you can click End and go see the front desk to complete your check-in process.`,
        };
      }
    }
  };

  __canProceed = (paymentDetails, visitPaymentType, type) => {
    if (type === 'VIRTUAL') {
      return true;
    }

    if (
      visitPaymentType === 'insurance' &&
      paymentDetails.insuranceEligibility === 'unknown' &&
      type !== 'VIRTUAL'
    ) {
      return false;
    }

    return (
      type !== 'VIRTUAL' && ['selfpay', 'insurance', 'subscription'].includes(visitPaymentType)
    );
  };

  render() {
    const {appointment, paymentDetails, visitPaymentType, visitCost, isWalkin} = this.state;

    if (visitPaymentType === 'unknown') {
      return this.__renderUnknown();
    }

    const wording = this.lookupWording(paymentDetails, visitPaymentType, appointment.type);
    const additionalWording = this.lookupAdditionalWording(
      paymentDetails,
      visitPaymentType,
      appointment.type,
    );
    const canProceed = this.__canProceed(paymentDetails, visitPaymentType, appointment.type);

    let cost = <></>;
    if (!(paymentDetails.insuranceEligibility === 'unknown' && appointment.type !== 'VIRTUAL')) {
      cost = (
        <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '40px !important',
              lineHeight: '32px !important',
              color: '#002F59',
            }}
          >
            ${visitCost?.toFixed(2)}
          </Typography>
        </Grid>
      );
    }

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer messageId={wording.id} message={wording.message}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={wording.contentId}
                  defaultMessage={wording.content}
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
            {cost}
            {additionalWording}
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '14px !important',
                  lineHeight: '26px !important',
                  color: '#777777',
                }}
              >
                <FormattedMarkdown
                  id={wording.subtextId}
                  defaultMessage={wording.subtext}
                ></FormattedMarkdown>
              </Typography>
              <Grid item xs={12}>
                <MockStripe/>
              </Grid>

            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          {appointment.type === 'VIRTUAL' && visitCost > 0 && (
            <Grid item xs={12} sm={6}>
              <DecodedSecondaryButton
                onClick={(e) => {
                  this.bloc.setVisitCostOverride();
                  this.bloc.next();
                }}
              >
                <FormattedMessage
                  id={`payment.account.${visitPaymentType}.button.skip`}
                  defaultMessage={'Skip'}
                />
              </DecodedSecondaryButton>
            </Grid>
          )}
          {appointment.type !== 'VIRTUAL' && isWalkin && visitCost > 0 && (
            <Grid item xs={12} sm={6}>
              <DecodedSecondaryButton
                onClick={(e) => {
                  this.bloc.end();
                }}
              >
                <FormattedMessage
                  id={`payment.account.${visitPaymentType}.button.end`}
                  defaultMessage={'End'}
                />
              </DecodedSecondaryButton>
            </Grid>
          )}
          {/*<Grid item xs={12} sm={6}>*/}

          {/*  <DecodedButton onClick={(e) => this.bloc.next()}>*/}
          {/*    <FormattedMessage*/}
          {/*      id={`payment.account.${visitPaymentType}.submit`}*/}
          {/*      defaultMessage={'CONTINUE TO PAYMENT'}*/}
          {/*    />*/}
          {/*  </DecodedButton>*/}

          {/*</Grid>*/}
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }

  __renderUnknown() {
    const {visitPaymentType, visitCost} = this.state;

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer
          messageId={`payment.account.${visitPaymentType}.bubble`}
          message={'Thank you. We were unable to verify your insurance.'}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={`payment.account.unknown.heading`}
                  defaultMessage={`We were unable to verify your insurance eligibility. We will do our best to bill your insurance, but you will be held liable for the cost.`}
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12} sm={6}>
            <DecodedButton onClick={(e) => this.bloc.next()}>
              <FormattedMessage
                id={`payment.account.${visitPaymentType}.submit`}
                defaultMessage={'Continue'}
              />
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(
  withRouter((props) => (
    <AccountContext.Consumer>
      {(value) => {
        return <VisitCost context={value} {...props} />;
      }}
    </AccountContext.Consumer>
  )),
);
