import axios from 'axios';
import { VERSION } from '../version';
import { tenantUtil } from '../tenant';

class AccountApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Content-Type'] = 'application/json; version=0.1.0';
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  command(data) {
    return axios.post(`${this.baseUrl}/api/accounts/me/_command`, {
      payload: data,
    });
  }

  listIdentityDocuments = () => {
    return axios.get(`${this.baseUrl}/api/accounts/me/identitycards`);
  };

  listAccounts = () => {
    return axios.get(`${this.baseUrl}/api/accounts/me/financial_accounts`);
  };

  analyzeDocument(form) {
    return this.instance.post(`${this.baseUrl}/api/accounts/me/_validate_id`, form);
  }
}

export const accountApi = new AccountApi();
