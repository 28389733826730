import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import {Box, Button, Grid, useTheme} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LandingOrgCard from './LandingOrgCard';
import {globalBloc} from '../../../global.bloc';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {useEffect, useState} from 'react';
import {authService} from "../../../../utils/auth";

const LandingOrgSelector = ({organisations}) => {
    const theme = useTheme();

    const [selected, setSelected] = useState(null);
    const [pointTo, setPointTo] = useState(null);

    const history = useHistory();

    const {user} = globalBloc.subject.value;

    window.addEventListener('popstate', () => {
        history.push('/');
    });

    useEffect(() => {
        globalBloc.resetGlobalBloc();
        authService.logout();

    }, [user]);

    useEffect(() => {
        if (selected) {
            globalBloc.updateBooking(selected);
            globalBloc.updateGlobalBloc({orgSelected: true});
        }
    }, [selected]);

    const handleManageReservation = () => {
        globalBloc.updateGlobalBloc({manageAppointments: true});
        sessionStorage.setItem('action', 'manage-appointments');
        history.push('/');
    }

    const Marker = ({lat, lng, children}) => (
        <div lat={lat} lng={lng}>
            {children}
        </div>
    );

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                        version: 'weekly',
                        libraries: ['places'],
                    }}
                    defaultCenter={{
                        lat: 30.45537972887279,
                        lng: -91.0783767180397,
                    }}
                    center={
                        pointTo
                            ? {
                                lat: pointTo?.contactInformation?.address?.geoLocation?.latitude,
                                lng: pointTo?.contactInformation?.address?.geoLocation?.longitude,
                            }
                            : {
                                lat: 30.45537972887279,
                                lng: -91.0783767180397,
                            }
                    }
                    defaultZoom={16}
                    options={{
                        zoomControl: false,
                        fullscreenControl: false,
                    }}
                    yesIWantToUseGoogleMapApiInternals
                >
                    {pointTo && (
                        <Marker
                            lat={pointTo?.contactInformation?.address?.geoLocation?.latitude}
                            lng={pointTo?.contactInformation?.address?.geoLocation?.longitude}
                        >
                            <LocationOnIcon
                                sx={{
                                    fontSize: '36px',
                                    color: theme.palette.accent.main,
                                }}
                                fontSize='large'
                                color='primary'
                            />
                        </Marker>
                    )}
                    {organisations?.map((_org) => (
                        <Marker
                            key={_org.id}
                            lat={_org?.contactInformation?.address?.geoLocation?.latitude}
                            lng={_org?.contactInformation?.address?.geoLocation?.longitude}
                        >
                            <LocationOnIcon
                                sx={{
                                    fontSize: '36px',
                                    color: theme.palette.accent.main,
                                }}
                                fontSize='large'
                                color='primary'
                            />
                        </Marker>
                    ))}
                </GoogleMapReact>
            </div>
            <Grid
                container
                direction='column'
                justifyContent='flex-end'
                height='100%'
                sx={(theme) => ({
                    paddingLeft: '5em',
                    marginBottom: '12px',
                    [theme.breakpoints.down('md')]: {
                        paddingLeft: '20px',
                    },
                })}
            >
                <Grid
                    item
                    container
                    flexWrap='nowrap'
                    width='100%'
                    sx={{
                        overflowX: 'scroll',
                        maxWidth: '88vw !important',
                        paddingLeft: '10px',
                    }}
                >
                    {organisations
                        ? organisations.map((org) => (
                            <Box
                                key={org.id}
                                onMouseEnter={() => setPointTo(org)}
                                sx={{
                                    width: '300px',
                                    minWidth: '300px',
                                    maxWidth: '300px',
                                    margin: '12px 24px 12px 0',
                                }}
                            >
                                <LandingOrgCard
                                    organisation={org}
                                    key={org.id}
                                    isHovered={pointTo?.id === org.id}
                                    setSelected={setSelected}
                                    pointTo={pointTo}
                                />
                            </Box>
                        ))
                        : null}
                </Grid>
                <Grid
                    item
                    width='100%'
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        alignItems: 'center',
                        marginTop: '12px',
                    }}
                >
                    <Button
                        sx={{
                            [theme.breakpoints.down('sm')]: {
                                width: '75%',
                            },
                        }}
                        variant='contained'
                        onClick={handleManageReservation}
                    >
                        MANAGE RESERVATION
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default LandingOrgSelector;
