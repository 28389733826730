import React, { useContext } from 'react';
import { Chip, Box, Grid, Button, Container } from '@mui/material';
import { H1TextStepTitle, TextStepSecondary } from '../../../shared/Typography';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { LoginDataContext } from '../LoginDataProvider';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LandscapeNextButton, NextButton } from './NextButton';

const options = ['Female', 'Male'];

const SexAtBirth = (props) => {
  const [selectedOption, setSelectedOption] = React.useState('');

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  const handleChipClick = (option) => {
    setSelectedOption(option);
    setLoginData((prevState) => ({ ...prevState, gender: option }));
  };

  const handleClick = () => {
    setTimeout(() => {
      if (props.index !== 6) {
        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleClick();
    // updateFormData(data);
    // if (index >= 0) {
    //   setLoginData((prevData) => ({ ...prevData, goBackStep: true }));
    // }
    if (props.index === 5) {
      console.log('***form is complete***');
      // doSubmit(data);
    }
    if (props.index === 6) {
      // doPinSubmit(data);
    }
  };

  return (
    <form
      style={{
        height: 'inherit',
      }}
      onSubmit={onSubmit}
    >
      <Container
        maxWidth="md"
        sx={{
          height: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          marginLeft: '10%',
          '@media (max-width: 1024px)': {
            marginLeft: '5%',
          },
          '@media (max-width: 935px)': {
            marginLeft: '0',
          },
        }}
      >
        <Box
          component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'start',
          }}
          gap={!mediaQuery.isMobileLandscape && 5}
        >
          <Box component={'div'}>
          <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
              <FormattedMarkdown
                id="registration.patient.name"
                defaultMessage={'Patient assigned sex at birth'}
              />
            </H1TextStepTitle>
            <TextStepSecondary isMobile={mediaQuery.isMobileLandscape}>
              <FormattedMarkdown
                id="registration.patient.name"
                defaultMessage={
                  'This information is used to verify your identity. It will not be used to assume your gender identity and it will not be shared with anyone.'
                }
              />
            </TextStepSecondary>
          </Box>
          <Grid
            container
            spacing={2}
            sx={{
              width: '75%',
              '@media (max-width:600px)': {
                width: '100%',
              },
              display: mediaQuery.isMobileLandscape && 'flex',
              flexDirection: mediaQuery.isMobileLandscape && 'row',
              justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
              alignItems: mediaQuery.isMobileLandscape && 'flex-end',
              flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
            }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Box display="flex" gap={2}>
                {options.map((option) => (
                  <Chip
                    size="large"
                    key={option}
                    label={option}
                    variant={loginData.gender === option ? 'filled' : 'outlined'}
                    clickable
                    onClick={() => handleChipClick(option)}
                    sx={{
                      fontSize: '1.5rem', 
                      padding: '1.7rem 1.4rem', 
                    }}
                  />
                ))}
              </Box>
            </Grid>
            {mediaQuery.isMobileLandscape && (
              <Grid item sm={3}>
                <LandscapeNextButton disabled={!loginData.gender} />
              </Grid>
            )}
          </Grid>
        </Box>
        {!mediaQuery.isMobileLandscape && <NextButton label={'Next'} disabled={!loginData.gender} />}
      </Container>
    </form>
  );
};

export default SexAtBirth;
