export class BookingBlocEvent {
  static INITIALISED = 'INITIALISED'
  static SWITCH_BOOKING_TYPE = 'SWITCH_BOOKING_TYPE'
  static DOCTOR_SELECTED = 'DOCTOR_SELECTED'
  static DATE_SELECTED = 'DATE_SELECTED'
  static TIME_SELECTED = 'TIME_SELECTED'
  static REMINDER_SELECTED = 'REMINDER_SELECTED'
  static BOOKING_CONFIRMED = 'BOOKING_CONFIRMED'

  static WALKIN_DETAILS_LOADED = 'WALKIN_DETAILS_LOADED'

  static NAVIGATE_TO = 'NAVIGATE_TO'
}
