import * as React from 'react';
import { compareAsc } from 'date-fns';
import { dateUtil } from '../../../utils/date';
import { useBookingWizardContext } from '../BookingWizard/booking-wizard-context';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { Grid } from '@mui/material';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import TimeBarChart from './TimeBarChart';
import { TimeIntervalButton } from './TimeIntervalButton';
import { Loading } from '../../../shared-library';
import { H2TextTitleContent, TextBody2Standard } from '../../shared/Typography';
import { useMediaQueries } from '../../../utils/hooks/useMediaQueries';
import { globalBloc } from '../../global.bloc';
import { appointmentIntervalUtil } from '../../../utils/appointment';
import {service} from '../bloc/helpers.bloc'

const TimeSelector = () => {
  const [walkinSlot, setWalkinSlot] = React.useState(null);

  const mediaQuery = useMediaQueries();

  const { schedulingIntervals, booking, bookingBloc, loadingData, walkinOnly } =
    useBookingWizardContext();
  const selectedSlot = booking.selectedSlot;

  const orgID = bookingBloc.subject.value.booking.selectedOrg;

  const { getInLine } = globalBloc.subject.value;


  React.useEffect(() => {
    console.log('time selector use effect')
    bookingBloc.loadSelectedDayAvailability();
    // if (booking.selectedOrg === null) {
    //   logger.debug('No selected org');
    //   this.props.history.replace(uriStorage.getCurrentPath());
    // }
  }, []);


  React.useEffect(() => {
    const now = new Date();
    const tomorrow = dateUtil.nextStartOfDay(now);

    bookingBloc.loadAvailability(now, tomorrow, orgID, service(), undefined, true).then( //=> apply rules needs to be true to get walkin slot (later api sets to false)
      (schedulingIntervals) => {
        let firstWalkinSlot = appointmentIntervalUtil.findFirstAvailableSlotForDay(
          schedulingIntervals,
          now,
        );

        if (firstWalkinSlot) {
          setWalkinSlot(`CURRENTLY AVAILABLE`);
        } else {
          setWalkinSlot('CURRENTLY UNAVAILABLE');
        }
      },
      (reason) => {
        setWalkinSlot('Unable to load');
      },
    );


  }, [bookingBloc, orgID]);
  console.log("booking", booking)

  const { intervals } = React.useMemo(() => {
    if (booking?.availability) {
      const start = new Date(booking.selectedDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(booking.selectedDate);
      end.setHours(23, 59, 59, 999);

      // const now = dateUtil.addhours(new Date(), -1);
      const now = new Date();
      const cutoff = dateUtil.addhours(new Date(), -1);
      cutoff.setMinutes(59, 59);

      let intervals = [];

      //!---------->>>> THERE MIGHT BE AN ISSUE HERE WITH BOOKING.AVAILABILITY <<<<<-------//
      if (booking.availability.results.length > 0) {
        booking.availability.results[0].intervals.forEach((_interval) => {
          _interval.renderDate = dateUtil.parseDate(_interval.start);
          if (
            cutoff.getTime() < _interval.renderDate.getTime() &&
            dateUtil.encloses(_interval.renderDate, start, end) &&
            _interval.availableSlots &&
            _interval.availableSlots.length > 0
          ) {
            if (_interval.renderDate.getTime() < now) {
              _interval.availableSlots = _interval.availableSlots.filter(
                (_slot) => _slot.start.split(':')[1] > now.getMinutes(),
              );
              if (_interval.availableSlots.length > 0) {
                intervals.push(_interval);
              }
            } else {
              intervals.push(_interval);
            }
          }
        });

        intervals.sort((slot1, slot2) => {
          return compareAsc(slot1.renderDate, slot2.renderDate);
        });
      }
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_LOADED, {
        intervals: intervals.length,
      });
      if (intervals.length > 0) {
        if (walkinOnly) {
          bookingBloc.clearWalkinOnly();
        }
      } else {
        bookingBloc.setWalkinOnly();
      }
      return { intervals };
    }
    bookingBloc.setWalkinOnly();
    analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_LOADED, {
      intervals: 0,
    });
    return { intervals: [] };
  }, [booking]);

  const handleTimeSelected = React.useCallback(
    (slot) => () => {
      bookingBloc.setBookingTime(slot);
    },
    [bookingBloc],
  );

  const available = intervals && intervals.length > 0;

  return loadingData ? (
    <Loading />
  ) : (
    <Grid>
      {available && (
        <Grid item row xs={12} sx={{ marginTop: mediaQuery.isSm ? '-2.5em' : null }}>
          <TextBody2Standard>
            <FormattedMarkdown
              id={'interaction.appointment.reservation.selecttime.subtext.0'}
              defaultMessage={'You can see the approximated waiting on the graph below.'}
            />
          </TextBody2Standard>
        </Grid>
      )}
      {available && <TimeBarChart schedulingIntervals={schedulingIntervals} />}
      <Grid item row xs={12}>
        {intervals && intervals.length > 0 ? (
          intervals.map((time) => {
            return !(time.capacity === 0) ? (
              <TimeIntervalButton
                key={`btn-slot-${time.start}`}
                onClick={handleTimeSelected(time.start)}
                isSelected={selectedSlot === time.start}
              >
                {dateUtil.formatTimeDisplay(
                  dateUtil.parseDate(
                    dateUtil.addMinutesToUtc(time.availableSlots[0].start, time.start),
                  ),
                )}
              </TimeIntervalButton>
            ) : null;
          })
        ) : (
          <TextBody2Standard
            sx={{
              fontWeight: '500',
              lineHeight: '32px',
            }}
          >
            <FormattedMarkdown
              id={'booking.appointment.schedule.chart.unavailable'}
              defaultMessage={'No time slots are available for selected date.'}
            />
          </TextBody2Standard>
        )}
      </Grid>
      {walkinSlot && !getInLine && (
        <Grid item row xs={12}>
          <TextBody2Standard
            sx={{
              fontWeight: '500',
              lineHeight: '32px',
            }}
          >
            WALK-INS:

          </TextBody2Standard>
          <TextBody2Standard
            sx={{
              fontWeight: '500',
              lineHeight: '32px',
            }}
            color={walkinSlot === 'CURRENTLY UNAVAILABLE' ? 'secondary' : 'primary'}
          >

            {walkinSlot}
          </TextBody2Standard>
        </Grid>
      )}
      {intervals && intervals.length === 0 && (
        <Grid item row xs={12}>
          <H2TextTitleContent>
            {booking.selectedOrgCapacity === 'full' ? (
              <FormattedMarkdown
                id={`booking.timeselector.noslots.options.full.text`}
                defaultMessage={`We have reached our patient capacity for the today. We apologize for the inconvenience.\n`}
              />
            ) : (
              <FormattedMarkdown
                id={`booking.timeselector.noslots.options.text`}
                defaultMessage={`Our online reservations are currently full but we have walk-in availability.\n`}
              />
            )}
          </H2TextTitleContent>
        </Grid>
      )}
    </Grid>
  );
};

export default TimeSelector;
