import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { providerStorage } from '../../utils/provider.qs';
import { authService } from '../../utils/auth';

export class Bloc extends DecodedBloc {
  appointmentApi;

  constructor(props) {
    super({ ...props });
    this.appointmentApi = props.appointmentApi;
  }

  initialise = async () => {
    setTimeout(() => {
      console.log('Redirecting');
      window.location = 'https://google.com';
    }, 2000);
    authService.logout();
    providerStorage.clearProvider();
  };
}

export class Constants {}

export class BlocEvent {}
