import {routeUtil} from '../../../utils/route.name'
import {BookingBlocEvent} from './events.bloc'
import {AppointmentConstants} from "../../../const";

export const isWalkin = (bloc) => {
  const {isWalkin} = bloc.subject.value
  return isWalkin
}

export const isVirtual = (bloc) => {
  const {appointment} = bloc.subject.value
  return appointment.type === 'VIRTUAL'
}

export const showWalkinInstructions = (bloc) => {
  const {appointment} = bloc.subject.value
  bloc.events.next({
    type: BookingBlocEvent.NAVIGATE_TO,
    data: {url: routeUtil.buildAWalkinOnlyInstructions(appointment.provider)},
  })
}

export const service = () => AppointmentConstants.DEFAULT_SERVICE

export const setSelectedDate = (date, bloc) => {
  const {booking} = bloc.subject.value

  let newBooking = {...booking}
  newBooking.selectedDate = date
  newBooking.availability = undefined
  newBooking.reminderTime = undefined

  bloc.subject.next({
    ...bloc.subject.value,
    booking: newBooking,
  })

  bloc.events.next({type: BookingBlocEvent.DATE_SELECTED, data: {}})
}

export const isTomorrow = (bloc) => {
  const {booking} = bloc.subject.value
  const now = new Date()

  //==> Check if selectedDate is a Date object
  const date =
    booking.selectedDate instanceof Date ? booking.selectedDate : new Date(booking.selectedDate)

  return now.getDate() + 1 === date?.getDate()
}

export const bookingSelectedDate = (bloc) => {
  const {booking} = bloc.subject.value
  return booking.selectedDate
}
