import * as React from 'react';
import { RelationShipForm } from './RelationShipForm';


export const EmergencyContact = ({ emergency = {}, loading, handleSubmit, handleBack }) => {
  return (
    <RelationShipForm
      messageId="registration.user.bubble.emergencyContact"
      message="Please tell us who should we contact in case of an emergency."
      formName={"emergency.contact"}
      formDefaultTitle={"Emergency Contact"}
      label="Emergency Contact"
      loading={loading}
      data={emergency}
      handleSubmit={handleSubmit}
      handleBack={handleBack}
    />
  );
};
