import React, { Component } from 'react';

import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { authService } from '../../../utils/auth';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { FormattedMessage } from 'react-intl';

import CircularProgress from '@mui/material/CircularProgress';
import { Grid, styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DateMask, SsnTextMask } from '../../shared/InputTextMask';
import { GENDER_MANDATORY_OPTIONS } from '../../../utils/user';
import Typography from '@mui/material/Typography';
import ChatBubble from '../../shared/ChatBubble';
import PageContainer from '../../common/PageContainer';
import { PhoneNumber } from '../../shared/PhoneNumber/PhoneNumber';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import {
  PageContent,
  PageFooter,
  ScrollableBox,
  ScrollableQuinnContainer,
  ScrollableWrapper,
} from '../../common/ScrollableContainer';
import { TextH5Standard } from '../../shared/Typography';
import { DecodedButton, DecodedSecondaryButton } from '../../common/DecodedButton';
import { logger } from '../../../utils/logging';

const styles = (theme) => ({
  validation: {
    display: 'contents',
    width: '100%',
  },
  title: {
    paddingTop: '1em',
    color: '#FF7700',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
  },
  formInput: {
    flex: '1',
  },
  buttonGroup: {
    paddingTop: '2em',
  },
  button: {
    width: '100%',
    padding: '1em',
  },
});

class BasicInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      readOnly: true,
    };
  }

  componentDidMount() {
    console.log(this.props);
  }

  onEdit = () => {
    this.setState({ readOnly: false });
    this.props.setDirty();
  };

  isLoading = () => {
    return this.props.loading || authService.isLoading();
  };

  render() {
    let {
      classes,
      loading,
      firstName,
      lastName,
      gender,
      ssn,
      dateOfBirth,
      number,
      email,
      code,
      handleTextChange,
      onCodeChange,
      handleGenderChange,
      handleNext,
      handleBack,
    } = this.props;

    let { readOnly } = this.state;

    return (
      <PageContainer loading={loading} onBack={handleBack}>
        <ScrollableQuinnContainer
          messageId="update.user.bubble.one"
          message={
            'Please verify that the following information is correct. If you need to make any changes, please click EDIT below, otherwise click NEXT to proceed.'
          }
        >
          <ValidatorForm
            autoComplete="off"
            onSubmit={() => handleNext(!readOnly)}
            className={classes.validation}
          >
            <PageContent>
              <div className={classes.formInput}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextH5Standard orange sx={{ paddingTop: '1em' }}>
                      <FormattedMarkdown
                        id="registration.user.step.one"
                        defaultMessage="1 of 2 Personal Information"
                      />
                    </TextH5Standard>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      autoFocus={true}
                      id="firstName"
                      label={
                        <FormattedMessage
                          id="registration.field.label.firstName"
                          defaultMessage={'First Name'}
                        />
                      }
                      name="firstName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={firstName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.given"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={handleTextChange}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      id="lastName"
                      label={
                        <FormattedMessage
                          id="registration.field.label.lastName"
                          defaultMessage={'Last Name'}
                        />
                      }
                      type="text"
                      name="lastName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={lastName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.family"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={handleTextChange}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SelectValidator
                      id="gender"
                      fullWidth
                      select
                      required
                      label={
                        <FormattedMessage
                          id="registration.field.label.gender"
                          defaultMessage={'Sex at Birth'}
                        />
                      }
                      value={gender}
                      onChange={handleGenderChange}
                      helperText={
                        <FormattedMessage
                          id="registration.field.helper.gender"
                          defaultMessage={'Please select your sex at birth'}
                        />
                      }
                      validators={['required']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.gender"
                          defaultMessage={'This field is required'}
                        />,
                      ]}
                      disabled={readOnly}
                    >
                      {GENDER_MANDATORY_OPTIONS.map((option) => {
                        const Item = styled(MenuItem)({ '&:hover': { color: '#ffffff' } });
                        return (
                          <Item key={option.value} value={option.value}>
                            {option.label}
                          </Item>
                        );
                      })}
                    </SelectValidator>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      id="dateOfBirth"
                      label={
                        <FormattedMessage
                          id="registration.field.label.dob"
                          defaultMessage={'Date of Birth'}
                        />
                      }
                      name="dateOfBirth"
                      placeholder="MM/dd/yyyy e.g. 12/31/2000"
                      autoComplete="bday"
                      value={dateOfBirth}
                      onChange={handleTextChange}
                      validators={['isDateValid']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.dob"
                          defaultMessage={'Invalid date of birth'}
                        />,
                      ]}
                      InputProps={{
                        inputComponent: DateMask,
                      }}
                      inputProps={{ inputMode: 'numeric' }}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      required
                      fullWidth
                      id="email"
                      label={
                        <FormattedMessage
                          id="registration.field.label.email"
                          defaultMessage={'Email Address'}
                        />
                      }
                      name="email"
                      type="email"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                        inputMode: 'email',
                      }}
                      value={email}
                      onChange={handleTextChange}
                      validators={['isEmail']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.email"
                          defaultMessage={'email is not valid'}
                        />,
                      ]}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid container item xs={12} sm={12}>
                    <PhoneNumber
                      numberLabel={
                        <FormattedMessage
                          id="registration.field.label.mobile"
                          defaultMessage={'Mobile Number'}
                        />
                      }
                      numberValue={number}
                      numberRequired
                      onNumberChange={handleTextChange}
                      codeRequired
                      codeValue={{ callingCode: code, code: this.props.codeCountry }}
                      onCodeChange={(e) => {
                        const codeValues = e.target.value;
                        onCodeChange(
                          codeValues.callingCode,
                          this.props.addressLine1 === '' ? codeValues.code : undefined,
                          codeValues.code,
                        );
                      }}
                      selectedCountry={this.props.codeCountry}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {readOnly ? (
                      <TextValidator
                        id="ssn"
                        name="ssn"
                        label={
                          <FormattedMessage
                            id="registration.field.label.ssn"
                            defaultMessage={'SSN (optional)'}
                          />
                        }
                        fullWidth
                        placeholder="e.g. 856-45-6789"
                        value={ssn}
                        disabled={true}
                      />
                    ) : (
                      <TextValidator
                        id="ssn"
                        name="ssn"
                        label={
                          <FormattedMessage
                            id="registration.field.label.ssn"
                            defaultMessage={'SSN (optional)'}
                          />
                        }
                        inputMode="numeric"
                        fullWidth
                        placeholder="e.g. 856-45-6789"
                        value={ssn}
                        onChange={handleTextChange}
                        validators={[
                          'matchRegexp:(^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$)',
                        ]}
                        errorMessages={[
                          <FormattedMarkdown
                            id="registration.field.error.ssn"
                            defaultMessage={'Invalid social security number'}
                          />,
                        ]}
                        InputProps={{
                          inputComponent: SsnTextMask,
                        }}
                        disabled={readOnly}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            </PageContent>
            <PageFooter>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                className={classes.buttonGroup}
              >
                {readOnly && (
                  <Grid item xs={12} sm={6} className={classes.button}>
                    <DecodedSecondaryButton
                      type="button"
                      variant="outlined"
                      color="primary"
                      disabled={this.isLoading()}
                      onClick={this.onEdit}
                    >
                      {this.isLoading() ? (
                        <CircularProgress size="1.5em" />
                      ) : (
                        <FormattedMessage
                          id="registration.button.label.edit"
                          defaultMessage="Edit"
                        />
                      )}
                    </DecodedSecondaryButton>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} className={classes.button}>
                  <DecodedButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={this.isLoading()}
                  >
                    {this.isLoading() ? (
                      <CircularProgress size="1.5em" />
                    ) : (
                      <FormattedMessage
                        id="registration.button.label.submit"
                        defaultMessage="Next"
                      />
                    )}
                  </DecodedButton>
                </Grid>

              </Grid>
            </PageFooter>
          </ValidatorForm>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(BasicInformation);
