import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authService } from './auth';
import { LOGIN_ROUTE, ROOT_ROUTE } from './route.name';
import 'firebase/auth';
import {globalBloc} from '../components/global.bloc'

function canRedirect(pathname) {
  return (
    pathname.startsWith('/virtual-clinic') ||
    pathname.startsWith('/conversation') ||
    pathname.startsWith('/booking')
  );
}

const defaultRoute = ROOT_ROUTE;

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { user } = globalBloc.subject.value
      const loggedIn = user || authService.getUser();
      console.log({loggedIn, user})

      return loggedIn !== null ? (
        <Component {...props} />
      ) : (
        <Redirect
          push
          to={{
            pathname: LOGIN_ROUTE,
            search: props.location.search,
            state: {
              next: canRedirect(props.location.pathname) ? props.location.pathname : defaultRoute,
            },
          }}
        />
      );
    }}
  />
);
