import {appointmentApi} from '../../../utils/services/appointments.api'
import {AnalyticsEvent, analyticsEventLogger} from '../../../utils/events'
import {notificationService} from '../../../utils/notification'
import {BookingBlocEvent} from './events.bloc'
import {globalBloc} from '../../global.bloc'
import {providerStorage} from '../../../utils/provider.qs'
import {dateUtil} from '../../../utils/date'

const extractSlotStartDate1 = (booking) => {
  //TODO might need to remove if statement
  if (booking.availability) {
    const interval = booking.availability.results
      .flatMap((_result) => _result.intervals)
      .filter((_interval) => {
        if (!_interval.availableSlots) return false
        if (_interval.start === booking.selectedSlot) return true
        const arr = _interval.availableSlots.filter(
          (_slot) => _slot.slotId === booking.selectedSlot,
        )
        return arr.length > 0
      })[0]

    const slot = interval.availableSlots

    const selectedStart = dateUtil.parseDate(interval.start)
    if (slot.length > 0) {
      selectedStart.setMinutes(slot[0].start.split(':')[1])
    }
    return selectedStart
  } else {
    return undefined
  }
}

const getIntervalWithSelectedSlot = (booking) => {
  return booking.availability.results
    .flatMap(result => result.intervals)
    .find(interval => {
      if (!interval.availableSlots) return false
      if (interval.start === booking.selectedSlot) return true
      return interval.availableSlots.some(slot => slot.slotId === booking.selectedSlot)
    })
}

const getSelectedStart = (interval) => {
  const selectedStart = dateUtil.parseDate(interval.start)
  if (interval?.availableSlots?.length > 0) {
    selectedStart.setMinutes(interval.availableSlots[0].start.split(':')[1])
  }
  return selectedStart
}

const getClosestInterval = (booking) => {
  const now = new Date();
  let closestInterval = null;
  let closestDiff = Infinity;

  const intervals = booking.availability.intervals
  console.log('intervals', intervals)
  intervals.forEach(interval => {
    const intervalStart = dateUtil.parseDate(interval.start);
    const diff = Math.abs(now - intervalStart);

    if (diff < closestDiff) {
      closestDiff = diff;
      closestInterval = interval;
    }
  });

  return closestInterval;
};

export const extractSlotStartDate = (booking, getInLine = false) => {
  if (!booking.availability) return undefined;

  let interval;

  if (getInLine) {
    interval = getClosestInterval(booking);
  } else {
    interval = getIntervalWithSelectedSlot(booking);
  }

  return interval ? getSelectedStart(interval) : undefined;
};


export const createAppointmentRequestData = (step, bloc) => {
  const {appointment, booking} = bloc.subject.value
  const {getInLine, booking: globalBooking} = globalBloc.subject.value

  let selectedStart

  if (getInLine) {
    selectedStart = extractSlotStartDate(globalBooking, true) //?--> sets time for getInLine <--//
  } else {
    selectedStart = extractSlotStartDate(booking)
  }

  let participants = [
    {
      role: 'O_SRV_PROV',
      identifier: {
        code: 'id',
        system: 'decoded/party/organisation',
        value: booking.selectedOrg || appointment.provider,
      },
    },
  ]

  if (booking.doctor) {
    participants.push({
      role: 'S_PROVIDER',
      identifier: {
        value: booking.doctor,
      },
    })
  }

  let requestData

  if (providerStorage.hasProvider()) {
    requestData = {
      service: {
        code: {
          value: appointment.service,
        },
        channel: {
          value: appointment.type,
        },
      },
      slot: {
        intervalStart: selectedStart,
      },
      reminder: booking.reminderTime || 60,
      participants: participants,
    }
  }

  if (step === 'org-selector') {
    requestData = {
      command: 'set_service_provider',
      participants: participants,
    }
  }

  if (step === 'time-selector') {
    requestData = {
      command: 'update_slot',
      start: selectedStart,
    }
  }

  return requestData
}

export const saveAppointmentProgress = (step, bloc) => {
  const {appointment, appointmentId} = bloc.subject.value

  appointmentApi.updateAppointment(appointment?.id ?? appointmentId, createAppointmentRequestData(step, bloc))
    .then((value) => {
      bloc.events.next({type: BookingBlocEvent.TIME_SELECTED, data: {}})
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {})
      if (step === 'time-selector') {
        globalBloc.updateBooking({currentStep: 'booking_confirm'})
      }
      return value
    })
    .catch((reason) => {
      notificationService.error('There was a problem selecting the desired time. Please select another time. If the problem persists please contact the clinic.')
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {
        status: 'error',
        message: `${reason.message}`,
      })
      return reason
    })
}
