import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';

import { Route, Switch, withRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';


import Login from '../Login';
import Secure from '../Secure';

import { PrivateRoute } from '../../utils/protected.route';
import LinkHandler from '../Link';
import LocalNotifications from '../shared/Notifications';
import PostEncounter from '../Secure/VirtualClinic/PostEncounter';
import {
  INVALID_ROUTE,
  LINK_ROUTE,
  LOGIN_ROUTE,
  QUINN_TERMINATE_ROUTE,
  ROOT_ROUTE,
  VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE,
  KIOSK_ROUTE,
  WALKIN_ONLY,
  THANKYOU_ROUTE,
  PATIENT_REGISTRATION_ONLY_RESULT_ROUTE,
  EMPLOYER_OCC_ROUTE,
  PRE_AUTH_QUINN_ROUTE,
  PRE_AUTH_BOOKING_ROUTE,
  PRE_AUTH_URGENTCARE_ROUTING_ROUTE,
  OCCHEALTH_ROUTING_ROUTE, POST_BOOKING_ROUTE, APPOINTMENT_CHECKIN_CONFIRMATION,
} from '../../utils/route.name';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isAfter, isBefore, isValid, parse, subYears } from 'date-fns';
import { messages } from '../../utils/intl/messages';
import Loading from '../shared/Loading';
import { globalBloc } from '../global.bloc';
import PostTerminate from '../Secure/Assistant/PostTerminate';
import { userInfoUtil } from '../../utils/user';
import { getThemeBasedOnTenant } from './themes';
import Invalid from '../Invalid';
import KioskAdmin from '../KioskAdmin';
import WalkinNotice from '../WalkinNotice';
import ThankYou from '../ThankYou';
import RegistrationOnlyComplete from '../RegistrationOnlyComplete';
import AssistantChat from '../../components/Secure/Assistant';
import { Booking } from '../Booking/Booking';
import { authService } from '../../utils/auth';
import Employer from '../BookingPaymentMethod/Employer';
import { uriStorage } from '../../utils/storage';
import IdleTimer from 'react-idle-timer';
import { providerStorage } from '../../utils/provider.qs';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { logger } from '../../utils/logging';
import PreAuthUrgentCareRouting from '../UrgentCareRouting/PreAuthUrgentCareRouting';
import OccHealthRouting from '../OccHealthRouting';
import PreDialog from '../shared/Dialogs/PreDialog';
import ExpiringSessionDialog from '../shared/Dialogs/ExpiringSessionDialog';
import LogoutDialog from '../shared/Dialogs/LogoutDialog';
import PostBooking from "../Secure/PostBooking";
import CheckinConfirmation from "../CheckinConfirmation";

const styles = {
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    maxHeight: '100%',
  },
};

const TIMEOUT = 60 * 15;
const KIOSK_TIMEOUT = 60 * 5;
const LEEWAY = 60;

class App extends Component {
  globalStateSubsciption;

  constructor(props) {
    super(props);
    this.state = {
      initialising: true,
      loading: true,
      countdownSeconds: LEEWAY,
      idle: false,
      open: false,
      logoutDialogOpen: false,
      showPreDialog: true,
      isKiosk: providerStorage.isKiosk(),
    };

    this.__watchGlobalState = this.__watchGlobalState.bind(this);
    this.idleTimer = null;
    this.countDownTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.onCountdown = this._countdown.bind(this);
    this.onCancelIdleTimeout = this._cancelIdleTimeout.bind(this);
    this.idleTimeout = providerStorage.isKiosk() ? KIOSK_TIMEOUT : TIMEOUT;
  }

  componentDidMount() {
    logger.debug('App has mounted.');




    if (process.env.REACT_APP_HTTPS !== 'http') {
      window.location.protocol === 'http:' &&
        (window.location.href = window.location.href.replace(/^http:/, 'https:'));
    }

    this.globalStateSubsciption = globalBloc.subscribeToState(this.__watchGlobalState);

    ValidatorForm.addValidationRule('isDateValid', (value) => {
      const date = parse(value, 'MM/dd/yyyy', new Date());
      if (!isValid(date)) return false;

      const now = new Date();

      return isBefore(date, now) && isAfter(date, subYears(now, 130));
    });

    ValidatorForm.addValidationRule('isValidName', (value) => {
      return userInfoUtil.validName(value.trim());
    });
  }

  componentWillUnmount() {
    logger.debug('App has unmounted.');

    globalBloc.dispose();

    if (this.countDownTimer) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = undefined;
    }
  }
  __watchGlobalState = (state) => {
    this.setState({
      ...state,
    });
  };

  _onAction(e) {}

  _onActive(e) {}

  _onIdle(e) {
    if (!this.countDownTimer) {
      this.countDownTimer = setInterval(this.onCountdown, 1000);
    }
    this.setState({
      idle: true,
    });
    analyticsEventLogger.log(AnalyticsEvent.IDLE_SHOW, {});
  }

  _countdown = () => {
    const { countdownSeconds } = this.state;

    if (countdownSeconds < 0) {
      this.doLogout();
    } else {
      this.setState({
        countdownSeconds: countdownSeconds - 1,
      });
    }
  };

  _cancelIdleTimeout = () => {
    this.idleTimer.reset();
    clearInterval(this.countDownTimer);
    this.countDownTimer = undefined;
    this.setState({
      idle: false,
      countdownSeconds: LEEWAY,
    });
  };

  doLogout = () => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
      window.location = '/';
      this.setState({ logoutDialogOpen: false });
    });
  };

  cancelLogoutDialog = () => {
    this.setState({ logoutDialogOpen: false });
  };

  openLogoutDialog = () => {
    this.setState({ logoutDialogOpen: true });
  };

  handleClosePreDialog = () => {
    this.setState({ showPreDialog: false });
    globalBloc.updateGlobalBloc({ showBanner: false });
  };

  render() {
    const { classes } = this.props;
    const { initialising, idle, countdownSeconds, logoutDialogOpen, isKiosk } = this.state;
    const {loading, showBanner}  = globalBloc.subject.value;

    if (initialising) {
      console.log('loading...', loading);
      return (
        <>
          <Loading />
        </>
      );
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getThemeBasedOnTenant()}>
          <IntlProvider locale="en" messages={messages}>
            <div className={classes.root}>
              <CssBaseline />
              <Switch>
                <Route path={POST_BOOKING_ROUTE} component={PostBooking} />
                <Route path={PRE_AUTH_QUINN_ROUTE} component={AssistantChat} />
                <Route path={PRE_AUTH_BOOKING_ROUTE} component={Booking} />
                <Route
                  path={PRE_AUTH_URGENTCARE_ROUTING_ROUTE}
                  component={PreAuthUrgentCareRouting}
                />
                <Route path={INVALID_ROUTE} component={Invalid} />
                <Route path={THANKYOU_ROUTE} component={ThankYou} />
                <Route path={KIOSK_ROUTE} component={KioskAdmin} />
                <Route path={LINK_ROUTE} component={LinkHandler} />
                <Route path={LOGIN_ROUTE} component={Login} />
                <Route path={VIRTUAL_CLINIC_POST_ENCOUNTER_ROUTE} component={PostEncounter} />
                <Route path={QUINN_TERMINATE_ROUTE} component={PostTerminate} />
                <Route path={WALKIN_ONLY} component={WalkinNotice} />
                <Route path={OCCHEALTH_ROUTING_ROUTE} component={OccHealthRouting} />
                <Route
                  path={PATIENT_REGISTRATION_ONLY_RESULT_ROUTE}
                  component={RegistrationOnlyComplete}
                />
                <Route path={EMPLOYER_OCC_ROUTE} component={Employer} />
                <Route path={APPOINTMENT_CHECKIN_CONFIRMATION} component={CheckinConfirmation}/>

                <PrivateRoute path={ROOT_ROUTE} component={Secure} />
              </Switch>
              <IdleTimer
                ref={(ref) => {
                  this.idleTimer = ref;
                }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * this.idleTimeout}
              />
              <LogoutDialog
                open={logoutDialogOpen}
                doLogout={this.doLogout}
                cancelLogoutDialog={this.cancelLogoutDialog}
              />
              <ExpiringSessionDialog
                idle={idle}
                countdownSeconds={countdownSeconds}
                onCancelIdleTimeout={this.onCancelIdleTimeout}
              />
              {!isKiosk && showBanner &&
                <PreDialog
                showPreDialog={this.state.showPreDialog}
                handleClosePreDialog={this.handleClosePreDialog}
              />
              }
            </div>
            <LocalNotifications />
          </IntlProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default withStyles(styles)(withRouter(App));
