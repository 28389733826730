import {Box, Card, CardContent, CardHeader, useTheme} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {providerUtil} from '../../../../utils/provider'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import {
    TextLargeStandard,
    TextMediumStandard,
    TextSmallStandard,
} from '../../../shared/Typography'
import DecodedButton from '../../../common/DecodedButton'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {PRE_AUTH_QUINN_ROUTE} from '../../../../utils/route.name'
import TodayIcon from '@mui/icons-material/Today'
import {globalBloc} from '../../../global.bloc'
import {LandingOrgSelectorBloc} from './LandingOrgSelector.bloc'
import {analyticsEventLogger} from '../../../../utils/events'
import {Loading} from '../../../../shared-library'

const LandingOrgCard = ({organisation, setSelected, isHovered}) => {
    const theme = useTheme()

    const [bloc] = useState(() => new LandingOrgSelectorBloc({analyticsLogger: analyticsEventLogger}))
    const [blocState, setBlocState] = useState(bloc.subject.value);
    console.log(blocState)

    const {
        reservationSlot,
        walkinSlot,
        firstAvailableDate,
        firstAvailableCapacity,
        estimatedWaitTime,
        patientsInLine,
        getInLineAvailable,
        firstWalkinSlot,
        availability,
        walkinFirstAvailableCapacity,
        loading,
    } = blocState


    useEffect(() => {
        const subscription = bloc.subscribeToState(setBlocState)

        const fetchSchedules = async () => {
            globalBloc.updateGlobalBloc({getInLine: false});

            await bloc.getInLineSchedule(organisation.id);
            await bloc.reservationSchedule(organisation.id);
        };

        fetchSchedules();

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    const history = useHistory()


    const handleMakeReservation = () => {
        setSelected({
            organisation,
            firstAvailableCapacity: firstAvailableCapacity,
            firstAvailableDate: firstAvailableDate,
            metadata: 'added by handleMakeReservation',
        })

        globalBloc.updateGlobalBloc({getInLine: false})

        setTimeout(() => {
            history.push(PRE_AUTH_QUINN_ROUTE)
        }, 1000)
    }

    const handleGetInLine = () => {
        globalBloc.updateGlobalBloc({getInLine: true})

        setSelected({
            organisation,
            ...firstWalkinSlot,
            availability: availability,
            selectedSlot: firstWalkinSlot.slots[0],
            firstAvailableCapacity: walkinFirstAvailableCapacity,
            firstAvailableDate: firstWalkinSlot.slots[0].startDatetime,
            metadata: 'added by handleGetInLine',
        })

        setTimeout(() => {
            history.push(PRE_AUTH_QUINN_ROUTE)
        }, 1000)
    }

    return (
        <Card
            sx={{
                boxShadow: isHovered
                    ? '0px 0px 5px 2px rgba(0, 209, 255, 0.5)'
                    : '0px 1px 3px 1px rgba(0, 0, 0, 0.3)',
                width: '100%',
                minHeight: '310px',
                margin: '0',
                border: isHovered ? '1px solid rgba(0, 209, 255, 0.5)' : '1px solid transparent',
                borderRadius: '6px',
            }}
            variant="outlined"
        >
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        minHeight: '320px',
                        padding: '0 16px 16px 16px',
                    }}>
                    <Loading message="Loading location data..." primary/>
                </Box>
            ) : (
                <>
                    <CardHeader
                        title={
                            <>
                                <TextMediumStandard
                                    sx={{
                                        fontWeight: '800 !important',
                                        lineHeight: '18px',
                                        color: '#5C5D5F',
                                    }}
                                >
                                    {organisation.name}
                                </TextMediumStandard>
                            </>
                        }
                    />
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            minHeight: '320px',
                            padding: '0 16px 16px 16px',
                        }}
                    >
                        <Box
                            mb={2}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '36px',
                                height: '36px',
                            }}
                        >
                            <LocationOnIcon
                                sx={{
                                    fontSize: '18px',
                                    color: theme.palette.accent.main,
                                }}
                                fontSize={'large'}
                                color={'primary'}
                            />

                            <TextSmallStandard
                                sx={{
                                    color: '#5C5D5F',
                                    paddingLeft: '10px',
                                }}
                            >
                                {providerUtil.formatOrganisationAddress(organisation)}
                            </TextSmallStandard>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // paddingLeft: '10px',
                            }}
                        >


                            <Box
                                mb={2}
                                flexGrow={1}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '-12px',
                                }}

                            >
                                <QueryBuilderIcon
                                    sx={{
                                        fontSize: '18px',
                                        marginTop: '5px',
                                        color: theme.palette.accent.main,
                                    }}
                                    fontSize={'large'}
                                    color={'primary'}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingLeft: '10px',
                                    }}
                                >
                                    <TextLargeStandard
                                        style={{
                                            color: '#5C5D5F',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        Walk-In availability:
                                    </TextLargeStandard>

                                    {getInLineAvailable && (
                                        <>
                                            <TextMediumStandard
                                                sx={{
                                                    fontSize: '1.1rem !important',
                                                    fontWeight: 'bold',
                                                    marginTop: '-20px',
                                                    lineHeight: '24px',
                                                    // color: walkinSlot === 'UNAVAILABLE' ? 'red' : '#2D8088',
                                                }}
                                            >
                                                PATIENTS IN LINE -
                                                <span
                                                    style={{color: '#00D1FF'}}> {walkinSlot === 'CURRENTLY AVAILABLE' ? patientsInLine : null}</span>
                                                {/*{walkinSlot || 'Loading'}*/}
                                            </TextMediumStandard>
                                            <TextMediumStandard
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '1.1rem !important',
                                                    lineHeight: '24px',
                                                    // color: walkinSlot === 'UNAVAILABLE' ? 'red' : '#2D8088',
                                                }}
                                            >
                                                ESTIMATED WAIT TIME -
                                                <span
                                                    style={{color: '#00D1FF'}}> {walkinSlot === 'CURRENTLY AVAILABLE' ? estimatedWaitTime : null} MINUTES</span>
                                            </TextMediumStandard>
                                        </>
                                    )}
                                </Box>
                            </Box>


                            <Box>
                                {getInLineAvailable ? (
                                    <DecodedButton
                                        sx={{
                                            marginTop: 'auto',
                                        }}
                                        onClick={handleGetInLine}
                                    >
                                        GET IN LINE
                                    </DecodedButton>
                                ) : (
                                    <DecodedButton
                                        sx={{
                                            marginTop: 'auto',
                                            padding: '10px',
                                        }}
                                        disabled={true}
                                    >
                                        Virtual line will be available tomorrow at 7am
                                    </DecodedButton>
                                )}

                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // paddingLeft: '10px',
                            }}
                        >

                            <Box
                                mb={2}
                                flexGrow={1}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: 1,
                                    marginBottom: 1,

                                }}
                            >
                                <TodayIcon
                                    sx={{
                                        fontSize: '18px',
                                        marginTop: '5px',
                                        color: theme.palette.accent.main,
                                    }}
                                    fontSize={'large'}
                                    color={'primary'}
                                />

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingLeft: '10px',
                                    }}
                                >

                                    <TextLargeStandard
                                        sx={{
                                            paddingBottom: 0,
                                            lineHeight: '26px',
                                            color: '#5C5D5F',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        Next reserved availability:
                                    </TextLargeStandard>
                                    <TextMediumStandard
                                        sx={{
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            marginTop: '-20px',
                                            lineHeight: '24px',
                                            color: reservationSlot === 'UNAVAILABLE' ? 'red' : '#00D1FF',
                                        }}
                                    >
                                        {reservationSlot || 'Loading'}
                                    </TextMediumStandard>
                                </Box>
                            </Box>


                            {firstAvailableCapacity !== 'not set' && (
                                <DecodedButton
                                    sx={{
                                        marginTop: 'auto',
                                    }}
                                    onClick={handleMakeReservation}
                                    disabled={firstAvailableCapacity === 'full'}
                                >
                                    {firstAvailableCapacity === 'full' ? 'NOT AVAILABLE' : 'MAKE A RESERVATION'}
                                </DecodedButton>
                            )}

                        </Box>

                    </CardContent>
                </>
            )}
        </Card>
    )

}

export default LandingOrgCard
