import * as rxjs from 'rxjs'
import {appointmentApi} from '../../../utils/services/appointments.api'
import {format, parse} from 'date-fns'
import {DATE_FORMAT} from '../../../utils/user'
import {notificationService} from '../../../utils/notification'
import {dateUtil} from '../../../utils/date'
import {AnalyticsEvent, analyticsEventLogger} from '../../../utils/events'
import {providerStorage} from '../../../utils/provider.qs'
import {LOGIN_ROUTE, routeUtil, THANKYOU_ROUTE} from '../../../utils/route.name'
import {appointmentIntervalUtil} from '../../../utils/appointment'
import {authService} from '../../../utils/auth'
import {logger} from '../../../utils/logging'
import {CheckSession, SetSession} from '../../../utils/storage'
import {globalBloc} from '../../global.bloc'
import {remoteSteps, walkinSteps} from '../stepOrchestrator'
import {notifyAndLog} from '../../../utils/services/notifyAndLog'
import {initialiseBooking} from './initialize.bloc'
import {createAppointmentRequestData, saveAppointmentProgress} from './updaters.bloc'
import {BookingBlocEvent} from './events.bloc'
import {setSelectedDate} from './helpers.bloc'

export class BookingBloc {
  constructor(appointmentId, appointmentType) {
    const organisationId = providerStorage.getCurrentProvider()
    const isWalkin = providerStorage.isWalkin()
    const acuity = sessionStorage.getItem('acuity' + appointmentId) || 'non-urgent'

    const {firstAvailableCapacity, firstAvailableDate, organisation} =
      globalBloc.subject.value.booking
    const {id} = organisation

    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      loadingData: true,
      isWalkin: isWalkin,
      acuity: acuity,
      appointmentId: appointmentId,
      appointmentType: appointmentType,
      booking: {
        selectedOrg: id,
        selectedOrgCapacity: firstAvailableCapacity,
        selectedDate: firstAvailableDate,
      },
      calendarSummary: undefined,
      availableOrganisations: [],
      systemProperties: [],
      token: authService.getToken() || '',
      manageAppointments: CheckSession('action', 'manage-appointments'),
    })

    this.events = new rxjs.Subject()

    this.__initialise(appointmentId, organisationId)

  }

  __updateSubject = (value) => {
    const newState = {
      ...this.subject.value,
      ...value,
    }
    this.subject.next(newState)
  }

  subscribeToEvents = (func) => this.events.subscribe(func)
  subscribeToState = (func) => this.subject.subscribe(func)

  __initialise = (appointmentId, organisationId) => {
    return initialiseBooking(appointmentId, organisationId, this)
  }

  clearWalkinOnly = () => {
    let {booking} = this.subject.value
    booking.selectedSlot = ''
    this.__updateSubject({walkinOnly: false, booking: booking})
  }

  setWalkinOnly = () => {
    let {booking} = this.subject.value
    booking.selectedSlot = 'walkin'
    this.__updateSubject({walkinOnly: true, booking: booking})
  }

  _constructScheduleData = (intervalData, selectedDate) =>
    appointmentIntervalUtil.constructScheduleData(intervalData, selectedDate)

  loadServiceSchedule = () => {
    const {appointment, booking, isWalkin} = this.subject.value

    this.subject.next({
      ...this.subject.value,
      loadingData: true,
      calendarSummary: undefined,
    })

    const now = new Date()
    const lastPeriod = new Date(new Date().setDate(now.getDate() + 2))

    let start = format(now, DATE_FORMAT)
    let end = format(lastPeriod, DATE_FORMAT)

    return appointmentApi
      .getServiceScheduleSummary(
        start,
        end,
        appointment.service,
        booking.selectedOrg,
        undefined,
        !isWalkin,
      )
      .then(
        (value) => {
          this.subject.next({
            ...this.subject.value,
            calendarSummary: value.data,
            loadingData: false,
          })

          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {})
        },
        (reason) => {
          notificationService.error(
            'Error loading available times for appointment type. Please refresh. If the problem persists please contact the clinic.',
          )
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED, {
            status: 'error',
            message: `${reason}`,
          })
          this.subject.next({
            ...this.subject.value,
            loadingData: false,
          })
        },
      )
  }

  setReminderTime = (interval) => {
    const {booking} = this.subject.value

    let newBooking = {...booking}
    newBooking.reminderTime = interval

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    })

    this.events.next({type: BookingBlocEvent.REMINDER_SELECTED, data: {}})
  }

  loadSelectedDayAvailability = () => {
    const {appointment, booking, isWalkin} = this.subject.value

    this.clearWalkinOnly()

    this.subject.next({
      ...this.subject.value,
      loadingData: true,
    })

    let apiCall
    if (!providerStorage.hasProvider()) {
      apiCall = appointmentApi.anonGetAvailableAppointmentSchedule(
        booking.selectedDate,
        booking.selectedOrg ? booking.selectedOrg : appointment?.provider,
        appointment?.service, //TODO need to ensure this is revisited after server fix
        booking.doctor,
        !isWalkin,
      )
    } else {
      apiCall = appointmentApi.getAvailableAppointmentSchedule(
        booking.selectedDate,
        booking.selectedOrg || appointment.provider,
        appointment.service,
        booking.doctor,
        !isWalkin,
      )
    }

    return apiCall
      .then((value) => {
        let newBooking = {...booking, availability: value.data, reminderTimer: undefined}

        this.subject.next({
          ...this.subject.value,
          booking: newBooking,
          schedulingIntervals: this._constructScheduleData(
            value.data.results[0].intervals,
            booking.selectedDate,
          ),
        })
      })
      .finally(() => {
        this.subject.next({
          ...this.subject.value,
          loadingData: false,
        })
      })
  }

  loadAvailability = (start, end, organisationId, service, doctor, isWalkin) => {
    return appointmentIntervalUtil.loadAvailability(
      appointmentApi,
      start,
      end,
      organisationId,
      service,
      doctor,
      isWalkin,
    )
  }

  setBookingTime = (datetime, save, step) => {
    const {booking, appointment} = this.subject.value

    let newBooking = {...booking, selectedSlot: datetime, reminderTime: undefined}

    console.log('setBookingTime', newBooking, datetime, save, step)

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    })

    if (save) {
      appointmentApi
        .updateAppointment(appointment.id, this.__createAppointmentRequestData(step))
        .then(
          (value) => {
            this.events.next({type: BookingBlocEvent.TIME_SELECTED, data: {}})
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {})
          },
          (reason) => {
            notificationService.error(
              'There was a problem selecting the desired time. Please select another time. If the problem persists please contact the clinic.',
            )
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {
              status: 'error',
              message: `${reason.message}`,
            })
          },
        )
    } else {
      this.events.next({type: BookingBlocEvent.TIME_SELECTED, data: {}})
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT, {})
    }
  }

  __saveAppointmentProgress = (step) => {
    return saveAppointmentProgress(step, this)
  }

  __createAppointmentRequestData = (step, startTimeResolver) => {
    return createAppointmentRequestData(step, startTimeResolver, this)
  }

  __createAppointmentWalkinRequestData = () => {
    const {appointment, booking} = this.subject.value

    let participants = [
      {
        role: 'O_SRV_PROV',
        identifier: {
          value: booking.selectedOrg,
        },
      },
    ]

    if (booking.doctor) {
      participants.push({
        role: 'S_PROVIDER',
        identifier: {
          value: booking.doctor,
        },
      })
    }

    return {
      service: {
        code: {
          value: appointment.service,
        },
        channel: {
          value: appointment.type,
        },
      },
      reminder: booking.reminderTime || 60,
      participants: participants,
    }
  }


  confirmAppointment = () => {
    const {appointment} = this.subject.value
    const {getInLine} = globalBloc.subject.value

    return appointmentApi.remoteConfirmAppointment(appointment.id)
      .then((response) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
          appointmentId: appointment.id,
        })
        this.events.next({type: BookingBlocEvent.BOOKING_CONFIRMED, data: {}})
        return response
      })
      .catch((reason) => {
        // if (getInLine) {
        //   notificationService.error('Unable to get in line. Please try again or make a reservation.')
        // } else {
        //   notificationService.error('Reservation time slot is unavailable. Please try a different location or choose another time.')
        // }
        // analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT, {
        //   status: 'error',
        //   message: `${reason}`,
        // })
        // throw reason
        console.log('confirmAppointment error', reason)
      })
  }

  setSelectedOrg = (org, capacity, date) => {
    const {booking} = this.subject.value

    let newBooking = {...booking}
    newBooking.selectedOrg = org
    newBooking.selectedOrgCapacity = capacity
    newBooking.selectedDate = date || new Date()
    newBooking.availability = undefined
    newBooking.reminderTime = undefined

    analyticsEventLogger.log(
      AnalyticsEvent.BOOKING_APPOINTMENT_BOOKING_APPOINTMENT_ORGANISATION_LOADED_SELECT,
      {organisation: org, capacity: `${capacity}`},
    )

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    })
  }

  //? v===ADDED TO CLEAR ORG WHEN USER GOES BACK TO AVOID ERROR===v

  clearSelectedOrg = () => {
    const {booking} = this.subject.value

    let newBooking = {...booking}
    newBooking.selectedOrg = undefined
    newBooking.selectedOrgCapacity = undefined
    newBooking.selectedDate = undefined
    newBooking.availability = undefined
    newBooking.reminderTime = undefined

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    })
  }

  lockWalkinDetails = (start) => {
    const {booking, appointmentId, appointment} = this.subject.value

    const iso = start.toISOString()
    const selectedSlot = parse(iso, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSX', new Date())

    let newBooking = {...booking}
    newBooking.selectedSlot = selectedSlot
    newBooking.reminderTime = 60
    newBooking.locked = false
    newBooking.selectedOrg = appointment.provider

    this.subject.next({
      ...this.subject.value,
      booking: newBooking,
    })

    const request = this.__createAppointmentWalkinRequestData()
    request.command = 'update_details'

    return appointmentApi.command(appointmentId, request)
  }

  lockedWalkinDetails = (error) => {
    const {booking} = this.subject.value

    let newBooking = {...booking}
    newBooking.locked = true
    newBooking.error = error

    this.__updateSubject({booking: newBooking})
  }

  confirmWalkin = () => {
    const {booking, loadingData, appointmentId} = this.subject.value

    // if (loadingData || !booking.locked) {
    //   return new Promise((resolve) => resolve('stop'))
    // }

    // if (!booking.selectedSlot || booking.error) {
    //   this.events.next({
    //     type: BookingBlocEvent.NAVIGATE_TO,
    //     data: {url: THANKYOU_ROUTE},
    //   })
    //   return new Promise((resolve) => resolve('stop'))
    // }

    // sessionStorage.setItem('appt', appointmentId)

    // this.__updateSubject({
    //   loadingData: true,
    // })

    return  appointmentApi.getAppointmentQueueStatus(appointmentId)
      .then((res) => {
        const {waitTime} = res.data
        // return waitTime > 60
        //   ? routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(appointmentId, 'IN_PERSON')
        //   : routeUtil.buildBookingIdentityDocument(appointmentId)
        return routeUtil.buildBookingIdentityDocument(appointmentId)
      })
      .then((destination) => {
        this.events.next({
          type: BookingBlocEvent.NAVIGATE_TO,
          data: {url: destination},
        })
        return new Promise((resolve) => resolve('stop'))
      })
  }

  estimateQueueStats = () => {
    const {availableOrganisations, booking, appointment} = this.subject.value

    const target = availableOrganisations.filter((org) => org.id === appointment.provider)

    if (target.length > 0) {
      const clinic = target[0]
      setSelectedDate(new Date(), this)
      this.loadSelectedDayAvailability().finally(() => {
        this.events.next({type: BookingBlocEvent.WALKIN_DETAILS_LOADED, data: {}})
      })
    } else {
      notificationService.error(
        'An error occurred trying to lookup the clinics information. Please try refresh or contact the clinic directly.',
      )
    }
  }

  makeReservationAvailable = () => {
    this.__updateSubject({ reservationAvailable: true });
  };

}


