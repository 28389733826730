import { Box, Button, Grid, styled } from '@mui/material';
import ChatBubble from '../shared/ChatBubble';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQueries } from '../../utils/hooks/useMediaQueries'

export const ScrollableBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
  height: '100%',
  overflowY: 'auto',
  paddingRight: '12px',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '0 ',
  },
}));

export const QuinnContent = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  padding: '0 0 0 10em',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    padding: '0 3em 0 2em',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '-1.5em',
    maxWidth: '100%',
    padding: '0 2em 0 2em',
  },
}));

export const PagePanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
  height: '100%',
  maxWidth: '700px',
  padding: '0 0 24px 10em',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    padding: '0 2em 32px 2em',
  },
}));
export const LargePagePanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
  height: '100%',
  maxWidth: '850px',
  padding: '0 0 24px 10em',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    padding: '0 2em 32px 2em',
  },
}));

export const PageContent = styled(Box)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    paddingLeft: '12px',
  },
}));

export const PageFooter = styled(Grid, { container: true, spacing: 2 })(({ theme }) => ({
  paddingTop: '2em',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    paddingLeft: '12px',
  },
}));

export const DisconnectedPageFooter = styled(Grid, { container: true, spacing: 0 })(
  ({ theme }) => ({
    paddingTop: '2em',
    maxWidth: '700px',
    minWidth: '700px',
    padding: '0 0 24px 10em',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      minWidth: '100%',
      padding: '0 1em 2em 1em',
      flexDirection: 'initial',
    },

  }),
);

export const DefaultPageFooter = ({ children }) => (
  <PageFooter
    container
    justifyContent="center"
    alignItems="center"
    spacing={2}
    sx={(theme) => ({
      paddingTop: '2em',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    })}
  >
    {children}
  </PageFooter>
);

export const DefaultDisconnectedPageFooter = ({ children, alignItems, justifyContent }) => {
  const mediaQuery = useMediaQueries()
  return (
  <DisconnectedPageFooter
    container
    justifyContent={justifyContent || 'center'}
    alignItems={alignItems || 'center'}
    spacing={mediaQuery.isSm ? 0 : 2}
    gap={mediaQuery.isSm ? 2 : 0}
  >
    {children}
  </DisconnectedPageFooter>
  )
};

export const ScrollableWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
});

const ScrollableContainer = ({ children, applyFormat }) => (
  <ScrollableWrapper>
    <ScrollableBox>
      {applyFormat ? <PagePanel>{children}</PagePanel> : <>{children}</>}
    </ScrollableBox>
  </ScrollableWrapper>
);

export const ScrollableQuinnContainer = ({ size, messageId, message, children, footer }) => (
  <ScrollableWrapper>
    <QuinnContent>
      <ChatBubble messageId={messageId} message={message} />
    </QuinnContent>
    <ScrollableBox>
      <PagePanel sx={{ maxWidth: size === 'large' ? '950px' : '850px' }}>{children}</PagePanel>
    </ScrollableBox>
    {footer && (
      <Grid item sx={{ paddingTop: '24px' }}>
        {footer}
      </Grid>
    )}
  </ScrollableWrapper>
);

export default ScrollableContainer;
