import React, {useEffect, useState} from 'react';
import {Card, CardContent, TextField, Button, Typography, MenuItem, Grid, Box} from '@mui/material';
import DecodedButton from "../../common/DecodedButton";
import {useHistory} from "react-router-dom";
import {routeUtil} from "../../../utils/route.name";
import {globalBloc} from "../../global.bloc";

const MockStripe = () => {
    const [cardDetails, setCardDetails] = useState({
        cardNumber: '4242-4242-4242-4242',
        expiryDate: '10/25',
        cvv: '123',
        country: 'US',
        zipCode: '54321'
    });
    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState('primary');
    const [icon, setIcon] = useState('Pay Now');

    const history = useHistory();

    const {appointmentId} = globalBloc.subject.value;

    const handleChange = (prop) => (event) => {
        setCardDetails({...cardDetails, [prop]: event.target.value});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setColor('success')
                setIcon('✓ SUCCESS')
                setLoading(false);
                setTimeout(() => {
                    history.push(
                        routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
                            appointmentId,
                            'IN_PERSON',
                        ),
                    )
                }, 1000);
            }, 1500);
        }
    }, [loading]);

    const countries = [
        {code: 'US', label: 'United States'},
        {code: 'CA', label: 'Canada'},
        {code: 'GB', label: 'United Kingdom'},
    ];

    return (
        <Card raised sx={{maxWidth: 480, mx: "auto", mt: 5}}>
            <CardContent>
                <Typography variant="h5" component="div" sx={{mb: 2}}>
                    Enter Payment Details
                </Typography>
                <TextField
                    disabled
                    label="Card Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={cardDetails.cardNumber}
                    onChange={handleChange('cardNumber')}
                    inputProps={{maxLength: 16}}
                    sx={{mb: 2}}
                />
                <Grid sx={{mb: 2}} container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            disabled
                            label="Expiry Date"
                            variant="outlined"
                            fullWidth
                            value={cardDetails.expiryDate}
                            onChange={handleChange('expiryDate')}
                            placeholder="MM/YY"
                            inputProps={{maxLength: 5}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            disabled
                            label="CVV"
                            variant="outlined"
                            fullWidth
                            value={cardDetails.cvv}
                            onChange={handleChange('cvv')}
                            inputProps={{maxLength: 4}}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            select
                            label="Country"
                            value={cardDetails.country}
                            onChange={handleChange('country')}
                            fullWidth
                            variant="outlined"
                        >
                            {countries.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            label="ZIP/Postal Code"
                            variant="outlined"
                            fullWidth
                            value={cardDetails.zipCode}
                            onChange={handleChange('zipCode')}
                        />
                    </Grid>
                </Grid>
                <Box sx={{display: 'flex', justifyContent: 'center', mt: 3}}>
                    <DecodedButton
                        loading={loading}
                        type="submit"
                        color={color}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {icon}
                    </DecodedButton>
                </Box>
            </CardContent>
        </Card>
    );
};
export default MockStripe;
