import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Redirect, withRouter } from 'react-router-dom';

import { authService } from '../../utils/auth';

import Loading from '../shared/Loading';
import { linksApi } from '../../utils/services/links.api';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';

import {
  LOGIN_ROUTE,
  TELEHEALTH_LINK_VIRTUAL_CLINIC_WAITING_ROOM_ROUTE,
} from '../../utils/route.name';
import { uriStorage } from '../../utils/storage';
import { ErrorMessage } from '../../utils/error.resolver';

const styles = (theme) => ({
  root: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
    backgroundColor: '#313941',
    position: 'absolute',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    color: '#fff',
  },
});

class RegistrationOnlyLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this._initialise();
  }

  _initialise = () => {
    analyticsEventLogger.log(AnalyticsEvent.LINK_OPEN, { type: 'registration' });
  };

  componentDidMount() {
    const { history } = this.props;

    setTimeout(() => {
      sessionStorage.setItem('action', 'registration.only');
      history.replace('/');
    }, 5000);
  }

  componentWillUnmount() {}

  render() {
    let { classes } = this.props;

    let { loading } = this.state;

    return (
      <div className={classes.root}>
        <Loading message={'Loading registration'} />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(RegistrationOnlyLink));
