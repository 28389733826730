import { Grid } from '@mui/material';
import * as React from 'react';
import { deepGet, useWizardContext } from '../../../shared-library';
import PageContainer from '../../common/PageContainer';
import {
  DisconnectedPageFooter,
  PageContent,
  PagePanel,
  QuinnContent,
  ScrollableBox,
  ScrollableWrapper,
} from '../../common/ScrollableContainer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Typography,
} from '@mui/material';
import ChatBubble from '../../shared/ChatBubble';
import { BookingWizardProvider } from './booking-wizard-context';
import { notificationService } from '../../../utils/notification';
import DecodedButton from '../../common/DecodedButton';
import { logger } from '../../../utils/logging';
import withStyles from '@mui/styles/withStyles';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { useHistory } from 'react-router-dom';
import { authService } from '../../../utils/auth';
import { BOOKING_LIST_ROUTE } from '../../../utils/route.name';
import { useCallback, useEffect, useState } from 'react';
import { globalBloc } from '../../global.bloc';

const styles = (theme) => ({
  progress: {
    color: theme.palette.secondary.main,
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'right',
  },
  dialogButton: {
    maxWidth: '150px',
    margin: '1em',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
  },
});

const BookingWizard = (props) => {

  const {
    onBack,
    bloc,
    isGoBack,
    setGoBack,
    handleTimeSelect,
    onSubmit
  } = props;
  const { isFirstStep, activeStep, goBack, goNext, goBackOneStep } = useWizardContext();

  const history = useHistory();

  const [openBackDialog, setOpenBackDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { orgSelected } = globalBloc.subject.value;

  useEffect(() => {
    if (isGoBack) {
      goBackOneStep();
      setGoBack(false);
    }
  }, [isGoBack]);

  const handleBack = useCallback(() => {
    if (isFirstStep) {
      onBack?.();
      return;
    }
    globalBloc.updateGlobalBloc({ orgSelected: false });
    goBack();
  }, [isFirstStep, goBack, onBack]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (activeStep.onSubmit) {
      try {
        const result = await activeStep.onSubmit();
        goNext();

        if (orgSelected) {
          handleTimeSelect();
        }

        if (result === 'stop') {
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
      } catch (e) {
        notificationService.error(
          'An error occurred trying to book your reservation. Please call the clinic.',
        );
        setIsLoading(false);
        goBack();
      }
    } else  {
      onSubmit();
        setIsLoading(false);

    }
  };

  const buttonLabelSubmit = () => {
    if (activeStep.submitLabel) {
      if (typeof activeStep.submitLabel == 'function') {
        return activeStep.submitLabel();
      } else {
        return activeStep.submitLabel ?? 'Select';
      }
    } else {
      return 'Select';
    }
  };

  const currentStepValue = deepGet(bloc, activeStep.dataKey, undefined);


  const handleClickOpen = () => {
    setOpenBackDialog(true);
  };

  const handleClose = () => {
    setOpenBackDialog(false);
  };

  const handleRedirect = () => {
    if (authService.getUser()) {
      logger.info('***redirected from handleRedirect in BookingWizard***');
      history.push(BOOKING_LIST_ROUTE);
    } else {
      history.push('/');
    }
  };

  const OnBackDialog = () => {
    return (
      <Dialog
        maxWidth={'md'}
        open={openBackDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' className={styles.dialogTitle}>
          <Typography variant='h4' component={'p'}>
            <FormattedMarkdown
              id={'dialog.cancel.title'}
              defaultMessage={'Leaving Booking Process'}
            />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='body2' component={'p'}>
              <FormattedMarkdown
                id={'dialog.cancel.content'}
                defaultMessage={
                  'You are about to leave the booking process and lose any reservation being made.'
                }
              />
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type='button'
            variant={'contained'}
            color='secondary'
            className={styles.dialogButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant={'contained'}
            color='primary'
            className={styles.dialogButton}
            onClick={handleRedirect}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <BookingWizardProvider
      value={{
        ...bloc,
      }}
    >
      <PageContainer
        loading={isLoading || bloc.loadingData}
        onBack={activeStep.id !== 'org-selector' ? handleBack : handleClickOpen}
      >
        <Grid container direction='column' justifyContent='space-between' flex='1'>
          <ScrollableWrapper>
            {activeStep.message && (
              <QuinnContent>
                <ChatBubble
                  messageId={
                   typeof activeStep.message.id === 'string' ||
                      activeStep.message.id instanceof String
                        ? activeStep.message.id
                        : activeStep.message.id()
                  }
                  message={
                    typeof activeStep.message.text === 'string' ||
                    activeStep.message.text instanceof String
                      ? activeStep.message.text
                      : activeStep.message.text()
                  }
                />
              </QuinnContent>
            )}
            <ScrollableBox>
              {activeStep.message === undefined || activeStep.message.id === undefined ? (
                <>{activeStep.component}</>
              ) : (
                <PagePanel>
                  <PageContent>{activeStep.component}</PageContent>
                </PagePanel>
              )}
            </ScrollableBox>
          </ScrollableWrapper>
          <DisconnectedPageFooter>
            <DecodedButton
              loading={isLoading || bloc.loadingData}
              onClick={handleSubmit}
              disabled={activeStep.dataKey ? !currentStepValue : false}
            >
              {buttonLabelSubmit()}
            </DecodedButton>
          </DisconnectedPageFooter>
        </Grid>
      </PageContainer>
      <OnBackDialog />;
    </BookingWizardProvider>
  );
};
export default withStyles(styles)(BookingWizard);
