import {createTheme} from '@mui/material/styles'

let defaultTheme = createTheme({
  palette: {
    primary: {
      light: '#B1F1FF',
      main: '#00D1FF',
    },
    secondary: {
      main: '#E50B14',
    },
    error: {
      main: '#ff1744',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    action: {
      hover: '#00D1FF',
    },
    text: {
      primary: '#074D75',
      secondary: '#555',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14, // Default font size
  },
})

export default defaultTheme
