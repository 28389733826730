import React, { useEffect, useMemo, useState } from 'react';
import {BookingBloc} from './bloc'
import {BookingBlocEvent} from './events.bloc'
import {BookingType} from '../utils'

const determineAppointmentType = (appointment, overrides) => {
  return BookingType.RESERVATION;
};


// useBookingBloc is a hook that returns the booking bloc appointment type and state

const useBookingBloc = (appointmentId, type, history) => {
  const [state, setState] = useState({});
  const [appointmentType, setAppointmentType] = useState(null);
  const bookingBloc = useMemo(() => new BookingBloc(appointmentId, type), [appointmentId, type]);

  const subscribeToState = React.useCallback((newState) => {
    setState((state) => ({ ...state, ...newState }));
  }, []);

  const subscribeToEvents = React.useCallback((event) => {
    const { data } = event;
    switch (event.type) {
      case BookingBlocEvent.INITIALISED:
        setAppointmentType(determineAppointmentType(data.appointment, data.overrides));
        break;
      case BookingBlocEvent.SWITCH_BOOKING_TYPE:
        setAppointmentType(determineAppointmentType(data.appointment, data.overrides));
        break;
      case BookingBlocEvent.NAVIGATE_TO:
        history.replace(data.url);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const stateSub = bookingBloc.subscribeToState(subscribeToState);
    const eventSub = bookingBloc.subscribeToEvents(subscribeToEvents);
    return () => {
      stateSub.unsubscribe();
      eventSub.unsubscribe();
    };
  }, [bookingBloc, subscribeToState, subscribeToEvents]);

  return {
    ...state,
    appointmentType,
    bookingBloc,
  };
};

export default useBookingBloc;
