import * as rxjs from 'rxjs';
import { notificationService } from '../../../utils/notification';
import { userInfoUtil } from '../../../utils/user';
import { insuranceStorage } from '../../../utils/insurance.storage';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { globalBloc } from '../../global.bloc';

const initial = {
  initialised: false,
  insuranceCardData: {},
};

export class Bloc {
  callback;

  codesetApi;
  insuranceApi;
  paymentApi;

  constructor(props, callback) {
    this.codesetApi = props.codesetApi;
    this.insuranceApi = props.insuranceApi;
    this.paymentApi = props.paymentApi;

    this.subject = new rxjs.BehaviorSubject({
      ...initial,
      ...props,
    });

    this.events = new rxjs.Subject();

    this.callback = callback;
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  __publishEvent = (type, data) =>
    this.events.next({
      type: type,
      data: data,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    Promise.all([
      this.codesetApi.getPolicyHolderRelationshipCodesets(),
      this.paymentApi.getPaymentMethods().catch((reason) => {
        return { data: { items: [], page: { size: 0 } } };
      }),
    ])
      .then(
        (result) => {
          console.log('result', result);
          /* Payment methods */
          const paymentMethodResult = result[1];

          let insurances = [];
          let deletedInsurance = [];

          if (paymentMethodResult.data.page.size > 0) {
            insurances = paymentMethodResult.data.items
              .filter((item) => item.type.code === '1' || item.type.code === 'insurance')
              .sort((a, b) => {
                if (a.primary === 1 && b.primary === 0) {
                  return -1;
                } else if (a.primary === 0 && b.primary === 1) {
                  return 1;
                }

                if (a.order > b.order) {
                  return 1;
                }

                if (a.order < b.order) {
                  return -1;
                }

                return 0;
              });
          }

          this.__updateSubject({
            relationshipCodes: result[0].data.items,
            insurances: insurances,
            deletedInsurance: deletedInsurance,
          });

          globalBloc.updateGlobalBloc({ insurances: insurances });

          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_PAYMENT_METHOD_LOADED, {});
        },
        (reason) => {
          this.__updateSubject({ error: true });
          notificationService.httpError(reason);
          analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_PAYMENT_METHOD_LOAD_ERROR, {
            error: reason,
          });
        },
      )
      .finally(() => {
        this.__updateSubject({ initialised: true });
      });
  };

  appendInsurance = (insurance) => {
    let { insurances } = this.subject.value;

    insurance.dirty = true;

    let entry = {
      primary: insurances.filter((_item) => _item.primary === 1).length === 0 ? 1 : 0,
      order: insurances.length,
      type: {
        system: 'decoded/payment_method',
        code: '1',
      },
      insuranceInformation: this.createInsuranceEntry(insurance),
      attachments: insurance.attachments,
      dirty: true,
    };

    insurances.push(entry);

    this.__updateSubject({ insurances: insurances });
  };

  createInsuranceEntry = (insurance) => {
    let { memberId, policyHolder, provider, planType, userPlan, customUserPlanName } = insurance;

    let members = [];
    if (policyHolder.relationship !== 'SELF') {
      members.push({
        name: {
          given: policyHolder.firstName,
          family: policyHolder.lastName,
        },
        role: 'POLICY_HOLDER',
        dob: userInfoUtil.formatDate(policyHolder.dateOfBirth),
        gender: policyHolder.gender,
        sex: policyHolder.gender,
      });
    }

    return {
      memberId: memberId,
      type: 'INSURANCE',
      provider: {
        name: provider,
      },
      plan: {
        id: userPlan.planId,
        name:
          !this._isPlanIdUnknown(userPlan.planId) || !customUserPlanName
            ? userPlan.planName
            : customUserPlanName,
        type: planType,
      },
      members: members,
      attachments:  [
            {
              reference: {
                value: '01c27b50-243d-4c5e-9fe4-f35e8ec82174',
              },
            },
          ],
      // attachments: !insuranceStorage.isValueSet()
      //   ? []
      //   : [
      //       {
      //         reference: {
      //           value: insuranceStorage.getInsuranceCardRef(),
      //         },
      //       },
      //     ],
    };
  };

  _isPlanIdUnknown = (planId) => {
    let parsePlanId = parseInt(planId);
    return parsePlanId === -1;
  };

  clearInsuranceAndSave = () => {
    this.removeInsurances();
    this.save();
    analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UPDATE_SUCCESS, { action: 'unknown' });
  };

  removeInsurances = () => {
    let { insurances } = this.subject.value;
    this.__updateSubject({ insurances: [], deletedInsurance: insurances });
  };

  removeInsurance = (insurance) => {
    let { insurances, deletedInsurance } = this.subject.value;

    deletedInsurance.push(insurance);
    insurances = insurances.filter((_item) => _item !== insurance);
    this.__updateSubject({ insurances: insurances, deletedInsurance: deletedInsurance });
  };

  save = () => {
    const { insurances, deletedInsurance, organisationId } = this.subject.value;

    this.__updateSubject({ loading: true });

    if (
      insurances.filter((insurance) => insurance.dirty).length > 0 ||
      deletedInsurance?.length > 0 ||
      insurances.length === 0
    ) {
      const updates =
        insurances.length > 0
          ? insurances
          : [
              {
                primary: 1,
                order: 0,
                type: {
                  system: 'decoded/payment_method',
                  code: '0',
                },
                attachments: [],
              },
            ];

      let request = {
        command: 'replace_insurance',
        organisationId: organisationId,
        paymentMethods: updates,
      };

      this.paymentApi
        .submitPaymentCommand(request)
        .then(
          (value) => {
            if (insurances.length > 0) {
              globalBloc.updateGlobalBloc({ insurances: insurances });
              this.__updateSubject({ confirm: true });
            } else {

              this.__updateSubject({ selfPay: true})

              // this.callback();
            }
            analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UPDATE_SUCCESS, {});
          },
          (reason) => {
            notificationService.httpError(reason);
            analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UPDATE_ERROR, {});
          },
        )
        .finally(() => this.__updateSubject({ loading: false }));
    } else {
      if (insurances.length > 0) {
        this.__updateSubject({ confirm: true });
        globalBloc.updateGlobalBloc({ insurances: insurances });
        analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UPDATE_SUCCESS, {});
      } else {
        this.callback();
        analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UPDATE_SUCCESS, {});
      }
      this.__updateSubject({ loading: false });
    }
  };

  closeAccept = () => {
    this.__updateSubject({ confirm: false });
  };
  accept = () => {
    this.callback();
  };

  close = () => {};
}

export class Constants {}

export class BlocEvent {}
