import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Box, Grid, Container } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { H1TextStepTitle, TextStepSecondary } from '../../../shared/Typography';
import { isValidName } from '../validationUtils';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import { LoginDataContext } from '../LoginDataProvider';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import { LandscapeNextButton, NextButton } from './NextButton';

const Name = (props) => {
  // const { loginData, setLoginData} = props;
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const mediaQuery = useMediaQueries();

  const { formatMessage } = useIntl();

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'firstName') {
      setError1(!isValidName(value));
    }
    if (name === 'lastName') {
      setError2(!isValidName(value));
    }
  };

  const handleClick = () => {
    setTimeout(() => {
      if (props.index !== 6) {
        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleClick();
    setLoginData((prevData) => ({ ...prevData, goBackStep: true }));
  };

  return (
    <form
      style={{
        height: 'inherit',
      }}
      onSubmit={onSubmit}
    >
      <Container
        maxWidth="md"
        sx={{
          height: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          marginLeft: '10%',
          '@media (max-width: 1024px)': {
            marginLeft: '5%',
          },
          '@media (max-width: 935px)': {
            marginLeft: '0',
          },
        }}
      >
        <Box
          component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'start',
          }}
        >
          <Box component={'div'}>
            <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
              <FormattedMarkdown
                id="registration.patient.name"
                defaultMessage={'Lets verify the patient account quickly.'}
              />
            </H1TextStepTitle>

            <TextStepSecondary isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
              <FormattedMarkdown
                id="registration.patient.name"
                defaultMessage={
                  'PLEASE PROVIDE THE PATIENT NAME AS IT APPEARS ON YOUR INSURANCE CARD OR GOVERNMENT ID'
                }
              />
            </TextStepSecondary>
          </Box>
          <Grid
            container
            spacing={ 2}
            sx={{
              width: '75%',
              '@media (max-width:600px)': {
                width: '100%',
              },
              display: mediaQuery.isMobileLandscape && 'flex',
              flexDirection: mediaQuery.isMobileLandscape && 'row',
              justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
              alignItems: mediaQuery.isMobileLandscape && 'flex-end',
              flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
            }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <DecodedTextField
                autoFocus
                name="firstName"
                label="Patients First Name*"
                variant="outlined"
                value={loginData.firstName}
                onChange={handleInputChange}
                fullWidth
                error={error1}
                helperText={
                  error1 &&
                  formatMessage({
                    id: 'registration.field.error.patient.name.family',
                    defaultMessage: 'Invalid name*',
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <DecodedTextField
                name="lastName"
                label="Patients Last Name*"
                variant="outlined"
                value={loginData.lastName}
                onChange={handleInputChange}
                fullWidth
                error={error2}
                helperText={
                  error2 &&
                  formatMessage({
                    id: 'registration.field.error.patient.name.family',
                    defaultMessage: 'Invalid name*',
                  })
                }
              />
            </Grid>

            {mediaQuery.isMobileLandscape && (
              <Grid item sm={3}>
                <LandscapeNextButton
                  disabled={
                    error1 || error2 || loginData.firstName === '' || loginData.lastName === ''
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>
        {!mediaQuery.isMobileLandscape && (
          <NextButton
            label={'Next'}
            disabled={error1 || error2 || loginData.firstName === '' || loginData.lastName === ''}
          />
        )}
      </Container>
    </form>
  );
};

export default Name;
