import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

export const LandscapeNextButton = (props) => {
  return (
    <>
      <Button
        {...props}
        type="submit"
        sx={{
          minHeight: '42px',
          borderRadius: '15px',
          background: 'linear-gradient(45deg, #00D1FF, #00BFE9)',
          color: 'white',
          '&': {
            color: 'white',
          },
          '&:hover': {
            color: 'white',
          },
          '&.Mui-disabled': {
            background: 'grey',
            color: 'rgba(255, 255, 255, 0.5)',
          },
        }}
        variant="contained"
      >
        <ArrowForwardIos />
      </Button>
    </>
  );
};

export const NextButton = (props) => {
  return (
    <>
      <Button
      {...props}
        type="submit"
        sx={{
          borderRadius: '15px',
          position: 'absolute',
          bottom: '24px',
          left: '50%',
          transform: 'translateX(-50%)',
          background: 'linear-gradient(275deg, #00D1FF, #00BFE9)',
          color: 'white',
            fontSize: '1.5rem',
          '&': {
            color: 'white',
          },
          '&:hover': {
            color: 'white',
          },
          '&.Mui-disabled': {
            background: 'grey',
            color: 'rgba(255, 255, 255, 0.5)',
          },
          '@media (min-width: 600px)': {
            bottom: '20%',
            left: '180px',
            transform: 'translateX(-50%)',
          },
        }}
      >
        {props.label} &nbsp;
      </Button>
    </>
  );
};
