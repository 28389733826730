import React, {useContext, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {FormattedMarkdown} from '@decodedhealth/react-library';
import {Box, Grid, Container} from '@mui/material';
import {H1TextStepTitle, SmallButtonText} from '../../../shared/Typography';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import InputMask from 'react-input-mask';
import {authService} from '../../../../utils/auth';
import {ROOT_ROUTE} from '../../../../utils/route.name';
import {AnalyticsEvent, analyticsEventLogger} from '../../../../utils/events';
import {errorResolver} from '../../../../utils/error.resolver';
import {notificationService} from '../../../../utils/notification';
import {LoginDataContext} from '../LoginDataProvider';
import {phoneUtil} from '../../../../utils/phone';
import {userInfoUtil} from '../../../../utils/user';
import {LandscapeNextButton, NextButton} from './NextButton';
import {useMediaQueries} from '../../../../utils/hooks/useMediaQueries';
import {TextButton} from '../../../shared/components/TextButton';
import Loading from '../../../shared/Loading';

const PinCode = (props) => {
    const {code, codeCountry} = props;

    const [state, setState] = useState({
        verificationSuccess: false,
        pin: '',
    });
    const [disablePin, setDisablePin] = useState(true);
    const [loading, setLoading] = useState(false);

    const {loginData, setLoginData} = useContext(LoginDataContext);

    const mediaQuery = useMediaQueries();

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setLoginData((prevState) => ({...prevState, [name]: value}));
        if (name === 'pin') {
            const processedPin = value.replace(/-/g, '');
            setState((prevState) => ({...prevState, pin: processedPin}));

            const isValidPin = /^[0-9]{6}$/.test(processedPin);
            setDisablePin(!isValidPin);
        }
    };
    const onSubmit = (e) => {
        e.preventDefault();
        doPinSubmit();
    };

    const createAuthRequest = (data) => {
        const processedPin = data.replace(/-/g, '');

        return {
            pin: processedPin,
            name: {
                given: loginData.firstName,
                family: loginData.lastName,
            },
            dob: userInfoUtil.formatDate(loginData.dateOfBirth),
            number: loginData.number
                ? phoneUtil.formatPhoneNumberForRegistration(
                    loginData.number,
                    props.code,
                    props.codeCountry,
                )
                : null,
            email: loginData.email,
        };
    };

    const doPinSubmit = (data) => {
        let {setLoadingStatus} = props;
        setLoading(true);

        authService
            .confirmVerificationCode(createAuthRequest(loginData.pin))
            .then((token) => {
                authService
                    .loginWithToken(token)
                    .then((user) => {
                        analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_SUCCESS);

                        setLoginData((prevData) => ({...prevData, verificationSuccess: true}));
  setLoading(false);
                        analyticsEventLogger.setUser(user.uid);
                    })
                    .catch((error) => {
                        analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
                            reason: `${error}`,
                        });
                        notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
                        setLoadingStatus(false);
                    });
            })
            .catch((error) => {
                analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
                    reason: `${error}`,
                });
                setLoadingStatus(false);
                notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
            })
            .finally(() => {
                sessionStorage.removeItem('currentPath');
            });
    };

    if(loading){
        return <Loading />
    }

    if (loginData.verificationSuccess) {
        return (
            <Redirect
                to={{
                    pathname: ROOT_ROUTE,
                    state: {
                        loginData,
                        code,
                        codeCountry,
                        addressCountry: codeCountry,
                    },
                }}
            />
        );
    }

    return (
        <form
            style={{
                height: 'inherit',
            }}
            onSubmit={onSubmit}
        >
            <Container
                maxWidth="md"
                sx={{
                    height: 'inherit',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    marginLeft: '10%',
                    '@media (max-width: 1024px)': {
                        marginLeft: '5%',
                    },
                    '@media (max-width: 935px)': {
                        marginLeft: '0',
                    },
                }}
            >
                <Box
                    component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'start',
                    }}
                    gap={!mediaQuery.isMobileLandscape && 5}
                >
                    <Box component={'div'}>
                        <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
                            <FormattedMarkdown
                                id="login.otp.pin"
                                defaultMessage={'Please provide the pin code texted to you through SMS'}
                            />
                        </H1TextStepTitle>
                    </Box>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            width: '75%',
                            '@media (max-width:600px)': {
                                width: '100%',
                            },
                            display: mediaQuery.isMobileLandscape && 'flex',
                            flexDirection: mediaQuery.isMobileLandscape && 'row',
                            justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
                            alignItems: mediaQuery.isMobileLandscape && 'flex-end',
                            flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
                        }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <InputMask mask="9-9-9-9-9-9" onChange={handleInputChange}>
                                {(inputProps) => (
                                    <DecodedTextField
                                        {...inputProps}
                                        type='tel'
                                        name="pin"
                                        label="Pin Code*"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="0-0-0-0-0-0"
                                        pinCodeFont={true}
                                        error={state.pin.replace(/-/g, '').length < 6}
                                        InputProps={
                                            !mediaQuery.isMobileLandscape && {
                                                style: {
                                                    fontSize: '3.5rem',
                                                },
                                            }
                                        }
                                    />
                                )}
                            </InputMask>
                            <TextButton>
                                <SmallButtonText onClick={() => props.setIndex((prevIndex) => (prevIndex - 1) % 7)}>
                                    Resend pin code?
                                </SmallButtonText>
                            </TextButton>
                        </Grid>
                        {mediaQuery.isMobileLandscape && (
                            <Grid item sm={3}>
                                <LandscapeNextButton disabled={disablePin}/>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {!mediaQuery.isMobileLandscape && (
                    <NextButton label={'Next'} disabled={disablePin}/>
                )}
            </Container>
        </form>
    );
};

export default PinCode;
