import * as rxjs from 'rxjs';
import { appointmentApi } from '../../utils/services/appointments.api';
import { notificationService } from '../../utils/notification';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import React from 'react';
import { insurancesApi } from '../../utils/services/insurances.api';
import { paymentApi } from '../../utils/services/payment.api';
import { registrationApi } from '../../utils/services/register.api';
import { Bloc as InsuranceBloc } from './Insurance/bloc';
import { Bloc as NoticeBloc } from './Notice/bloc';
import Insurance from './Insurance';
import { routeUtil } from '../../utils/route.name';
import { Bloc as EmployerBloc } from './Employer/bloc';
import Employer from './Employer';
import Notice from './Notice';
import { AssistantBlocEvent } from '../Secure/Assistant/assistant.bloc';
import { providerStorage } from '../../utils/provider.qs';
import {logger} from "../../utils/logging";

export class Bloc {
  constructor(appointmentId, appointmentType) {
    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      loading: true,
      loadingData: true,
      appointmentId: appointmentId,
      appointmentType: appointmentType,
    });

    this.events = new rxjs.Subject();

    this.__initialise(appointmentId);
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  __initialise = (appointmentId) => {
    appointmentApi.getAppointmentStatus(appointmentId).then(
      (value) => {
        const appointment = value.data;

        const service = sessionStorage.getItem('service' + appointment.id) || '';

        this.__updateSubject({
          appointment: appointment,
          isVip: service === 'vip',
          loading: false,
          loadingData: false,
        });

        this.__initialisePaymentMethod(appointment);

        this.events.next({
          type: BlocEvent.INITIALISED,
          data: {
            appointment: appointment,
          },
        });
      },
      (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR, {});
        notificationService.error(
          'Unable to load your reservation status. Please try refreshing your browser and if the problem persists call the clinic.',
        );
      },
    );
  };

  __initialisePaymentMethod = (appointment) => {
    if (appointment.service.includes('-OCC')) {
      const employerBloc = new EmployerBloc({
        appointmentApi: appointmentApi,
        appointment: appointment,
        onSwitch: this.__onSwitchToUrgentcare,
      });
      employerBloc.initialise();

      this.__updateSubject({
        component: <Employer bloc={employerBloc} />,
      });
    } else if (appointment.service.includes('MEB-UC') || appointment.service.includes('-VV')) {
      const bloc = new NoticeBloc(
        {
          insuranceApi: insurancesApi,
          paymentApi: paymentApi,
          codesetApi: registrationApi,
        },
        this.__onInsuranceUpdateSuccess,
      );
      bloc.initialise();

      this.__updateSubject({
        initialising: false,
        component: <Notice bloc={bloc} />,
      });
    } else {
      const bloc = new InsuranceBloc(
        {
          insuranceApi: insurancesApi,
          paymentApi: paymentApi,
          codesetApi: registrationApi,
          isWalkin: providerStorage.isWalkin(),
          organisationId: appointment.provider,
        },
        this.__onInsuranceUpdateSuccess,
      );
      bloc.initialise();

      this.__updateSubject({
        initialising: false,
        component: <Insurance bloc={bloc} />,
      });
    }
  };

  __onSwitchToUrgentcare = () => {
    const { appointment } = this.subject.value;
    this.__initialisePaymentMethod(appointment);
  };

  __onInsuranceUpdateSuccess = () => {
    const { appointmentId, isVip } = this.subject.value;
    logger.info('isVip?', isVip, appointmentId);

    if (isVip) {
      this.events.next({
        type: AssistantBlocEvent.NAVIGATE_TO,
        url: routeUtil.buildRoutingVip(appointmentId, 'vip'),
      });
      return;
    }

    this.events.next({
      type: BlocEvent.NAVIGATE_TO,
      url: routeUtil.buildAppointmentCheckinConfirmation(appointmentId),
    });
  };

  __onNoticeSuccess = () => {
    const { appointmentId, appointmentType } = this.subject.value;

    this.events.next({
      type: BlocEvent.NAVIGATE_TO,
      url: routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
        appointmentId,
        appointmentType,
      ),
    });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
