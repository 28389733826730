import * as rxjs from 'rxjs';

export class DecodedBloc {
  analyticsLogger;

  constructor(options) {
    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      ...options,
    });

    this.analyticsLogger = options.analyticsLogger;

    this.events = new rxjs.Subject();
  }

  __updateSubject = (value) => {
    const newState = {
      ...this.subject.value,
      ...value,
    };
    this.subject.next(newState);
  };

  __publishEvent = (type, data) =>
    this.events.next({
      type: type,
      data: data,
    });

  __makeInitialised = (data) => {
    if (data) {
      this.__updateSubject({ ...data, initialising: false});
    } else {
      this.__updateSubject({ initialising: false });
    }
    this.__publishEvent(BlocEvent.INITIALISED, data || {});
  };

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  logAnalyticsEvent(event, data) {
    if (this.analyticsLogger) {
      this.analyticsLogger.log(event, data);
    }
  }
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
}
