import firebase from 'firebase/app';
import 'firebase/auth';

import { notificationService } from './notification';
import { userApi } from './services/user.api';
import axios from 'axios';
import { VERSION } from './version';
import { useEffect, useState } from 'react';
import { tenantUtil } from './tenant';
import { logger } from './logging';
import {globalBloc} from '../components/global.bloc'

class AuthService {
  constructor() {
    this.confirmationResult = null;

    this.loading = true;

    this.registerLoadedCallback.bind(this);
  }

  initialise = (auth) => {
    return new Promise((resolve, reject) => {
      this.auth = auth;
      this.auth
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => this._initialise())
        .then(() => resolve())
        .catch((error) => {
          notificationService.error(
            "Error setting up your session. Please contact support and reference '" +
            error.code +
            ' - ' +
            error.message +
            "'",
          );
          reject(error);
        });
    });
  };

  _initialise = () => {
    console.log('auth initialising ran')
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.googleProvider.addScope('profile');
    this.googleProvider.addScope('email');

    firebase.auth().onAuthStateChanged((user) => {
      this.loading = false;
      globalBloc.updateGlobalBloc({loading: false})

      axios.interceptors.request.use(
        async (config) => {
          const token = await authService.getToken();
          config.headers.Authorization = `Bearer ${token}`;
          config.headers['X-DH-source'] = `Consumer Webapp`;
          config.headers['X-DH-version'] = VERSION;
          config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

          return config;
        },
        (error) => {
          return Promise.reject(error);
        },
      );

      // if (this.callback) {
      //   logger.info('callback?', this.callback)
      //   this.callback();
      // }
    });
  };

  registerLoadedCallback = (callback) => {
    this.callback = callback;
  };

  isLoading = () => this.loading;

  loginWithToken = (token) => {
    this.loading = true;

    return this.auth
      .signInWithCustomToken(token)
      .then((value) => {
        return value.user;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  loginWithPhoneNumber = (phoneNumber) => {
    this.loading = true;

    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback: function (response) {
        logger.info('SIGN IN CALLBACK');
      },
      'error-callback': function (error) {
        logger.info('ERROR');
        logger.info(error);
      },
      'expired-callback': function () {
        logger.info('EXPIRED');
      },
    });

    return this.auth
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then((confirmationResult) => {
        this.confirmationResult = confirmationResult;
        return true;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  loginWithEmail = (identities) => {
    this.loading = true;

    return userApi
      .requestOneTimePin(identities)
      .then((result) => {
        return true;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  confirmVerificationCode = (code) => {
    this.loading = true;

    return userApi
      .validateOneTimePin(code)
      .then((result) => {
        return result.data.token;
      })
      .finally(() => {
        this.loading = false;
      });
  };

  logout = () => this.auth.signOut();

  getUser = () => this.auth.currentUser;

  isLoggedIn = () => this.auth.currentUser !== null;

  getToken = () => {
    if (this.auth.currentUser) {
      return this.auth.currentUser.getIdToken().then((token) => {
        return token;
      });
    } else {
      return tenantUtil.tenantToken();
    }
  };

  getIdTokenResult = () => {
    return this.auth.currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        if (!idTokenResult) {
          window.location = '/';
        }

        return idTokenResult;
      })
      .catch((reason) => (window.location = '/'));
  };
}

class MockAuthService {
  constructor() {
    this.loading = false;

    this.registerLoadedCallback.bind(this);
  }

  initialise = (auth) => {
    this._initialise();
  };

  _initialise = () => {
    axios.interceptors.request.use(
      async (config) => {
        const token = authService.getToken();
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  };

  registerLoadedCallback = (callback) => {
    this.callback = callback;
  };

  isLoading = () => this.loading;

  logout = () => {
    return new Promise((resolve, reject) => resolve());
  };

  getUser = () => `${process.env.REACT_APP_MODE_USER}`;

  getToken = () => {
    return `${process.env.REACT_APP_MODE_TOKEN}`;
  };

  getIdTokenResult = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          token: `${process.env.REACT_APP_MODE_ID_TOKEN}`,
          claims: { scope: ['*'] },
        });
      }, 500);
    });
  };
}

export const authService =
  `${process.env.REACT_APP_MODE}` !== 'dev' ? new AuthService() : new MockAuthService();

export const useAuthServiceAvailable = () => {
  const [isAvailable, setIsAvailable] = useState(authService.isLoading() === false);

  useEffect(() => {
    if (!isAvailable) {
      authService.registerLoadedCallback(() => setIsAvailable(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAvailable;
};
