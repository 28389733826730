import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { authService } from '../../../utils/auth';
import { notificationService } from '../../../utils/notification';
import { Redirect } from 'react-router-dom';
import { Grid } from '@mui/material';
import { ROOT_ROUTE } from '../../../utils/route.name';
import { PinCodeMask } from '../../shared/InputTextMask';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { errorResolver } from '../../../utils/error.resolver';
import { userInfoUtil } from '../../../utils/user';
import { phoneUtil } from '../../../utils/phone';
import { FormattedMessage } from 'react-intl';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { buttonGroupStyle } from '../../shared/CommonStyle';
import PageContainer from '../../common/PageContainer';
import { ScrollableQuinnContainer } from '../../common/ScrollableContainer';
import { H2TextTitleContent } from '../../shared/Typography';
import { DecodedSecondaryButton, DecodedSubmitButton } from '../../common/DecodedButton';
import { logger } from '../../../utils/logging';

const styles = (theme) => ({
  ...buttonGroupStyle(theme),
  validation: {
    flex: '1',
    width: '100%',
    paddingTop: '4em',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2em',
    },
  },
  note: {
    width: '100%',
    lineHeight: '1.75',
    color: '#575757',
    paddingTop: '0.5em',
    textAlign: 'left',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
    justifyContent: 'space-between',
    paddingTop: '1.5em',
  },
  formInput: {
    paddingBottom: '2em',
  },
  textInput: {
    fontSize: '2.6em',
  },
  buttonGroup: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    flex: '1 1 1',
    padding: '1em',
    textAlign: 'right',
  },
  buttonGridItem: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: '0',
      textAlign: 'center',
    },
  },
});

const initState = {
  verificationSuccess: false,
  pin: '',
};

class PinCode extends Component {
  constructor(props) {
    super(props);

    this.state = initState;
  }

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  doSubmit = () => {
    let { setLoadingStatus } = this.props;

    setLoadingStatus(true);

    authService
      .confirmVerificationCode(this._createAuthRequest())
      .then((token) => {
        authService
          .loginWithToken(token)
          .then((user) => {
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_SUCCESS);

            this.setState({
              verificationSuccess: true,
            });

            analyticsEventLogger.setUser(user.uid);
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
              reason: `${error}`,
            });
            notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
            setLoadingStatus(false);
          });
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
          reason: `${error}`,
        });
        setLoadingStatus(false);
        notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
      })
      .finally(() => {
        sessionStorage.removeItem('currentPath');
      });
  };

  _createAuthRequest = () => {
    let { email, firstName, lastName, dateOfBirth, number, code, codeCountry } = this.props;
    return {
      pin: this.state.pin,
      name: {
        given: firstName,
        family: lastName,
      },
      dob: userInfoUtil.formatDate(dateOfBirth),
      number: number ? phoneUtil.formatPhoneNumberForRegistration(number, code, codeCountry) : null,
      email: email,
    };
  };

  backToLogin = () => {
    let { handleBack } = this.props;
    handleBack();
  };

  isLoading = () => {
    return this.props.loading || authService.isLoading();
  };

  render() {
    let { pin, verificationSuccess } = this.state;
    let { classes, loading, firstName, lastName, dateOfBirth, email, number, code, codeCountry } =
      this.props;

    if (verificationSuccess) {
      logger.debug('PinCode -> render -> verificationSuccess');
      return (
        <Redirect
          to={{
            pathname: ROOT_ROUTE,
            state: {
              firstName,
              lastName,
              dateOfBirth,
              email,
              number,
              code,
              codeCountry,
              addressCountry: codeCountry,
            },
          }}
        />
      );
    }

    return (
      <PageContainer loading={loading} onBack={this.backToLogin}>
        <ScrollableQuinnContainer
          messageId={'login.pincode.quinn.bubble'}
          message={'You should have received a message with a 6-digit pin code.'}
          applyFormat={true}
        >
          <H2TextTitleContent>
            <FormattedMarkdown
              id="login.pincode.text"
              defaultMessage={
                'Please check your mobile phone or email, enter the pin code, and click on the "Verify" button.'
              }
            />
          </H2TextTitleContent>

          <ValidatorForm autoComplete="off" onSubmit={this.doSubmit} className={classes.validation}>
            <div className={classes.form}>
              <div className={classes.formInput}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextValidator
                      required
                      fullWidth
                      autoFocus
                      inputMode="numeric"
                      id="pin"
                      label={
                        <FormattedMessage
                          id="login.pin.field.label.pin"
                          defaultMessage={'Pin Code'}
                        />
                      }
                      name="pin"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                        inputMode: 'numeric',
                        style: { fontSize: '2em' },
                      }}
                      value={pin}
                      onChange={this.handleTextChange}
                      validators={['matchRegexp:^[0-9]{6}$']}
                      errorMessages={[
                        <FormattedMessage
                          id="login.pin.field.error.pin"
                          defaultMessage={'Invalid pin code format'}
                        />,
                      ]}
                      InputProps={{
                        inputComponent: PinCodeMask,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.note}>
                      <H2TextTitleContent>
                        <FormattedMarkdown
                          id="login.pin.note"
                          defaultMessage="If you do not receive a pin code, please try again. If you are still having difficulty please phone the clinic"
                        />
                      </H2TextTitleContent>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={2} className={classes.buttonGroup}>
                <Grid item xs={12} sm={6} className={classes.buttonGridItem}>
                  <DecodedSecondaryButton disabled={this.isLoading()} onClick={this.backToLogin}>
                    <FormattedMessage
                      id="login.pin.button.resend"
                      defaultMessage=" Resend pin code"
                    />
                  </DecodedSecondaryButton>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.buttonGridItem}>
                  <DecodedSubmitButton
                    id={'submit-pin-button'}
                    disabled={this.isLoading()}
                    loading={this.isLoading()}
                  >
                    <FormattedMessage id="login.pin.button.submit" defaultMessage="Verify" />
                  </DecodedSubmitButton>
                </Grid>
              </Grid>
            </div>
          </ValidatorForm>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(PinCode);
