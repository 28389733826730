import React from 'react';
import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { consumerApi } from '../../utils/services/consumers.api';

export class Bloc extends DecodedBloc {
  constructor(props) {
    const action = sessionStorage.getItem('action');
    let extra = {};
    const loginDetails = JSON.parse(sessionStorage.getItem('loginDetails'));
    if (loginDetails) extra.loginDetails = loginDetails;

    super({ ...props, ...extra, action: action });

    this.__initialise();
  }

  __initialise = () => {
    const { action } = this.subject.value;

    consumerApi
      .getPersonSummary()
      .then(
        (value) => {
          if (action === 'registration.only') {
            this.__updateSubject({ registrationException: true });
          }
        },
        (reason) => {},
      )
      .finally(() => {
        this.__makeInitialised({});
      });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
