import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {Redirect, Switch, withRouter} from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import {authService} from '../../utils/auth';
import {PrivateRoute} from '../../utils/protected.route';
import {secureEventService} from './service/secure.event.service';
import IdleTimer from 'react-idle-timer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from '@mui/material';
import VirtualClinic from './VirtualClinic';
import Account from './Account';
import {CheckSession, uriStorage} from '../../utils/storage';
import PaymentCheck from './PaymentCheck';
import PostBooking from './PostBooking';
import {
  ACCOUNT_PAYMENT_METHOD_ROUTE,
  BOOKING_STATUS_ROUTE,
  BOOKING_CHECKIN_ROUTE,
  BOOKING_CONFIRMATION_ROUTE,
  BOOKING_DETAILS_ROUTE,
  BOOKING_LIST_ROUTE,
  BOOKING_ROUTE,
  POST_BOOKING_ROUTE,
  QUINN_ROUTE,
  REGISTRATION_ROUTE,
  routeUtil,
  VIRTUAL_CLINIC_ROUTE,
  BOOKING_PAYMENT_METHODS_ROUTE,
  BOOKING_PAYMENT_ROUTE,
  APPOINTMENT_CHECKIN_CONFIRMATION,
  APPOINTMENT_CHECKIN_EXCEPTION,
  USER_INFORMATION_ROUTE,
  BOOKING_IDENTITY_DOCUMENT,
  OCCHEALTH_ROUTING_ROUTE,
  URGENTCARE_ROUTING_ROUTE,
  VIP_ROUTING_ROUTE,
  PATIENT_INFORMATION_ROUTE,
} from '../../utils/route.name';
import {providerStorage} from '../../utils/provider.qs';
import Registration from '../Registration';
import {consumerApi} from '../../utils/services/consumers.api';
import BookingList from './BookingList';
import {globalBloc} from '../global.bloc';
import AssistantChat from './Assistant';
import BookingDetails from './BookingDetail';
import {Booking} from '../Booking/Booking';
import BookingPaymentMethod from '../BookingPaymentMethod';
import Payment from '../Payment';
import CheckinConfirmation from '../CheckinConfirmation';
import BookingStatus from './BookingStatus';
import BookingCheckin from './BookingCheckin';
import CheckinException from '../CheckinException';
import UserUpdate from './UserUpdate';
import IdentityDocument from './IdentityDocument';
import OccHealthRouting from '../OccHealthRouting';
import UrgentCareRouting from '../UrgentCareRouting';
import VipRouting from '../VipRouting';
import {AnalyticsEvent, analyticsEventLogger} from '../../utils/events';
import PatientInformation from '../PatientInformation';
import {logger} from '../../utils/logging';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Loading from '../../shared-library/components/Loading';
import {addUserDataHeap} from '../../utils/heap/heapUtils';
import ExpiringSessionDialog from '../shared/Dialogs/ExpiringSessionDialog';

const styles = (theme) => ({
  root: {
    display: 'flex',
    maxHeight: '100%',
    height: '100%',
    width: '100%',
    overscrollBehavior: 'contain',
    touchAction: 'none',
  },
  drawerContent: {
    width: '100%',
  },
  logoutButton: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(3),
    backgroundColor: '#878686',
    '&:hover': {
      backgroundColor: '#9d9c9c',
    },
  },
  lowerLogoutButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: '#878686',
    '&:hover': {
      backgroundColor: '#9d9c9c',
    },
  },
});

const TIMEOUT = 60 * 15;
const KIOSK_TIMEOUT = 60 * 5;
const LEEWAY = 60;

class Secure extends React.Component {
  subscription;

  state = {
    loading: true,
    quinnChat: globalBloc.quinnChat(),
    countdownSeconds: LEEWAY,
    idle: false,
    open: false,
    viewportWidth: window.innerWidth,
  };

  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.countDownTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.onCountdown = this._countdown.bind(this);
    this.onCancelIdleTimeout = this._cancelIdleTimeout.bind(this);
    this.idleTimeout = providerStorage.isKiosk() ? KIOSK_TIMEOUT : TIMEOUT;
  }

  componentDidMount() {
    logger.debug('Secure has mounted.');

    let {history} = this.props;
    const {booking, appointmentId, isKiosk} = globalBloc.subject.value;

    window.addEventListener('resize', this.handleResize);

    this.subscription = secureEventService.registerStateCallback(this._handleEvent);

    uriStorage.setUpStorage();

    const currentPath = uriStorage.getCurrentPath();

    if (this.props.location.pathname?.startsWith('/callback')) {
      this.setState({loading: false});
    } else if (
      currentPath !== '' &&
      currentPath !== '/register' &&
      !uriStorage.isOnRightPath(this.props.match.url)
    ) {
      this.setState({loading: false});
      history.replace(uriStorage.getCurrentPath());
    } else {
      providerStorage.setCurrentProvider(this.props.location.search);

      consumerApi
        .getPersonSummary()
        .then((result) => {
          this.setState({loading: false});

          addUserDataHeap({
            id: result.data.id,
            dateOfBirth: result.data.dob,
            firstName: result.data.name.given,
          });

          if (result.data) {
              this.ifIsRegistered(appointmentId)
            // if (sessionStorage.getItem('action') === 'registration.only') {
            //   history.replace(routeUtil.buildRegistrationOnlyResult('exception'));
            // } else if (this.props.location.search.includes('preserve=true')) {
            //   this.setState({ loading: false });
            // } else {
            //   if (CheckSession('action', 'manage-appointments')) {
            //     history.replace(BOOKING_LIST_ROUTE);
            //   } else if (booking?.appointmentId) {
            //     //?--> if refreshed and user is booking appointment, send to BOOKING_ROUTE <--//
            //     this.props.history.push(
            //       routeUtil.buildBookingRouteWithDraftAppointmentID(
            //         booking?.appointmentId,
            //         'IN_PERSON',
            //       ),
            //     );
            //   } else {
            //     history.replace(USER_INFORMATION_ROUTE);
            //   }
            // }
          } else {
            this.ifIsNotRegistered(appointmentId)
            // if (sessionStorage.getItem('action') === 'registration.only') {
            //   history.replace(PATIENT_INFORMATION_ROUTE);
            // } else if (sessionStorage.getItem('checkin') === 'true') {
            //   history.replace(routeUtil.buildAppointmentCheckinException('unknown'));
            // } else {
            //   history.replace(REGISTRATION_ROUTE, this.props.location.state);
            // }
          }
        })
        .catch((error) => {
          logger.debug('Error getting person summary', error);
          this.setState({
            loading: false,
          });

          this.ifIsNotRegistered()
          // if (sessionStorage.getItem('action') === 'registration.only') {
          //   logger.debug('Redirecting to patient information')
          //   history.replace(PATIENT_INFORMATION_ROUTE);
          // }
          //   // else if (sessionStorage.getItem('checkin') === 'true') {
          //   //   logger.debug('Redirecting to appointment checkin exception')
          //   //
          //   //   history.replace(routeUtil.buildAppointmentCheckinException('unknown'));
          // // }
          // else {
          //   logger.debug('Redirecting to registration')
          //   history.replace(REGISTRATION_ROUTE, this.props.location.state);
          // }
        });
    }
  }

  ifIsRegistered = (appointmentId) => {
    logger.info('Secure -> ifIsRegistered', appointmentId);
    if (!appointmentId) {
      this.props.history.replace(BOOKING_LIST_ROUTE);
    } else {
      this.props.history.push(
        routeUtil.buildBookingRouteWithDraftAppointmentID(
          appointmentId,
          'IN_PERSON',
        ),
      );
    }
  }

  ifIsNotRegistered = () => {
    logger.info('Secure -> ifIsNotRegistered');

    if (sessionStorage.getItem('action') === 'registration.only') {
      this.props.history.replace(PATIENT_INFORMATION_ROUTE);
    }  else {
      this.props.history.replace(REGISTRATION_ROUTE, this.props.location.state);
    }
  }

  componentWillUnmount() {
    logger.debug('Secure has unmounted.');

    if (this.countDownTimer) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = undefined;
    }
    secureEventService.stopRemotePublishing();
    this.subscription.unsubscribe();
    window.removeEventListener('resize', this.handleResize);
  }

  _onAction(e) {
  }

  _onActive(e) {
  }

  _onIdle(e) {
    if (!this.countDownTimer) {
      this.countDownTimer = setInterval(this.onCountdown, 1000);
    }
    this.setState({
      idle: true,
    });
    analyticsEventLogger.log(AnalyticsEvent.IDLE_SHOW, {});
  }

  _countdown = () => {
    const {countdownSeconds} = this.state;

    if (countdownSeconds < 0) {
      this.doLogout();
    } else {
      this.setState({
        countdownSeconds: countdownSeconds - 1,
      });
    }
  };

  _cancelIdleTimeout = () => {
    this.idleTimer.reset();
    clearInterval(this.countDownTimer);
    this.countDownTimer = undefined;
    this.setState({
      idle: false,
      countdownSeconds: LEEWAY,
    });
  };

  _handleEvent = (event) => {
  };

  doLogout = () => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
      window.location = '/';
    });
  };

  handleResize = () => {
    this.setState({viewportWidth: window.innerWidth});
  };

  render() {
    const {classes} = this.props;

    const {idle, countdownSeconds, loading, logoutDialogOpen, viewportWidth} = this.state;

    // NOTE: this will run on every render
    if (sessionStorage.getItem('currentProvider')) {
      if (loading) {
        return <Loading/>;
      } else if (this.props.history.location.pathname === '/') {
        logger.info('Secure -> render -> /booking/list');
        return <Redirect to={BOOKING_LIST_ROUTE}/>;
      }
    }

    let routes = (
      <>
        <PrivateRoute path={URGENTCARE_ROUTING_ROUTE} component={UrgentCareRouting}/>
        <PrivateRoute path={VIP_ROUTING_ROUTE} component={VipRouting}/>
        <PrivateRoute path={REGISTRATION_ROUTE} component={Registration}/>
        <PrivateRoute path={PATIENT_INFORMATION_ROUTE} component={PatientInformation}/>
        <PrivateRoute path={VIRTUAL_CLINIC_ROUTE} component={VirtualClinic}/>
        <PrivateRoute path={QUINN_ROUTE} component={AssistantChat}/>
        <PrivateRoute path={USER_INFORMATION_ROUTE} component={UserUpdate}/>
        <PrivateRoute path={BOOKING_LIST_ROUTE} component={BookingList}/>
        <PrivateRoute path={BOOKING_PAYMENT_METHODS_ROUTE} component={BookingPaymentMethod}/>
        <PrivateRoute path={BOOKING_PAYMENT_ROUTE} component={Payment}/>
        <PrivateRoute path={BOOKING_STATUS_ROUTE} component={BookingStatus}/>
        <PrivateRoute path={BOOKING_CHECKIN_ROUTE} component={BookingCheckin}/>
        <PrivateRoute path={BOOKING_CONFIRMATION_ROUTE} component={PaymentCheck}/>
        <PrivateRoute path={BOOKING_ROUTE} component={Booking}/>
        <PrivateRoute path={BOOKING_DETAILS_ROUTE} component={BookingDetails}/>
        <PrivateRoute path={BOOKING_IDENTITY_DOCUMENT} component={IdentityDocument}/>
        <PrivateRoute path={ACCOUNT_PAYMENT_METHOD_ROUTE} component={Account}/>
        <PrivateRoute path={APPOINTMENT_CHECKIN_EXCEPTION} component={CheckinException}/>
      </>
    );

    return (
      <div className={classes.root}>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * this.idleTimeout}
        />
        <Switch>{routes}</Switch>
        <ExpiringSessionDialog
          idle={idle}
          countdownSeconds={countdownSeconds}
          onCancelIdleTimeout={this.onCancelIdleTimeout}
        />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Secure));
