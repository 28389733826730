import * as rxjs from 'rxjs'
import {systemApi} from '../utils/services/system.api'
import {notificationService} from '../utils/notification'
import {AnalyticsEvent, analyticsEventLogger} from '../utils/events'
import {logger} from '../utils/logging'
import firebase from 'firebase/app'
import 'firebase/auth'
import ReactDOM from 'react-dom/client'
import {authService} from '../utils/auth'
import {appointmentApi} from '../utils/services/appointments.api'
import {remoteSteps, walkinSteps} from './Booking/stepOrchestrator'
import {BookingBlocEvent} from './Booking/bloc/events.bloc'
import {LOGIN_ROUTE, routeUtil} from '../utils/route.name'
import {providerStorage} from "../utils/provider.qs";



// example of calling an event in another component
// globalBloc.events.next({type: GlobalBlocEvent.FETCH_USER})


export class GlobalBloc {
  constructor(props) {


    this.cache = {
      systemProperties: {
        data: null,
        lastFetched: null,
        ttl: 1000 * 60 * 10, // Cache TTL of 10 minutes
      },
    }


    const savedState = JSON.parse(sessionStorage.getItem('globalState'))

    this.initialState = {
      initialising: false,
      loading: true,
      systemProperties: [],
      booking: {
        selectedSlot: {
          display: '',
        },
        firstAvailableCapacity: '',
        firstAvailableDate: '',
        organisation: {},
        currentStep: 'booking_org', // 'booking_org' | 'booking_slot' | 'booking_confirm'
      },
      orgSelected: false,
      insurances: [],
      user: null,
      appointment: null,
      appointments: [],
      appointmentId: null,
      manageAppointments: false,
      showBanner: true,
      intakeComplete: false,
      isKiosk: providerStorage.isKiosk(),
      initialPushToBookingStatus: true,
    }

    this.subject = new rxjs.BehaviorSubject(savedState || this.initialState)
    this.events = new rxjs.Subject()

    this.events.subscribe(event => {
      switch (event.type) {
        case 'FETCH_USER':
          this.fetchUser()
          break
        case 'FETCH_AVAILABLE_ORGANISATIONS':
          this.fetchAvailableOrganisations()
          break
        case 'FETCH_APPOINTMENTS':
          this.fetchUserAppointments()
          break
        case 'FETCH_STATUS':
          this.fetchAppointmentStatus(event.payload)
          break
        default:
          console.log('Unhandled event', event)
      }
    })
  }

  subscribeToEvents = (func) => this.events.subscribe(func)

  subscribeToState = (func) => {
    return this.subject.subscribe((newState) => {
      sessionStorage.setItem('globalState', JSON.stringify(newState))
      func(newState)
    })
  }

  initialise = () => {
    logger.info('Initializer: Loading properties')
    return new Promise((resolve, reject) => {
      this.initialiseFirebaseAndAuth()
        .then(() => this.loadProperties())
        .then(() => {
          this.makeInitialised()
          logger.info('Initializer: Fetching user')
          this.events.next({type: GlobalBlocEvent.FETCH_USER})

          logger.info('Initializer: Fetching organisations')
          this.events.next({type: GlobalBlocEvent.FETCH_AVAILABLE_ORGANISATIONS})

          logger.info('Initializer: Fetching appointment status')
          this.events.next({type: GlobalBlocEvent.FETCH_STATUS})

          resolve()
        })
        .catch((error) => {
          console.error('Error during initialization:', error)
        })
    })
  }

  initialiseFirebaseAndAuth = () => {
    return new Promise((resolve, reject) => {
      let config = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      }

      firebase.initializeApp(config)
      authService.initialise(firebase.auth())
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  }


  loadProperties = () => {
    analyticsEventLogger.initialise(firebase.analytics())

    const now = new Date()
    const cacheEntry = this.cache.systemProperties
    if (cacheEntry.data && (now - cacheEntry.lastFetched < cacheEntry.ttl)) {
      logger.info('Initializer: Serving from cache')
      return Promise.resolve(cacheEntry.data)
    }

    return systemApi.systemProperties()
      .then((value) => {
        // analyticsEventLogger.log(AnalyticsEvent.SYSTEM_PROPERTIES_LOADED_SUCCESS)
        cacheEntry.data = value.data.items
        cacheEntry.lastFetched = new Date()
        this.updateGlobalBloc({
          initialising: false,
          systemProperties: value.data.items,
        })
        return value.data.items
      })
      .catch((reason) => {
        // analyticsEventLogger.log(AnalyticsEvent.SYSTEM_PROPERTIES_LOADED_ERROR, {
        //   reason: reason,
        // })
        // notificationService.error('Unable to load system properties. Please retry later.')
      })
  }

  fetchUser = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.updateGlobalBloc({user: user})
        this.events.next({type: 'FETCH_APPOINTMENTS'})
      } else {
        this.updateGlobalBloc({user: null})
      }
    })
  }

  fetchAvailableOrganisations = (service) => {
    return appointmentApi.getAvailableOrgs("ANY-UC")
      .then((res) => {
        const organisations = res.data.items
        this.updateGlobalBloc({
          availableOrganisations: organisations.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
          ),
        })

        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {
          locations: `${organisations.length}`,
        })
      })
      .catch((reason) => {
        notificationService.error(
          'Error loading available organisations for appointment type. Please refresh. If the problem persists please contact the clinic.',
        )
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_APPOINTMENT_ORGANISATION_LOADED, {
          status: 'error',
          message: `${reason}`,
        })
      })
  }

  fetchUserAppointments = () => {
    // const {user} = this.subject.value
    // if (!user) return
    //
    // getUserAppointments(user.id).then(appointments => {
    //   this.subject.next({...this.subject.value, appointments})
    //   this.events.next({type: 'FETCH_STATUS'})
    // }).catch(error => {
    //   this.subject.next({...this.subject.value, error})
    // })
  }

  fetchAppointmentStatus = (id) => {
    const {appointmentId, manageAppointments} = this.subject.value

    const apptId = id || appointmentId

    if (!apptId) return

    appointmentApi.getAppointmentStatus(apptId, manageAppointments ? 'isStatus' : null)
      .then((value) => {
        const appointment = value.data
        this.subject.next({...this.subject.value, appointment: appointment})

      })
      .catch((error) => {
        logger.error('Error fetching appointment status', error)
      })

  }

  objective = () => {
    return this.subject.value.objective
  }

  updateBooking(newBooking) {
    this.subject.next({
      ...this.subject.value,
      booking: {
        ...this.subject.value.booking,
        ...newBooking,
      },
    })
  }

  resetGlobalBloc() {
    this.subject.next(this.initialState)
  }

  makeInitialised = () => {
    this.subject.next({
      ...this.subject.value,
      initialising: false,
    })
  }

  dispose = () => {
    //--> unsubscribes from all observables to prevent memory leaks
    this.subject.unsubscribe()
    this.events.unsubscribe()

    this.resetGlobalBloc()
  }

  updateGlobalBloc(update) {
    this.subject.next({
      ...this.subject.value,
      ...update,
    })
  }

  quinnChat = () => {
    const values = this.subject.value.systemProperties.filter(
      (_property) => _property.code === 'quinn.chat',
    )
    if (values.length === 0) {
      return true
    }

    return values[0].status === '1'
  }
}


export class GlobalBlocEvent {
  constructor(type, payload) {
    this.type = type
    this.payload = payload
    logger.info(`Global event created: ${type}`, payload)
  }

  static FETCH_USER = 'FETCH_USER'
  static FETCH_AVAILABLE_ORGANISATIONS = 'FETCH_AVAILABLE_ORGANISATIONS'
  static FETCH_APPOINTMENTS = 'FETCH_APPOINTMENTS'
  static FETCH_STATUS = 'FETCH_STATUS'
}


export const globalBloc = new GlobalBloc()
