import React from 'react';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { FormattedMessage } from 'react-intl';

const LeaveButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      startIcon={<LogoutIcon />}
      sx={{
        color: '#f05726',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        paddingX: '1em',
      }}
    >
      <FormattedMessage id={'common.button.restart'} defaultMessage="RESTART" />
    </Button>
  );
};

export default LeaveButton;
