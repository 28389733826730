import PageContainer from "../../common/PageContainer";
import {DefaultDisconnectedPageFooter, ScrollableQuinnContainer} from "../../common/ScrollableContainer";
import {Box, Grid} from "@mui/material";
import {H2TextTitleContent} from "../../shared/Typography";
import {FormattedMarkdown} from "@decodedhealth/react-library";
import DecodedButton from "../../common/DecodedButton";
import {authService} from "../../../utils/auth";
import {providerStorage} from "../../../utils/provider.qs";
import {logger} from "../../../utils/logging";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import {useHistory} from "react-router-dom";

const ErrorScreen = () => {
    const history = useHistory();
    return (
        <PageContainer loading={false}>
            <ScrollableQuinnContainer
                messageId={`link.checkin.error.bubble`}
                message="There is a problem with the link."
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                            <H2TextTitleContent>
                                <FormattedMarkdown
                                    id={`link.checkin.error.text`}
                                    defaultMessage={`The link has either expired or is invalid. Please click Talk to Quinn to start a new session.`}
                                />
                            </H2TextTitleContent>
                        </Box>
                    </Grid>
                </Grid>
            </ScrollableQuinnContainer>
            <DefaultDisconnectedPageFooter justifyContent={'left'}>
                <Grid xs={12} item>
                    <DecodedButton
                        onClick={async () => {
                            try {
                                await authService.logout();
                                providerStorage.clearProvider();
                            } catch (e) {
                                logger.debug('Error clearing storage from checkin link.');
                            }
                            history.push('/');
                        }}
                    >
                        Talk to Quinn
                    </DecodedButton>
                </Grid>
            </DefaultDisconnectedPageFooter>
        </PageContainer>
    );
};


export default ErrorScreen
