import curo from './curo.json';

const curoMessages = process.env.NODE_ENV !== 'production' ? curo : {};

export const messages =
  process.env.NODE_ENV !== 'production'
    ? {
        ...curoMessages,
      }
    : {
        ...process.env.REACT_APP_TENANT_CONTENTS,
      };
