import {FormattedMarkdown} from '@decodedhealth/react-library';
import {useIntl} from 'react-intl';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import {isValidDate, isValidName} from '../validationUtils';
import {Box, Button, Grid, TextField, Container} from '@mui/material';
import React, {useContext, useState} from 'react';
import {H1TextStepTitle} from '../../../shared/Typography';
import InputMask from 'react-input-mask';
import {LoginDataContext} from '../LoginDataProvider';
import {useMediaQueries} from '../../../../utils/hooks/useMediaQueries';
import {LandscapeNextButton, NextButton} from './NextButton';

const DOB = (props) => {
  const [error, setError] = useState(false);
  const {formatMessage} = useIntl();

  const {loginData, setLoginData} = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setLoginData((prevState) => ({...prevState, [name]: value}));

    if (name === 'dateOfBirth') {
      setError(!isValidDate(value));
    }
  };

  const handleClick = () => {
    setTimeout(() => {
      if (props.index !== 6) {
        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250); // This delay should match the duration of the leave animation
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleClick();
    // updateFormData(data);
    // if (index >= 0) {
    //   setLoginData((prevData) => ({ ...prevData, goBackStep: true }));
    // }
    if (props.index === 5) {
      console.log('***form is complete***');
      // doSubmit(data);
    }
    if (props.index === 6) {
      // doPinSubmit(data);
    }
  };

  return (
    <form
      style={{
        height: 'inherit',
      }}
      onSubmit={onSubmit}
    >
      <Container
        maxWidth="md"
        sx={{
          height: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          marginLeft: '10%',
          '@media (max-width: 1024px)': {
            marginLeft: '5%',
          },
          '@media (max-width: 935px)': {
            marginLeft: '0',
          },
        }}
      >
        <Box
          component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'start',
          }}
          gap={!mediaQuery.isMobileLandscape && 5}
        >
          <Box component={'div'}>
            <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
              <FormattedMarkdown
                id="registration.patient.name"
                defaultMessage={'Patient date of birth'}
              />
            </H1TextStepTitle>
          </Box>
          <Grid
            container
            spacing={2}
            sx={{
              width: '75%',
              '@media (max-width:600px)': {
                width: '100%',
              },
              display: mediaQuery.isMobileLandscape && 'flex',
              flexDirection: mediaQuery.isMobileLandscape && 'row',
              justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
              alignItems: mediaQuery.isMobileLandscape && 'flex-end',
              flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
            }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <InputMask
                mask="99/99/9999"
                value={loginData.dateOfBirth}
                onChange={handleInputChange}
              >
                {(props) => (
                  <DecodedTextField
                    type='tel'
                    name="dateOfBirth"
                    variant="outlined"
                    label="Date of Birth*"
                    error={error}
                    helperText={
                      error &&
                      formatMessage({
                        id: 'registration.field.error.patient.name.family',
                        defaultMessage: 'Invalid date*',
                      })
                    }
                  />
                )}
              </InputMask>
            </Grid>

            {mediaQuery.isMobileLandscape && (
              <Grid item sm={3}>
                <LandscapeNextButton disabled={loginData.dateOfBirth === '' || error}/>
              </Grid>
            )}
          </Grid>
        </Box>
        {!mediaQuery.isMobileLandscape && (
          <NextButton label={'Next'} disabled={loginData.dateOfBirth === '' || error}/>
        )}
      </Container>
    </form>
  );
};

export default DOB;
