import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Box, Grid, TextField, Typography, Button, Container } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
  H1TextStepTitle,
  H2TextTitle,
  H2TextTitleContent,
  TextStepSecondary,
} from '../../../shared/Typography';
import DecodedTextField from '../../../../utils/DecodedTextFIeld';
import { isValidEmail, isValidName } from '../validationUtils';
import InputMask from 'react-input-mask';
import { LoginDataContext } from '../LoginDataProvider';
import { useMediaQueries } from '../../../../utils/hooks/useMediaQueries';
import { LandscapeNextButton, NextButton } from './NextButton'

const Email = (props) => {
  const { formatMessage } = useIntl();
  const [error, setError] = useState(false);

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'email') {
      setError(!isValidEmail(value));
    }
  };

  const handleClick = () => {
    setTimeout(() => {
      if (props.index !== 6) {
        props.setIndex((prevIndex) => (prevIndex + 1) % 7);
      }
    }, 250); // This delay should match the duration of the leave animation
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleClick();
    // updateFormData(data);
    // if (index >= 0) {
    //   setLoginData((prevData) => ({ ...prevData, goBackStep: true }));
    // }
    if (props.index === 5) {
      console.log('***form is complete***');
      // doSubmit(data);
    }
    if (props.index === 6) {
      // doPinSubmit(data);
    }
  };

  return (
    <form
      style={{
        height: 'inherit',
      }}
      onSubmit={onSubmit}
    >
      <Container
        maxWidth="md"
        sx={{
          height: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          marginLeft: '10%',
          '@media (max-width: 1024px)': {
            marginLeft: '5%',
          },
          '@media (max-width: 935px)': {
            marginLeft: '0',
          },
        }}
      >
        {' '}
        <Box
          component={'div'}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'start',
          }}
          gap={!mediaQuery.isMobileLandscape && 5}
        >
          <Box component={'div'}>
          <H1TextStepTitle isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}>
              <FormattedMarkdown
                id="registration.patient.name"
                defaultMessage={'Your email address'}
              />
            </H1TextStepTitle>
          </Box>
          <Grid
            container
            spacing={2}
            sx={{
              width: '75%',
              '@media (max-width:600px)': {
                width: '100%',
              },
              display: mediaQuery.isMobileLandscape && 'flex',
              flexDirection: mediaQuery.isMobileLandscape && 'row',
              justifyContent: mediaQuery.isMobileLandscape && 'flex-start',
              alignItems: mediaQuery.isMobileLandscape && 'flex-end',
              flexWrap: mediaQuery.isMobileLandscape && 'nowrap',
            }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <DecodedTextField
                name="email"
                value={loginData.email}
                onChange={handleInputChange}
                variant="outlined"
                label="Email Address*"
                error={error}
                helperText={
                  error &&
                  formatMessage({
                    id: 'registration.field.error.patient.name.family',
                    defaultMessage: 'Invalid email address*',
                  })
                }
              />
            </Grid>
            {mediaQuery.isMobileLandscape && (
              <Grid item sm={3}>
                <LandscapeNextButton disabled={loginData.email === '' || error} />
              </Grid>
            )}
          </Grid>
        </Box>
        {!mediaQuery.isMobileLandscape && (
          <NextButton label={'Next'} disabled={loginData.email === '' || error} />
        )}
      </Container>
    </form>
  );
};

export default Email;
