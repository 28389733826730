import { Grid, Typography } from '@mui/material';
import PageContainer from '../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import * as React from 'react';
import DecodedComponent from '../../shared/DecodedComponent';
import withStyles from '@mui/styles/withStyles';
import StripeContext from './context';
import { FormattedMessage } from 'react-intl';
import { notificationService } from '../../../utils/notification';
import { H1TextTitleSecondary, H2TextTitleContent } from '../../shared/Typography';
import DecodedButton from '../../common/DecodedButton';
// import TerminalConnectedAnimation from '../../../assets/terminal.gif';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class TerminalForm extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.context.bloc;

    this.state = { terminal: { working: false } };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ terminal: { working: true } });
    try {
      const paymentResult = await this.bloc.collectTerminalPayment();
    } catch (e) {
      notificationService.error('Error taking payment.');
      this.bloc.resetTerminal();
    } finally {
      this.setState({ terminal: { working: false } });
    }
  };

  handleCancel = async (event) => {
    event.preventDefault();
    await this.bloc.cancelPayment();
  };

  handleManual = async (event) => {
    event.preventDefault();
    this.bloc.end();
  };

  render() {
    const { amount, terminal, terminalState } = this.state;

    if (terminalState === 'connected') {
      return this.__renderConnected(terminal);
    }

    if (terminalState === 'success') {
      return this.__renderSuccess();
    }

    if (terminalState === 'error') {
      return this.__renderError();
    }

    return (
      <PageContainer loading={false} onBack={this.bloc.onBack}>
        <ScrollableQuinnContainer
          messageId="payment.terminal.bubble"
          message="Please use the terminal to make payment."
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={'payment.heading'}
                  defaultMessage={'Your total payment for today is:'}
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '40px !important',
                  lineHeight: '32px !important',
                  color: '#002F59',
                }}
              >
                ${amount}
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12} sm={6}>
            <DecodedButton loading={terminal.working} onClick={this.handleManual}>
              <FormattedMessage
                id="payment.terminal.manual.submit"
                defaultMessage={'MANUAL PAYMENT'}
              />
            </DecodedButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DecodedButton loading={terminal.working} onClick={this.handleSubmit}>
              <FormattedMessage id="payment.terminal.submit" defaultMessage={'PAY'} />
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }

  __renderConnected = (terminal) => {
    return (
      <PageContainer loading={false} onBack={this.bloc.onBack}>
        <ScrollableQuinnContainer
          messageId="payment.terminal.connected.bubble"
          message="Please use the terminal to make payment."
        >
          <Grid container spacing={3}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              item
              xs={12}
            >
              {/*<img src={TerminalConnectedAnimation} alt="terminal-connected" />*/}
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12} sm={6}>
            <DecodedButton onClick={this.handleCancel}>
              <FormattedMessage
                id="payment.terminal.manual.submit"
                defaultMessage={'CANCEL PAYMENT'}
              />
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  };

  __renderSuccess = () => {
    return (
      <PageContainer loading={false} onBack={this.bloc.onBack}>
        <ScrollableQuinnContainer
          messageId="payment.terminal.connected.bubble"
          message="Please use the terminal to make payment."
        >
          <Grid container spacing={3}>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              item
              xs={12}
            >
              {/*<img src={TerminalConnectedAnimation} alt="terminal-connected" />*/}
            </Grid>
            <Grid item xs={12}>
              <H1TextTitleSecondary sx={{ textAlign: 'center' }}>Thank you!</H1TextTitleSecondary>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  };

  __renderError = () => {
    const { terminal, terminalState } = this.state;

    return (
      <PageContainer loading={false} onBack={this.bloc.onBack}>
        <ScrollableQuinnContainer
          messageId="payment.terminal.connected.bubble"
          message="Please use the terminal to make payment."
        >
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <H1TextTitleSecondary sx={{ textAlign: 'center', marginTop: '24px' }}>
                Payment error.
              </H1TextTitleSecondary>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitleContent sx={{ textAlign: 'center' }}>
                Please try again or click manual payment and proceed to the front desk.
              </H2TextTitleContent>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12} sm={6}>
            <DecodedButton loading={terminal.working} onClick={this.handleManual}>
              <FormattedMessage
                id="payment.terminal.manual.submit"
                defaultMessage={'MANUAL PAYMENT'}
              />
            </DecodedButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DecodedButton loading={terminal.working} onClick={this.handleSubmit}>
              <FormattedMessage id="payment.terminal.submit" defaultMessage={'RETRY PAY'} />
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  };
}

export default withStyles(styles)((props) => (
  <StripeContext.Consumer>
    {(value) => {
      return <TerminalForm context={value} {...props} />;
    }}
  </StripeContext.Consumer>
));
