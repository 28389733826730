import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material';
import { providerStorage } from '../../utils/provider.qs';

const commonTheme = createTheme({
  root: {
    fontFamily: 'Roboto, "sans-serif"',
    minHeight: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    // fontSize: '14px',
  },

  breakpoints: {
    values: {
      xs: 0,  // xs breakpoint
      sm: 600,  // sm breakpoint
      md: 960,  // md breakpoint
      lg: 1280,
      xl: 1920,
    },
  },

  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: '#ffffff',
        },
      },
    },


    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: '0',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'color .01s, background-color .3s',
          minHeight: '45px',
          width: '100%',
          boxSizing: 'border-box',
          maxWidth: '350px',
          borderRadius: '10px',
          fontWeight: '700',
          fontSize: '1rem',
          lineHeight: '1.222',
          textAlign: 'center',
          textDecoration: 'none',
          textTransform: 'none',
          verticalAlign: 'baseline',
          '@media (max-width: 600px)': {
            fontSize: '1rem',
          },
        },

        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: 'none',
          },
        },

        textPrimary: {},
      },
    },

    MuiContainer: {
      styleOverrides: {

        maxWidthLg: {
          '@media (min-width: 600px)': {
            maxWidth: '1029px',
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiPickersDay-root': {
            fontSize: '14px',
          },
          '& .MuiTypography-caption': {
            fontSize: '14px',
            color: '#00D1FF',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
  },
});

//TODO: Add font sizes here and remove from components.
const curoTheme = responsiveFontSizes(
  createTheme(commonTheme, {
    palette: {
      mode: 'light',
      primary: {
        light: '#B1F1FF',
        main: '#00D1FF',
      },
      secondary: {
        main: '#E50B14',
      },
      text: {
        primary: '#074D75',
      },
      action: {
        hover: '#00D1FF',
      },
      accent: {
        main: '#E50B14',
        contrastText: '#ffffff',
      },
    },


    components: {
      MuiListItem: {
        styleOverrides: {
          button: {
            '&:hover': {
              color: '#ffffff',
              backgroundColor: '#00D1FF',
            },
          },
        },
      },
      // MuiInputBase: {
      //   styleOverrides: {
      //     input: {
      //       fontSize: '2rem !important', // Set the font size you want here
      //     },
      //   },
      // },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            '&:hover': {
              color: '#ffffff',
              backgroundColor: '#00D1FF',
            },
            '&[data-focus="true"]': {
              color: '#ffffff',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            color: '#ffffff',
            backgroundColor: '#00D1FF',
            boxShadow: 'none',
            '@media (hover: hover)': {
              '&:hover': {
                color: '#ffffff',
                backgroundColor: '#00D1FF',
                boxShadow: 'none',
              },
            },
            '@media (hover: none)': {
              '&:hover': {
                color: '#ffffff',
                backgroundColor: '#00D1FF',
                boxShadow: 'none',
              },
            },
          },

          containedSecondary: {
            color: '#71675E',
            backgroundColor: '#ffffff',
            border: '1px solid #71675E',
            '@media (hover: hover)': {
              '&:hover': {
                color: '#71675E',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: 'none',
              },
            },
            '@media (hover: none)': {
              '&:hover': {
                color: '#ffffff',
                backgroundColor: '#E50B14',
                boxShadow: 'none',
              },
            },
          },

          text: {
            '&:hover': {
              color: '#E50B14',
            },
          },

          textSecondary: {
            '&:hover': {
              color: '#E50B14',
            },
          },
        },
      },
    },
  }),
);

export const getThemeBasedOnTenant = () => {
  return curoTheme;
};
