import axios from 'axios';
import { VERSION } from '../version';
import { tenantUtil } from '../tenant';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../user';
import { AnalyticsEvent, analyticsEventLogger } from '../events';
import retry from '../retry';
import { v4 as uuidv4 } from 'uuid';
import { authService } from '../auth';
import { CheckSession } from '../storage';
import { logger } from '../logging';
import {AppointmentConstants} from "../../const";

class AppointmentApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    this.isStatus = false;
  }

  getAppointmentsQueueSummary = () => {
    return this.instance.get(`${this.baseUrl}/api/appointments/_queue_summary`);
  };

  getAppointmentStatus = async (appointmentId) => {
    if (!appointmentId) {
      throw new Error('Appointment ID is null or undefined.');
    }

    const headers = {
      'X-DH-source': 'Consumer Webapp',
      'X-DH-version': VERSION,
      'X-API-KEY': process.env.REACT_APP_DH_API_KEY,
    };

    const isExistingConfirm = CheckSession('task', 'existing-confirm');

    let token;
    if (CheckSession('action', 'login_after_setting_org') && isExistingConfirm) {
      token = tenantUtil.tenantToken();
    } else {
      token = await authService.getToken();
    }

    headers['Authorization'] = `Bearer ${token}`;

    const instance = axios.create();
    try {
      const response = await instance.get(
        `${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/status`,
        {
          headers: headers,
        },
      );
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);
      return response;
    } catch (error) {
      console.log('reason', error);
      analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR);

      // Try the other token if the first one fails
      if (token === tenantUtil.tenantToken()) {
        token = await authService.getToken();
      } else {
        token = tenantUtil.tenantToken();
      }

      headers['Authorization'] = `Bearer ${token}`;
      try {
        const response = await instance.get(
          `${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/status`,
          {
            headers: headers,
          },
        );
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);
        return response;
      } catch (secondError) {
        console.log('second reason', secondError);
        analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_ERROR);
        return secondError;
      }
    }
  };

  getAppointmentQueueStatus = async (appointmentId) => {
    return await retry(() =>
      axios.get(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/queue_state`),
    );
  };

  getAppointmentTelehealthDetails = (appointmentId) => {
    return axios.get(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/telehealth`);
  };

  getPrecallTelehealthDetails = () => {
    return axios.get(`${this.baseUrl}/api/consumers/me/appointments/_precall`);
  };

  getAvailableProviders = () => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments/providers/_search`, {});
  };

  getAvailableOrganisationAnonymous = (service) => {
    return retry(() =>
      this.instance.post(`${this.baseUrl}/api/organisations/_search`, {
        serviceCodes: service ? [service] : [AppointmentConstants.DEFAULT_SERVICE], //? INP-UC has issues
        roles: ['O_SRV_PROV'],
      }),
    );
  };

  getAvailableOrgs = (service) => {
    return retry(() =>
      axios.post(`${this.baseUrl}/api/organisations/_search`, {
        serviceCodes: service ? [service] : [AppointmentConstants.DEFAULT_SERVICE], //? INP-UC has issues
        roles: ['O_SRV_PROV'],
      }),
    );
  };

  getServiceProviderSummary = (providerId) => {
    return axios.get(
      `${this.baseUrl}/api/consumers/me/appointments/providers/${providerId}/_summary`,
    );
  };

  getServiceProviderSummaryForService = (providerId, serviceCode) => {
    return retry(() =>
      axios.get(`${this.baseUrl}/api/consumers/me/appointments/providers/${providerId}/_summary`, {
        params: { service: serviceCode },
      }),
    );
  };

  getAvailableAppointmentScheduleBetweenAnonymous = (
    startDate,
    endDate,
    selectedProvider,
    serviceCode,
    doctor,
    applyRules,
  ) => {
    function subtractDays(numOfDays, date = new Date()) {
      date.setDate(date.getDate() - numOfDays);
      return date;
    }

    let request = {
      applyRules: applyRules,
      period: {
        start: subtractDays(1, new Date(startDate)),
        end: endDate,
      },
      // date: selectedDate,
      serviceProvider: {
        value: selectedProvider,
      },
      services: [
        {
          code: {
            value: serviceCode,
          },
        },
      ],
    };

    if (doctor) {
      request.staff = [
        { role: 'PROVIDER', identifier: { system: 'decoded', code: 'id', value: doctor } },
      ];
    }

    return retry(() =>
      this.instance.post(`${this.baseUrl}/api/organisations/schedules/_search`, request),
    );
  };

  getAvailableAppointmentSchedule = (
    selectedDate,
    selectedProvider,
    serviceCode,
    doctor,
    applyRules,
  ) => {
    function subtractDays(numOfDays, date = new Date()) {
      date.setDate(date.getDate() - numOfDays);
      return date;
    }

    let request = {
      applyRules: applyRules,
      period: {
        start: subtractDays(1, new Date(selectedDate)),
        end: selectedDate,
      },
      // date: selectedDate,
      serviceProvider: {
        value: selectedProvider,
      },
      services: [
        {
          code: {
            value: serviceCode,
          },
        },
      ],
    };

    if (doctor) {
      request.staff = [
        { role: 'PROVIDER', identifier: { system: 'decoded', code: 'id', value: doctor } },
      ];
    }
    return retry(
      () => axios.post(`${this.baseUrl}/api/organisations/schedules/_search`, request), //TODO changed to organisations from consumers
    );
  };

  anonGetAvailableAppointmentSchedule = (
    selectedDate,
    selectedProvider,
    serviceCode,
    doctor,
    applyRules,
  ) => {
    function subtractDays(numOfDays, date = new Date()) {
      date.setDate(date.getDate() - numOfDays);
      return date;
    }

    let request = {
      applyRules: applyRules,
      period: {
        start: subtractDays(1, new Date(selectedDate)),
        end: selectedDate,
      },
      // date: selectedDate,
      serviceProvider: {
        value: selectedProvider,
      },
      services: [
        {
          code: {
            value: serviceCode ? serviceCode : AppointmentConstants.DEFAULT_SERVICE, //TODO this needs to be not hardcoded
          },
        },
      ],
    };

    if (doctor) {
      request.staff = [
        { role: 'PROVIDER', identifier: { system: 'decoded', code: 'id', value: doctor } },
      ];
    }

    return retry(
      () => axios.post(`${this.baseUrl}/api/organisations/schedules/_search`, request), //TODO changed to organisations from consumers
    );
  };

  updateAppointment = async (appointmentId = null, request) => {
    if (!appointmentId) {
      logger.info('Appointment ID is null or undefined in updateAppointment.');
      throw new Error('Appointment ID is null or undefined.');
    }

    return await retry(() =>
      axios.post(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_command`, {
        correlationId: uuidv4(),
        payload: request,
      }),
    );
  };

  command = (appointmentId, request) => {
    if (!appointmentId) {
      logger.info('Appointment ID is null or undefined in command.');

      throw new Error('Appointment ID is null or undefined.');
    }

    return axios.post(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_command`, {
      payload: request,
    });
  };

  paymentCommand = (appointmentId, request) => {
    return axios.post(
      `${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/payments/_command`,
      request,
    );
  };

  confirmAppointment = (appointmentId, data) => {
    if (!appointmentId) {
      logger.info('Appointment ID is null or undefined in confirmAppointment.');

      throw new Error('Appointment ID is null or undefined.');
    }

    return axios.patch(
      `${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_confirm`,
      data,
    );
  };

  remoteConfirmAppointment = (appointmentId) => {
    const data = {
      payload: {
        command: 'confirm',
      },
    };
    return retry(() =>
      axios.post(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_command`, data),
    );
  };

  checkInAppointment = (appointmentId) => {
    return axios.patch(
      `${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_checkin`,
      {},
    );
  };

  getCurrentAppointment = (timeWindow) => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments`, {
      timeWindow: timeWindow,
      status: ['WAITING', 'RESERVED'],
    });
  };

  getAllFutureAppointments = async () => {
    let now = new Date();
    now.setDate(now.getDate() - 1);

    const response = await axios.post(`${this.baseUrl}/api/consumers/me/appointments`, {
      period: {
        start: format(now, DATE_FORMAT),
      },
      status: ['WAITING', 'RESERVED', 'REQUESTED'],
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  };

  getAppointmentForDate = (date) => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments`, {
      date: date,
      status: ['WAITING', 'RESERVED'],
    });
  };

  getNextAvailableAppointmentService = (organisationId, channel) => {
    return axios.get(
      `${this.baseUrl}/api/consumers/me/appointments/services/${
        organisationId ? organisationId : '_'
      }/_search?channel=${channel}`,
    );
  };

  getScheduleSummary = (date, serviceCode, serviceProvider) => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments/schedules/_summary`, {
      serviceProvider: {
        value: serviceProvider ? serviceProvider : '',
      },
      date: date,
      services: [
        {
          code: {
            value: serviceCode,
          },
        },
      ],
    });
  };

  getServiceScheduleSummary = (start, end, serviceCode, serviceProvider, doctor, applyRules) => {
    let request = {
      applyRules: applyRules,
      serviceProvider: {
        value: serviceProvider ? serviceProvider : '',
      },
      period: {
        start: start,
        end: end,
      },
      services: [
        {
          code: {
            value: serviceCode,
          },
        },
      ],
    };

    if (doctor) {
      request.staff = [
        { role: 'PROVIDER', identifier: { system: 'decoded', code: 'id', value: doctor } },
      ];
    }

    return axios.post(`${this.baseUrl}/api/consumers/me/appointments/schedules/_summary`, request);
  };

  getServiceStaff = (serviceProvider) => {
    return axios.get(
      `${this.baseUrl}/api/consumers/me/appointments/providers/${serviceProvider}/people`,
    );
  };

  getServiceStaffWithPermission = (serviceProvider, permission) => {
    return axios.get(
      `${this.baseUrl}/api/consumers/me/appointments/providers/${serviceProvider}/people?permissions=${permission}`,
    );
  };

  getChatHistory = (appointmentId) => {
    return axios.get(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/chat/history`);
  };

  cancelAppointment = (appointmentId, request) => {
    if (!appointmentId) {
      throw new Error('Appointment ID is null or undefined.');
    }

    return axios.post(
      `${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/_cancel`,
      request,
    );
  };

  getCancelReasons = () => {
    return axios.get(`${this.baseUrl}/api/consumers/me/appointments/cancel_reasons`);
  };

  getDraftAppointment = (appointmentId) => {
    return axios.post(`${this.baseUrl}/api/consumers/me/appointments/_draft`, {
      appointmentId,
    });
  };
}

export const appointmentApi = new AppointmentApi();
