import axios from 'axios';
import {authService} from "../auth";
import {VERSION} from "../version";
import {tenantUtil} from "../tenant";

class PaymentApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['Content-Type'] = 'multipart/form-data';
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );


  }



  submitPaymentCommand(request) {
    return axios.post(`${this.baseUrl}/api/accounts/me/payment_methods`, request);
  }

  verifyPayment(request) {
    return axios.put(`${this.baseUrl}/api/accounts/me/payment_methods`, request);
  }

  getPaymentMethods() {
    return axios.get(`${this.baseUrl}/api/accounts/me/payment_methods`);
  }

 submitInsuranceCard(formData) {
    return this.instance.post(
      `${this.baseUrl}/api/insurances/card/analyze_document`, formData);
  }

  getAnalyzeResult(analyzeId) {
    return this.instance.get(
      `${this.baseUrl}/api/insurances/card/analyze_document/${analyzeId}`);
  }

  getInsuranceCard() {
    return axios.get(`${this.baseUrl}/api/accounts/me/payment_methods/primary/insurance_card`);
  }
}

export const paymentApi = new PaymentApi();


