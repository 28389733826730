import React, { useEffect, useState, useCallback } from 'react';
import { Button, Container, Box, IconButton } from '@mui/material';
import Name from './components/Name';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DOB from './components/DOB';
import Phone from './components/Phone';
import { useTransition, animated } from 'react-spring';
import Email from './components/Email';
import ConfirmInput from './components/ConfirmInput';
import SexAtBirth from './components/SexAtBirth';
import PinCode from './components/PinCode';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { authService } from '../../../utils/auth';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { notificationService } from '../../../utils/notification';
import { errorResolver } from '../../../utils/error.resolver';
import { phoneUtil } from '../../../utils/phone';
import { userInfoUtil } from '../../../utils/user';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useIsMobile } from '../../../shared-library';
import { logger } from '../../../utils/logging';
import { useMediaQueries } from '../../../utils/hooks/useMediaQueries';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {globalBloc} from "../../global.bloc";

const LoginForm = (props) => {
  const { loginData, setLoginData, doSubmit } = props;

  const [index, setIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(index);

  const mediaQuery = useMediaQueries();

  const history = useHistory()

  useEffect(() => {

    //=> removes 'manage-appointment' from sessionStorage on back button click
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        sessionStorage.removeItem('action');
      }
    });

    setPrevIndex(index);

    return () => {
      unlisten(); //=> removes pop listener
    }

  }, [index]);

  const pages = [
    ({ style }) => (
      <animated.div style={{ height: '100%' }}>
        <Name {...props} setIndex={setIndex} index={index} />
      </animated.div>
    ),
    ({ style }) => (
      <animated.div style={{ height: '100%' }}>
        <SexAtBirth {...props} setIndex={setIndex} index={index} />
      </animated.div>
    ),
    ({ style }) => (
      <animated.div style={{ height: '100%' }}>
        <DOB {...props} setIndex={setIndex} index={index} />
      </animated.div>
    ),
    ({ style }) => (
      <animated.div style={{ height: '100%' }}>
        <Phone {...props} setIndex={setIndex} index={index} />
      </animated.div>
    ),
    ({ style }) => (
      <animated.div style={{ height: '100%' }}>
        <Email {...props} setIndex={setIndex} index={index} />
      </animated.div>
    ),
    ({ style }) => (
      <animated.div style={{ height: '100%' }}>
        <ConfirmInput {...props} setIndex={setIndex} index={index} />
      </animated.div>
    ),
    ({ style }) => (
      <animated.div style={{ height: '100%' }}>
        <PinCode {...props} setIndex={setIndex} index={index} />
      </animated.div>
    ),
  ];

  const transitions = useTransition(index, {
    from: {
      opacity: 0,
      transform: index > prevIndex ? 'translate3d(100%,0,0)' : 'translate3d(-100%,0,0)',
    },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: {
      opacity: 0,
      transform: index > prevIndex ? 'translate3d(-100%,0,0)' : 'translate3d(100%,0,0)',
    },
    config: (item, state) => ({
      tension: 220,
      friction: 120,
      duration: state === 'leave' ? 250 : 500, // Speed up the leave animation
    }),
  });

  const handleClick = () => {
    setTimeout(() => {
      if (index !== 6) {
        setIndex((prevIndex) => (prevIndex + 1) % pages.length);
      }
    }, 250); // This delay should match the duration of the leave animation
  };

  const handleBackClick = () => {
    if (index > 0) {
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  const onSubmit = (data) => {
    handleClick();
    // updateFormData(data);
    if (index >= 0) {
      setLoginData((prevData) => ({ ...prevData, goBackStep: true }));
    }
    if (index === 5) {
      logger.info('***form is complete***');
      doSubmit(data);
    }
    if (index === 6) {
      doPinSubmit(data);
    }
  };

  const createAuthRequest = (data) => {
    logger.info('***loginData***', data);
    const processedPin = data.pinCode.replace(/-/g, '');

    return {
      pin: processedPin,
      name: {
        given: loginData.firstName,
        family: loginData.lastName,
      },
      dob: userInfoUtil.formatDate(loginData.dateOfBirth),
      number: loginData.number
        ? phoneUtil.formatPhoneNumberForRegistration(
            loginData.number,
            props.code,
            props.codeCountry,
          )
        : null,
      email: loginData.email,
    };
  };

  const doPinSubmit = (data) => {
    let { setLoadingStatus } = props;

    setLoadingStatus(true);

    authService
      .confirmVerificationCode(createAuthRequest(data))
      .then((token) => {
        authService
          .loginWithToken(token)
          .then((user) => {
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_SUCCESS);

            setLoginData((prevData) => ({ ...prevData, verificationSuccess: true }));

            analyticsEventLogger.setUser(user.uid);
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
              reason: `${error}`,
            });
            notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
            setLoadingStatus(false);
          });
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
          reason: `${error}`,
        });
        setLoadingStatus(false);
        notificationService.error(errorResolver.resolveAuthErrorDisplay(error));
      })
      .finally(() => {
        sessionStorage.removeItem('currentPath');
      });
  };

  return (
    // <ValidatorForm autoComplete="off"  >
    <>
      {index !== 0 && (
        <IconButton
          size="large"
          sx={{
            position: 'absolute',
            top: '30px',
            left: mediaQuery.isSm || mediaQuery.isMobileLandscape ? '40px' : '150px',
            transform: 'translateX(-50%)',
            zIndex: 100,
          }}
          onClick={handleBackClick}
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
      )}
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      > */}
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, position: 'relative' }}>
        {transitions((style, i) => {
          const Page = pages[i];
          return (
            <animated.div
              style={{ ...style, position: 'absolute', top: 0, left: 0, bottom: 0, width: '100%' }}
            >
              <Page {...props} />
            </animated.div>
          );
        })}
      </Box>
      {/* </div> */}
    </>
    // </ValidatorForm>
  );
};

export default LoginForm;
