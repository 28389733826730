import { Dialog, DialogTitle, styled } from '@mui/material';

export const DecodedDialog = styled(Dialog, {})(({ theme }) => ({}));

export const DecodedDialogTitle = styled(
  DialogTitle,
  {},
)(({ theme }) => ({
  fontWeight: '400 !important',
  fontSize: '20px !important',
  lineHeight: '32px !important',
  color: 'rgba(0, 0, 0, 0.87)',
}));
