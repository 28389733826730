import axios from 'axios';
import { VERSION } from '../version';
import firebase from 'firebase/app';
import 'firebase/auth';
import { tenantUtil } from '../../utils/tenant';
import retry from '../retry';

export class AssistantApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
  }

  isUserLoggedIn() {
    const user = firebase.auth().currentUser;
    return user !== null;
  }

  generateToken() {
    if (this.isUserLoggedIn()) {
      return retry(() => axios.get(`${this.baseUrl}/api/assistant/_auth`));
      // return this.generateAnonymousToken();
    } else {
      return this.generateAnonymousToken();
    }
  }

  generateDirectlineToken() {
    return axios.get(`${this.baseUrl}/api/consumers/me/conversations/_start`);
  }

  generateAnonymousToken() {
    const instance = axios.create();

    instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Content-Type'] = 'application/json';
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    return retry(() => instance.get(`${this.baseUrl}/api/annon/assistant/_auth`));
  }
}

export const assistantApi = new AssistantApi();
