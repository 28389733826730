import * as rxjs from 'rxjs';
import { notificationService } from '../../../utils/notification';
import { DecodedBloc } from '../../shared/DecodedComponent/bloc';
import { QUINN_SCHEDULED, routeUtil } from '../../../utils/route.name';
import { appointmentApi } from '../../../utils/services/appointments.api';
import {AppointmentConstants} from "../../../const";

export class Bloc extends DecodedBloc {
  appointmentApi;
  onSwitch;

  constructor(props) {
    super({ ...props });
    this.appointmentApi = props.appointmentApi;
    this.onSwitch = props.onSwitch;
  }

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    this.__updateSubject({ initialising: false });
  };

  switchToUrgentcare = () => {
    const { appointment } = this.subject.value;
    this.__updateSubject({ switching: true });

    const request = this.__createAppointmentRequestData(appointment);
    request.command = 'update_service';

    this.appointmentApi.command(appointment.id, request).then(
      (data) => {
        appointment.service = request.service.code.value;
        this.onSwitch(appointment);
      },
      (reason) => {
        notificationService.error(
          'Error switching to urgentcare. Please try again and if the problem persists call the clinic.',
        );
        this.__updateSubject({ switching: false });
      },
    );
  };

  __createAppointmentRequestData = (appointment) => {
    return {
      service: {
        code: {
          value: AppointmentConstants.DEFAULT_SERVICE,
        },
        channel: {
          value: appointment.type,
        },
      },
    };
  };
}

export class Constants {}

export class BlocEvent {
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
