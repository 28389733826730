import { appointmentApi } from '../../utils/services/appointments.api';
import { notificationService } from '../../utils/notification';
import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { uriStorage } from '../../utils/storage';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';
import { dateUtil } from '../../utils/date';
import { AnalyticsEvent } from '../../utils/events';
import {logger} from "../../utils/logging";

export class Bloc extends DecodedBloc {
  eventSubscription;

  constructor(appointmentId) {
    super({
      appointmentId: appointmentId,
      retry: 0,
    });

    this.__initialise(appointmentId, 0);
  }

  __initialise = (appointmentId, retry) => {
    this.eventSubscription = this.subscribeToEvents(this.__handleEvent);

    appointmentApi.getAppointmentStatus(appointmentId).then(
      (value) => {
        if (value.isAxiosError) {
          this.__handleInitialiseError(retry);
        } else {
          const appointment = value.data;
          this.__updateSubject({ appointment: appointment });
          this.__publishEvent(BlocEvent.APPOINTMENT_LOADED);
        }
      },
      (reason) => {
        this.logAnalyticsEvent(AnalyticsEvent.CHECKIN_ERROR, { reason: reason, retry: retry });
        this.__handleInitialiseError(retry);
      },
    );
  };

  __handleInitialiseError = (retry) => {
    if (retry > 0) {
      this.logAnalyticsEvent(AnalyticsEvent.CHECKIN_ERROR, { reason: 'retryExhausted' });
      this.__publishEvent(BlocEvent.APPOINTMENT_CHECK_IN_ERROR, {
        messageId: 'checkin.error',
        message: 'Please see the front desk.',
      });
      this.__makeInitialised();
    } else {
      this.__publishEvent(BlocEvent.APPOINTMENT_LOAD_ERROR, {});
    }
  };

  __checkin = (retry) => {
    const { appointment } = this.subject.value;

    if (appointment.status === 'WAITING') {
      this.__publishEvent(BlocEvent.APPOINTMENT_CHECKED_IN, appointment);
    } else {
      appointmentApi
        .checkInAppointment(appointment.id)
        .then(
          (value) => {
            this.logAnalyticsEvent(AnalyticsEvent.CHECKIN_SUCCESS, {});
            this.__publishEvent(BlocEvent.APPOINTMENT_CHECKED_IN, appointment);
          },
          (reason) => {
            this.logAnalyticsEvent(AnalyticsEvent.CHECKIN_ERROR, { reason: reason });
            notificationService.error('Error on attempt to check in. Please see the front desk.');
            this.__publishEvent(BlocEvent.APPOINTMENT_CHECK_IN_ERROR, {
              messageId: 'checkin.confirmation.error',
              message: 'Please see the front desk to complete your check in.',
            });
          },
        )
        .finally(() => {
          this.logAnalyticsEvent(AnalyticsEvent.CHECKIN_ATTEMPT_COMPLETE, {});
        });
    }
  };

  __handleEvent = (e) => {
    const { appointemntId, retry } = this.subject.value;
    const { type, data } = e;

    switch (type) {
      case BlocEvent.APPOINTMENT_LOADED:
        this.__checkin();
        return;
      case BlocEvent.APPOINTMENT_LOAD_ERROR:
        this.__initialise(appointemntId, retry + 1);
        return;
      case BlocEvent.APPOINTMENT_CHECK_IN_ERROR:
        logger.info('APPOINTMENT_CHECK_IN_ERROR')
        this.__makeInitialised({ messageId: data.messageId, message: data.message });
        this.__clearSession().finally(() => {
          this.close();
        });
        return;
      case BlocEvent.APPOINTMENT_CHECKED_IN:
        this.__setAppointmentDetail();
        this.__makeInitialised();
        this.__clearSession().finally(() => {
          this.close();
        });
        return;
    }
  };

  __setAppointmentDetail = () => {
    const { appointment } = this.subject.value;
    const startTime = dateUtil.parseDate(appointment.start);
    const queueNumber = appointment.waitingRoomSummary?.numberInQueue;
    logger.info({appointment, startTime, queueNumber})
    this.__updateSubject({
      appointment: appointment,
      startTime: startTime,
      queueNumber: queueNumber,
    });
  };

  __clearSession = async () => {
    logger.info('Clearing session')
    return authService.logout().then((e) => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
      return e;
    });
  };

  close = () => {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static APPOINTMENT_LOADED = 'APPOINTMENT_LOADED';
  static APPOINTMENT_LOAD_ERROR = 'APPOINTMENT_LOAD_ERROR';
  static APPOINTMENT_CHECKED_IN = 'APPOINTMENT_CHECKED_IN';
  static APPOINTMENT_CHECK_IN_ERROR = 'APPOINTMENT_CHECK_IN_ERROR';
}
