import React, { useState, useEffect, useContext } from 'react';
import withStyles from '@mui/styles/withStyles';
import { authService } from '../../../utils/auth';
import { AnalyticsEvent, analyticsEventLogger } from '../../../utils/events';
import { userInfoUtil } from '../../../utils/user';
import { FormattedMessage } from 'react-intl';
import { phoneUtil } from '../../../utils/phone';
import PageContainer from '../../common/PageContainer';
import { withTheme } from '@mui/styles';
import { H2TextTitleContent } from '../../shared/Typography';
import DecodedButton, { DecodedSubmitButton } from '../../common/DecodedButton';
import { providerStorage } from '../../../utils/provider.qs';
import LoginForm from '../LoginForm/LoginForm';
import { ROOT_ROUTE } from '../../../utils/route.name'
import { Redirect } from 'react-router-dom/cjs/react-router-dom'
import { LoginDataContext } from '../LoginForm/LoginDataProvider'
import {logger} from "../../../utils/logging";

const styles = (theme) => ({
  validation: {
    flex: '1',
    width: '100%',
    paddingTop: '3em',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1em',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
    justifyContent: 'space-between',
  },
  formInput: {
    flex: '1',
    paddingBottom: '2em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonGroup: {
    paddingTop: '2em',
    flexDirection: 'row',
    textAlign: 'center',
  },
  button: {
    flex: '1 1 30%',
    padding: '1em',
  },
  disabledButton: {
    color: '#05167e',
    backgroundColor: '#ffffff',
  },
  warning: {
    width: '100%',
    lineHeight: '1.75',
    color: '#1A0C00',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
});

const UserAuthNew = (props) => {
    const {
      setLoadingStatus,
      handleBack,
      code,
      codeCountry,
      loading,
    } = props;

  const [loginData, setLoginData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    number: '',
    sexAtBirth: '',
    goBackStep: false,
    pinCode: '',
    verificationSuccess: false,
    isError: true,
  });

  const doSubmit = (event) => {

    setLoadingStatus(true);

    analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_REQUEST);

    authService.loginWithEmail(createAuthRequest()).finally(() => {
      const loginDetails = {
        firstName: loginData.firstName,
        lastName: loginData.lastName,
        dateOfBirth: loginData.dateOfBirth,
        email: loginData.email,
        number: loginData.number,
      };
      sessionStorage.setItem('loginDetails', JSON.stringify(loginDetails));
      setLoadingStatus(false);
    });
  };

  const backToLogin = () => {
    handleBack();
  };

  const createAuthRequest = () => {
    return {
      name: {
        given: loginData.firstName.trim(),
        family: loginData.lastName.trim(),
      },
      dob: userInfoUtil.formatDate(loginData.dateOfBirth),
      number: loginData.number ? phoneUtil.formatPhoneNumberForRegistration(loginData.number, code, codeCountry) : null,
      email: loginData.email,
    };
  };

  const isLoading = () => {
    return loading || authService.isLoading();
  };
  

  if (loginData.verificationSuccess) {
    logger.info('***redirected***');
    return (
      <Redirect
        to={{
          pathname: ROOT_ROUTE,
          state: {
            loginData,
            code,
            codeCountry,
            addressCountry: codeCountry,
          },
        }}
      />
    );
  }

  return (
    <PageContainer loading={loading} onBack={false}>
      <LoginForm
        {...props}
        loginData={loginData}
        setLoginData={setLoginData}
        setLoadingStatus={setLoadingStatus}
        // updateFormData={updateFormData}
        doSubmit={doSubmit}
      />
    </PageContainer>
  );
};

export default withTheme(withStyles(styles)(UserAuthNew));
