import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';

import { Route, Switch, withRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import Invalid from '../Invalid';
import LinkHandler from '../Link';
import LocalNotifications from '../shared/Notifications';
import {
  INVALID_ROUTE,
  KIOSK_ROUTE,
  LINK_ROUTE,
  ROOT_ROUTE,
  THANKYOU_ROUTE,
  WALKIN_ONLY,
} from '../../utils/route.name';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { isAfter, isBefore, isValid, parse, subYears } from 'date-fns';
import { messages } from '../../utils/intl/messages';
import { globalBloc } from '../global.bloc';
import { userInfoUtil } from '../../utils/user';
import { getThemeBasedOnTenant } from '../App/themes';
import { Bloc } from '../BookingPaymentMethod/Insurance/bloc';
import { insurancesApi } from '../../utils/services/insurances.api';
import { paymentApi } from '../../utils/services/payment.api';
import { registrationApi } from '../../utils/services/register.api';
import Secure from '../Secure';
import { PrivateRoute } from '../../utils/protected.route';
import { providerStorage } from '../../utils/provider.qs';
import KioskAdmin from '../KioskAdmin';
import WalkinNotice from '../WalkinNotice';
import ThankYou from '../ThankYou';

const styles = {
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    maxHeight: '100%',
  },
};

class DevApp extends Component {
  globalStateSubsciption;

  constructor(props) {
    super(props);

    let bloc = new Bloc({
      insuranceApi: insurancesApi,
      paymentApi: paymentApi,
      codesetApi: registrationApi,
    });
    bloc.initialise();

    this.state = { bloc: bloc };

    this.__watchGlobalState = this.__watchGlobalState.bind(this);
  }

  componentDidMount() {
    if (process.env.REACT_APP_HTTPS !== 'http') {
      window.location.protocol === 'http:' &&
        (window.location.href = window.location.href.replace(/^http:/, 'https:'));
    }

    providerStorage.setCurrentProvider(this.props.location.search);

    this.globalStateSubsciption = globalBloc.subscribeToState(this.__watchGlobalState);

    globalBloc.makeInitialised();

    ValidatorForm.addValidationRule('isDateValid', (value) => {
      const date = parse(value, 'MM/dd/yyyy', new Date());
      if (!isValid(date)) return false;

      const now = new Date();

      return isBefore(date, now) && isAfter(date, subYears(now, 130));
    });

    ValidatorForm.addValidationRule('isValidName', (value) => {
      return userInfoUtil.validName(value.trim());
    });
  }

  __watchGlobalState = (state) => {
    this.setState({
      ...state,
    });
  };

  componentWillUnmount() {
    this.state.bloc.close();
  }

  render() {
    const { classes } = this.props;
    const { bloc } = this.state;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getThemeBasedOnTenant()}>
          <IntlProvider locale="en" messages={messages}>
            <div className={classes.root}>
              <CssBaseline />
              <Switch>
                <Route path={WALKIN_ONLY} component={WalkinNotice} />
                <Route path={LINK_ROUTE} component={LinkHandler} />
                <Route path={KIOSK_ROUTE} component={KioskAdmin} />
                <Route path={INVALID_ROUTE} component={Invalid} />
                <Route path={THANKYOU_ROUTE} component={ThankYou} />
                <PrivateRoute path={ROOT_ROUTE} component={Secure} />
              </Switch>
              {/*<Insurance bloc={bloc} />*/}
              {/*<WalkinNotice />*/}
            </div>
            <LocalNotifications />
          </IntlProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default withStyles(styles)(withRouter(DevApp));
