import {Button, Dialog, DialogActions, DialogTitle, Grid, Typography} from '@mui/material';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import PageContainer from '../../../common/PageContainer';
import ChatBubble from '../../../shared/ChatBubble';
import withStyles from "@mui/styles/withStyles";
import InsuranceContext from "../context";
import {Component} from "react";
import {
    DefaultDisconnectedPageFooter,
    ScrollableBox,
    ScrollableQuinnContainer,
    ScrollableWrapper
} from "../../../common/ScrollableContainer";
import {withRouter} from "react-router-dom";
import DecodedButton, {DecodedSecondaryButton} from "../../../common/DecodedButton";
import DecodedComponent from "../../../shared/DecodedComponent";
import {routeUtil} from "../../../../utils/route.name";
import {globalBloc} from "../../../global.bloc";


const styles = (theme) => ({
    root: {},
});


class InsuranceChoice extends DecodedComponent {

    constructor(props) {
        super(props);
        this.bloc = props.bloc;
        this.state = {dialogOpen: false};
    }

    handleClose = () => {
        this.setState({dialogOpen: false});
    };

    navigateToSelfPay = () => {
        const {appointmentId} = globalBloc.subject.value;
        this.props.history.push(routeUtil.buildBookingPaymentRouteWithAppointmentID(appointmentId, 'quinn-schedule'));
    };

    handleSelfPay = () => {
        this.setState({dialogOpen: true});
        this.props.selfPay();
    }


    render() {

        let {loading} = this.state;

        return (
            <PageContainer onBack={() => {
                this.props.history.goBack();
            }}>
                <ScrollableQuinnContainer messageId="paymentmethod.choice.bubble"
                                          message="How would you like to pay for this visit?">
                </ScrollableQuinnContainer>
                <DefaultDisconnectedPageFooter justifyContent={"left"}>
                    <Grid item xs={12}>
                        <DecodedButton loading={loading} variant="contained" color="primary"
                                       onClick={this.props.addInsurance}>
                            I have insurance
                        </DecodedButton>
                    </Grid>
                    <Grid item xs={12}>
                        <DecodedButton loading={loading} variant="contained" color="primary"
                                       onClick={this.props.goToFrontDesk}>
                            Unsure
                        </DecodedButton>
                    </Grid>
                    <Grid item xs={12}>
                        <DecodedButton loading={loading} onClick={this.handleSelfPay}>
                            Self pay
                        </DecodedButton>
                    </Grid>
                </DefaultDisconnectedPageFooter>

                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure you want to continue with self pay?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.navigateToSelfPay} variant="contained" color="primary" autoFocus>
                            YES
                        </Button>
                        <Button
                            onClick={this.handleClose}
                            variant="outlined"
                            color="primary"
                            autoFocus
                        >
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>

            </PageContainer>
        );
    }
}

export default withStyles(styles)(withRouter((props) => (
    <InsuranceContext.Consumer>
        {(value) => {
            return <InsuranceChoice context={value} {...props} />;
        }}
    </InsuranceContext.Consumer>
)));
