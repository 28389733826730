import * as React from "react";
import PageContainer from "../common/PageContainer";
import { Button, Grid, Link, Typography } from "@mui/material";
import ChatBubble from "../shared/ChatBubble";
import { makeStyles } from "@mui/styles";
import { useUserSummary } from "../../utils/services/consumers.api";
import {PageContent, PageFooter, ScrollableQuinnContainer} from "../common/ScrollableContainer";
import DecodedButton from "../common/DecodedButton";


const useIntroStepStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1 1 auto',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    skip: {
        color: theme.palette.primary.main,
        fontSize: '1.6rem',
    },
    skipButton: {
        color: theme.palette.accent.main,
        textDecoration: 'underline',
        fontSize: '1.6rem',
    }
}));

export const IntroStep = ({ onScanID, handleBack, userFirstName }) => {
    const classes = useIntroStepStyles();
    const user = useUserSummary();
    return (
        <PageContainer loading={false} onBack={handleBack} hideBack={handleBack === undefined}>
            <ScrollableQuinnContainer messageId="registration.scan.document.intro"
                                      message={`Hi${user ? ` ${user.name.given}`: ` ${userFirstName}`}, It looks like you are a new patient . To start your registration process, can you please scan your driver's license or state issued identification card?`} >
                <PageContent></PageContent>
                <PageFooter>
                    <Grid xs={12} item>
                        <DecodedButton onClick={onScanID}>
                            Scan ID
                        </DecodedButton>
                    </Grid>
                </PageFooter>
            </ScrollableQuinnContainer>
        </PageContainer>
    );
};
