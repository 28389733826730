import React from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../../../shared/DecodedComponent';
import InsuranceContext from '../../context';
import { Button, Grid } from '@mui/material';
import { Wizard } from '../../../../../shared-library';
import PageContainer from '../../../../common/PageContainer';

import PolicyHolderForm from './PolicyHolderForm';
import InsuranceCard from '../InsuranceCard';
import {
  PageContent,
  PageFooter,
  ScrollableQuinnContainer,
} from '../../../../common/ScrollableContainer';
import InsuranceInformation from './InsuranceInformation';
import EnterScanInsurance from '../EnterScanInsurance';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class InsuranceAdd extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = { steps: [] };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  __start = () => {
    const steps = [
      {
        id: 'scan',
        component: <EnterScanInsurance bloc={this.bloc} startWithScan={this.__startWithScan} />,
      },
      {
        id: 'insurance-form',
        component: <InsuranceInformation bloc={this.bloc} />,
      },
      {
        id: 'policy-holder',
        component: <PolicyHolderForm bloc={this.bloc} />,
      },
    ];

    this.bloc.registerSteps(steps);
  };

  __startWithScan = () => {
    const steps = [
      {
        id: 'insurance-card',
        component: <InsuranceCard bloc={this.bloc} />,
      },
      {
        id: 'insurance-form',
        component: <InsuranceInformation bloc={this.bloc} />,
      },
      {
        id: 'policy-holder',
        component: <PolicyHolderForm bloc={this.bloc} />,
      },
    ];

    this.bloc.registerSteps(steps);
  };

  render() {
    const { steps } = this.state;

    if (steps?.length > 0) {
      return <Wizard steps={steps} />;
    }
    return this.__start();
  }
}

export default withStyles(styles)((props) => (
  <InsuranceContext.Consumer>
    {(value) => {
      return <InsuranceAdd context={value} {...props} />;
    }}
  </InsuranceContext.Consumer>
));
