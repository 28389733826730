import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { SpanText } from '../Typography';
import DecodedButton from '../../common/DecodedButton';

const PreDialog = (props) => {
  const { showPreDialog, handleClosePreDialog } = props;
  return (
    <Dialog open={showPreDialog} onClose={handleClosePreDialog}>
      <DialogContent>
        <DialogContentText
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SpanText
            sx={{
              color: 'red',
              fontWeight: 'bold',
            }}
          >
            EMERGENCY PATIENTS
          </SpanText>
          <SpanText>
            If you are experiencing any of the following EMERGENT symptoms please walk-in to any
            of our clinics and go directly to the front desk. If you are experiencing these
            symptoms after business hours please seek emergency help immediately.
          </SpanText>
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
            }}
          >
            <SpanText sx={{ fontWeight: 'bold' }}> • Breathing/Airway Distress</SpanText>
            <SpanText sx={{ fontWeight: 'bold' }}>• Current Chest Pain</SpanText>
            <SpanText sx={{ fontWeight: 'bold' }}>• Concern for Stroke</SpanText>
            <SpanText sx={{ fontWeight: 'bold' }}>• Active Bleeding</SpanText>
            <SpanText sx={{ fontWeight: 'bold' }}>• Severe Pain</SpanText>
            <SpanText sx={{ fontWeight: 'bold' }}>• Broken Bone or Fracture Concern</SpanText>
            <SpanText sx={{ fontWeight: 'bold' }}>• Seizure</SpanText>
            <SpanText sx={{ fontWeight: 'bold' }}>• Unable to Walk</SpanText>
          </span>
          <SpanText>If none of these apply please continue.</SpanText>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <DecodedButton onClick={handleClosePreDialog} color="primary">
          Continue
        </DecodedButton>
      </DialogActions>
    </Dialog>
  );
};

export default PreDialog;
