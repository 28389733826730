import {authService} from '../../utils/auth';
import OrgSelector from './components/OrgSelector';
import TimeSelector from './components/TimeSelector';
import {dateUtil} from '../../utils/date';
import Confirmation from './components/Confirmation';
import {globalBloc} from '../global.bloc';
import WalkinConfirmation from './components/WalkinConfirmation';
import React from 'react';
import {bookingSelectedDate, isTomorrow, showWalkinInstructions} from './bloc/helpers.bloc'

export const remoteSteps = (bloc) => {
  const loggedIn = authService.isLoggedIn();

  if (loggedIn) {
    return [
      {
        id: 'org-selector',
        component: <OrgSelector/>,
        dataKey: 'booking.selectedOrg',
        onSubmit: async () => {
          let step = 'org-selector';
          return bloc.__saveAppointmentProgress(step);
        },
      },
      {
        id: 'time-selector',
        component: <TimeSelector/>,
        message: {
          id: () =>
            isTomorrow(bloc)
              ? 'interaction.appointment.reservation.chatbubble.tomorrow.selecttime'
              : 'interaction.appointment.reservation.chatbubble.selecttime',
          text: () => {
            return isTomorrow(bloc)
              ? `When would you like to come in tomorrow ${dateUtil.formatLocalDateAdjust(
                bookingSelectedDate(bloc),
              )}?`
              : 'When would you like to come in?';
          },
        },
        dataKey: 'booking.selectedSlot',
        onSubmit: async () => {
          const {walkinOnly} = bloc.subject.value;
          if (walkinOnly) {
            showWalkinInstructions(bloc);
            return 'stop';
          }

          let step = 'time-selector';
          return bloc.__saveAppointmentProgress(step);
        },
        submitLabel: () => {
          const {walkinOnly} = bloc.subject.value;
          if (walkinOnly) {
            return 'Walk-In';
          }
          return 'Continue';
        },
      },
      {
        id: 'confirmation',
        component: <Confirmation/>,
        message: {
          id: 'interaction.appointment.reservation.chatbubble.confirm',
          text: 'Thank you. Before we continue, please review and confirm the message below.',
        },
        submitLabel: 'Confirm',
      },
    ];
  } else {
    return [
      {
        id: 'org-selector',
        component: <OrgSelector/>,
        disableBack: true,
        dataKey: 'booking.selectedOrg',
        onSubmit: async () => {
          let step = 'org-selector';
          return bloc.__saveAppointmentProgress(step);
        },
      },
      {
        id: 'time-selector',
        component: <TimeSelector/>,
        message: {
          id: () =>
            isTomorrow(bloc)
              ? 'interaction.appointment.reservation.chatbubble.tomorrow.selecttime'
              : 'interaction.appointment.reservation.chatbubble.selecttime',
          text: () => {
            return isTomorrow(bloc)
              ? `When would you like to come in tomorrow ${dateUtil.formatLocalDateAdjust(
                bookingSelectedDate(bloc),
              )}?`
              : 'When would you like to come in?';
          },
        },
        dataKey: 'booking.selectedSlot',
        onSubmit: async () => {
          const {walkinOnly} = bloc.subject.value;
          if (walkinOnly) {
            showWalkinInstructions(bloc);
            return 'stop';
          }
          let step = 'time-selector';
          return bloc.__saveAppointmentProgress(step);
        },
        submitLabel: () => {
          const {walkinOnly} = bloc.subject.value;
          if (walkinOnly) {
            return 'Walk-In';
          }
          return 'Continue';
        },
      },
    ];
  }
};

export const walkinSteps = (bloc) => {
  const service = bloc.subject.value.appointment?.service;
  if (service === 'FAT-UC') {
    return [
      {
        id: 'booking-confirmation',
        message: {
          id: 'interaction.appointment.walkin.fat-uc.chatbubble.selectdate',
          text: 'We are almost there. Just review the information below and confirm to reserve your place in line with a Fast Track Provider.',
        },
        component: <WalkinConfirmation/>,
        onSubmit: () => bloc.confirmWalkin(),
        submitLabel: () => {
          const {booking} = bloc.subject.value;

          if (!booking.selectedSlot) {
            return 'End';
          }

          if (booking.locked) {
            return booking.error ? 'End' : 'Confirm';
          }

          return 'Loading';
        },
      },
    ];
  }

  return [
    {
      id: 'booking-confirmation',
      message: {
        id: 'interaction.appointment.walkin.chatbubble.selectdate',
        text: 'We are almost there. Just review the below and confirm to reserve your place in line.',
      },
      component: <WalkinConfirmation/>,
      onSubmit: () => bloc.confirmWalkin(),
      submitLabel: () => {
        return 'Confirm';
      },
    },
  ];
};
