import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import Loading from '../shared/Loading';
// import UserAuth from './UserAuth';
import PinCode from './PinCode';
import { CheckSession, SetSession, uriStorage } from '../../utils/storage';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';
import Welcome from '../Login/Welcome';
import { logger } from '../../utils/logging';
import UserAuthNew from './UserAuth/UserAuthNew';
import { LoginDataProvider } from './LoginForm/LoginDataProvider';
import { BOOKING_LIST_ROUTE, QUINN_ROUTE, ROOT_ROUTE } from '../../utils/route.name';
import { globalBloc } from '../global.bloc';

const styles = (theme) => ({
  versionTitle: {
    marginBottom: '2.2rem',
    fontStyle: 'normal',
  },
  loginTitle: {
    marginTop: '2rem',
    marginBottom: '0.1rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '54px',
    '& span': {
      color: theme.palette.primary.main,
    },
  },
});

const initState = {
  loading: false,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  code: '+1',
  codeCountry: 'US',
  number: '',
  ssn: '',
  acceptLocationTerm: false,
  activeStep: 0,
  verificationSuccess: false,
  errorMessage: '',
  pinCode: '',
};

class Login extends Component {
  constructor(props) {
    super(props);
    const action = sessionStorage.getItem('action');
    this.state = {
      action: action,
      ...initState,
    };
  }

  componentDidMount() {
    logger.debug('Login -> componentDidMount');
    if (authService.isLoading()) {
      authService.registerLoadedCallback(this._loaded);
    } else {
      this._loaded();
    }
    providerStorage.setCurrentProvider(this.props.location.search);
  }

  //TODO: Move to a loading/initialisation page.
  _loaded = () => {
    logger.debug('Login -> _loaded');
    let { location } = this.props;
    if (
      location &&
      location.state &&
      location.state.redirectStep &&
      authService.getUser() === null
    ) {
      this.setState({
        activeStep: location.state.redirectStep,
        redirectReason: location.state.redirectReason,
      });
    }
    if (authService.getUser() === null) {
      this.setState({
        loading: false,
      });
    }

    //?--> causing issue, momentarily singling out <--//
    // else if (authService.getUser() && !CheckSession('task', 'post-register')) {
    //   logger.info('might be a bug here pushing user to booking from log in');
    //   this.props.history.push(BOOKING_LIST_ROUTE);
    // }
    else if (CheckSession('task', 'post-register')) {
      this.props.history.push(QUINN_ROUTE);
    } else {
      this.props.history.push(BOOKING_LIST_ROUTE);
    }
  };

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleBackToLast = () => {
    this.props.history.goBack();
  };

  handleTextChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  handleCodeChange = (code, codeCountry) => {
    this.setState({ code, codeCountry, number: '' });
  };

  handleDateChange = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  setLoadingStatus = (value) => {
    this.setState({
      loading: value,
    });
  };

  setConfirmationResult = (value) => {
    this.setState({
      confirmationResult: value,
    });
  };

  getStepContent = (step) => {
    const { action } = this.state;

    if (
      action === 'registration.only' ||
      action === 'manage-appointments' ||
      action === 'login_after_setting_org' ||
      CheckSession('task', 'confirm_booking') //?--> this is to allow getinLine patients to login
    ) {
      logger.info('LOGIN_ROUTE sign in only');
      switch (step) {
        case 0:
          return (
            <LoginDataProvider>
              <UserAuthNew
                {...this.state}
                history={this.props.history}
                handleNext={this.handleNext}
                onCodeChange={this.handleCodeChange}
                handleTextChange={this.handleTextChange}
                setLoadingStatus={this.setLoadingStatus}
                setConfirmationResult={this.setConfirmationResult}
                handleBack={this.handleBackToLast}
              />
            </LoginDataProvider>
          );

        default:
          throw new Error('Unknown step');
      }
    }

    switch (step) {
      case 0:
        return (
          <Welcome
            location={this.props.location}
            acceptLocationTerm={this.state.acceptLocationTerm}
            handleNext={this.handleNext}
            handleCheckboxChange={this.handleCheckboxChange}
          />
        );
      case 1:
        return (
          <LoginDataProvider>
            <UserAuthNew
              {...this.state}
              history={this.props.history}
              handleNext={this.handleNext}
              handleBack={this.handleBack}
              onCodeChange={this.handleCodeChange}
              handleTextChange={this.handleTextChange}
              setLoadingStatus={this.setLoadingStatus}
              setConfirmationResult={this.setConfirmationResult}
            />
          </LoginDataProvider>
        );

      default:
        throw new Error('Unknown step');
    }
  };

  isRedirectedToNext = () => {
    return this.props.location.state && uriStorage.getCurrentPath() != null;
  };

  render() {
    let { activeStep } = this.state;
    if (this.isRedirectedToNext()) {
      return <Loading />;
    }

    return this.getStepContent(activeStep);
  }
}

export default withStyles(styles)(Login);
