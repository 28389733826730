import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router-dom';
import PageContainer from '../common/PageContainer';
import { Grid, styled } from '@mui/material';
import DecodedComponent from '../shared/DecodedComponent';
import { Bloc } from './bloc';
import { ScrollableQuinnContainer } from '../common/ScrollableContainer';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import {
  H1TextTitleContent,
  H1TextTitleSecondary,
  H2TextTitle,
  H2TextTitleSecondary,
  H2TextTitleSecondaryContent,
} from '../shared/Typography';
import Loading from '../../shared-library/components/Loading';
import { dateUtil } from '../../utils/date';

const styles = (theme) => ({
  grid: {
    flex: '1',
    paddingTop: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '1em',
    marginLeft: '1em',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    },
  },
  textItem: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  checkinText: {
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'center',
    color: '#71675E',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingTop: '3em',
    },
  },
  estimationText: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    color: '#71675E',
    paddingTop: '2.4em',
    paddingBottom: '3em',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingBottom: '1.5em',
      paddingTop: 0,
    },
  },
  phoneNumberText: {
    color: '#71675E',
    fontWeight: '500',
    fontSize: 18,
    textAlign: 'center',
    paddingBottom: '2.3em',
    flexDirection: 'row',
    alignContent: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingBottom: '3em',
    },
  },
  phoneNumber: {
    color: theme.palette.primary.main,
  },
  noteText: {
    fontWeight: '550',
    fontSize: 14,
    textAlign: 'center',
    color: '#1A0C00',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      marginTop: '4em',
    },
  },
  waitingTime: {
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  queueNumber: {
    fontWeight: '700',
    color: theme.palette.primary.main,
    fontSize: '10em',
  },
  directionLink: {
    fontSize: '1.2em',
    color: '#fffff',
  },
  directionLinkText: {
    fontSize: '1.3em',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkBox: {
    fontWeight: 700,
    textDecoration: 'none',
    paddingBottom: '5em',
    paddingTop: '3em',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '4em',
    },
  },
  icon: {
    width: '1.8em',
    paddingRight: '0.3em',
  },
  homeLink: {
    fontSize: '2em',
    fontWeight: '1',
  },
  progress: {
    color: theme.palette.secondary.main,
  },
  cancelLinkText: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  getDirection: {
    flexDirection: 'row',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  getDirectionIcon: {
    marginRight: 10,
  },
  phoneNumberMargin: {
    margin: 0,
  },
});

const initState = {
  initialising: true,
};

class CheckinConfirmation extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = initState;
    analyticsEventLogger.log(AnalyticsEvent.CHECKIN_SUCCESS, {});
    this.bloc = new Bloc(this.__currentAppointmentId());
  }



  __currentAppointmentId = () => {
    return this.props.match.params.appointmentId
      ? this.props.match.params.appointmentId
      : this.props.appointmentId;
  };

  render() {
    const { initialising, queueNumber, madePayment, startTime } = this.state;

    if (!madePayment) {
      return this.__renderGeneral();
    }

    return (
      <PageContainer loading={initialising}>
        <ScrollableQuinnContainer
          messageId="checkin.postpayment.confirmation.bubble"
          message={`Please take a seat and the staff will call you as soon as they are ready for you.`}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ margin: '12px 0' }}>
              <H1TextTitleSecondary sx={{ textAlign: 'center' }}>Thank you!</H1TextTitleSecondary>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitle sx={{ textAlign: 'center' }}>
                The staff has been notified of your arrival.
              </H2TextTitle>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitleSecondary sx={{ textAlign: 'center' }}>
                Your reservation time
              </H2TextTitleSecondary>
            </Grid>
            <Grid item xs={12}>
              <H1TextTitleSecondary sx={{ textAlign: 'center' }}>
                {dateUtil.formatTimeDisplay(startTime)}
              </H1TextTitleSecondary>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitleSecondary sx={{ textAlign: 'center' }}>
                <FormattedMarkdown
                  id={'appointment.checkin.success.note'}
                  defaultMessage={
                    'IF YOU WERE UNABLE TO UPLOAD YOUR INSURANCE CARD, PLEASE BRING IT TO THE FRONT DESK TO COMPLETE YOUR CHECK-IN'
                  }
                />
              </H2TextTitleSecondary>

              <H2TextTitle sx={{ textAlign: 'center' }}>
                <FormattedMarkdown
                  id={'appointment.checkin.success.note'}
                  defaultMessage={
                    'PLEASE NOTIFY THE FRONT DESK IF YOU ARE - LEAVING THE LOBBY - RETURNING LATER FOR YOUR VISIT'
                  }
                />
              </H2TextTitle>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }

  __renderGeneral = () => {
    const { initialising, queueNumber, appointment, messageId, message, startTime } = this.state;

    if (initialising) {
      return (
        <PageContainer loading={initialising}>
          <ScrollableQuinnContainer
            messageId="checkin.general.loading.bubble"
            message={`Please wait while I complete your check-in.`}
          >
            <Loading isFullscreen={false} />
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    if (messageId) {
      return (
        <PageContainer loading={initialising}>
          <ScrollableQuinnContainer
            messageId="checkin.general.error.bubble"
            message={`Check-in unsuccessful. Please see the front desk to complete your check in.`}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <H2TextTitleSecondary sx={{ textAlign: 'center' }}>
                  <FormattedMarkdown id={messageId} defaultMessage={message} />
                </H2TextTitleSecondary>
              </Grid>
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    const service = appointment.service;
    if (service === 'FAT-UC') {
      return (
        <PageContainer loading={initialising}>
          <ScrollableQuinnContainer
            messageId="checkin.fat-uc.confirmation.bubble"
            message={`Check-in for Fast Track successful.  Please take a seat and the staff will notify you as soon as they are ready for you.`}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ margin: '12px 0' }}>
                <H1TextTitleSecondary sx={{ textAlign: 'center' }}>Thank you!</H1TextTitleSecondary>
              </Grid>
              <Grid item xs={12}>
                <H2TextTitle sx={{ textAlign: 'center' }}>
                  The staff has been notified of your arrival.
                </H2TextTitle>
              </Grid>
              <Grid item xs={12}>
                <H2TextTitleSecondary sx={{ textAlign: 'center' }}>
                  Your reservation time
                </H2TextTitleSecondary>
              </Grid>
              <Grid item xs={12}>
                <H1TextTitleSecondary sx={{ textAlign: 'center' }}>
                  {dateUtil.formatTimeDisplay(startTime)}
                </H1TextTitleSecondary>
              </Grid>
              <Grid item xs={12}>
                <H2TextTitleSecondary sx={{ textAlign: 'center' }}>
                  <FormattedMarkdown
                    id={'appointment.checkin.success.note'}
                    defaultMessage={
                      'IF YOU WERE UNABLE TO UPLOAD YOUR INSURANCE CARD, PLEASE BRING IT TO THE FRONT DESK TO COMPLETE YOUR CHECK-IN'
                    }
                  />
                </H2TextTitleSecondary>

                <H2TextTitle sx={{ textAlign: 'center' }}>
                  <FormattedMarkdown
                    id={'appointment.checkin.success.note'}
                    defaultMessage={
                      'PLEASE NOTIFY THE FRONT DESK IF YOU ARE - LEAVING THE LOBBY - RETURNING LATER FOR YOUR VISIT'
                    }
                  />
                </H2TextTitle>
              </Grid>
            </Grid>
          </ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    return (
      <PageContainer loading={initialising}>
        <ScrollableQuinnContainer
          messageId="checkin.general.confirmation.bubble"
          message={`Check-in successful. Please take a seat and the staff will call you as soon as they are ready for you.`}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ margin: '12px 0' }}>
              <H1TextTitleSecondary sx={{ textAlign: 'center' }}>Thank you!</H1TextTitleSecondary>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitle sx={{ textAlign: 'center' }}>
                The staff has been notified of your arrival.
              </H2TextTitle>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitleSecondary sx={{ textAlign: 'center' }}>
                Your reservation time
              </H2TextTitleSecondary>
            </Grid>
            <Grid item xs={12}>
              <H1TextTitleSecondary sx={{ textAlign: 'center' }}>
                {dateUtil.formatTimeDisplay(startTime)}
              </H1TextTitleSecondary>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitleSecondary sx={{ textAlign: 'center' }}>
                <FormattedMarkdown
                  id={'appointment.checkin.success.note'}
                  defaultMessage={
                    'IF YOU WERE UNABLE TO UPLOAD YOUR INSURANCE CARD, PLEASE BRING IT TO THE FRONT DESK TO COMPLETE YOUR CHECK-IN'
                  }
                />
              </H2TextTitleSecondary>

              <H2TextTitle sx={{ textAlign: 'center' }}>
                <FormattedMarkdown
                  id={'appointment.checkin.success.note'}
                  defaultMessage={
                    'PLEASE NOTIFY THE FRONT DESK IF YOU ARE - LEAVING THE LOBBY - RETURNING LATER FOR YOUR VISIT'
                  }
                />
              </H2TextTitle>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  };
}

export default withStyles(styles)(withRouter(CheckinConfirmation));
