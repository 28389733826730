import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const LogoutDialog = (props) => {
  const { logoutDialogOpen = false, cancelLogoutDialog, doLogout } = props;
  return (
    <Dialog
      open={logoutDialogOpen}
      onClose={cancelLogoutDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to end the Check-In process and Log Out?
      </DialogTitle>
      <DialogActions>
        <Button onClick={doLogout} variant="contained" color="error" autoFocus>
          YES
        </Button>
        <Button
          onClick={cancelLogoutDialog}
          variant="outlined"
          color="primary"
          autoFocus
        >
          NO
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
