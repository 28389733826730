import React from 'react';
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";

const SessionDialog = (props) => {
    const {dialogOpen, setDialogOpen} = props;


    const handleClearSession = () => {
        sessionStorage.removeItem('savedSessionId')
        setDialogOpen(false)
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
               An Intake session is already in progress. Would you like to start a new session?
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClearSession} variant="contained" color="primary" autoFocus>
                    YES
                </Button>
                <Button
                    onClick={() => setDialogOpen(false)}
                    variant="outlined"
                    color="primary"
                    autoFocus
                >
                    NO
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SessionDialog;
