import * as rxjs from 'rxjs';
import {notificationService} from '../../../utils/notification';
import {userInfoUtil} from '../../../utils/user';
import {phoneUtil} from '../../../utils/phone';

import {loadStripe} from '@stripe/stripe-js';
import {loadStripeTerminal} from '@stripe/terminal-js';
import {providerStorage} from '../../../utils/provider.qs';
import {appointmentApi} from '../../../utils/services/appointments.api';
import {uriStorage} from '../../../utils/storage';
import {AnalyticsEvent} from '../../../utils/events';
import {FormattedMessage} from 'react-intl';
import * as React from 'react';
import {paymentApi} from '../../../utils/services/payment.api';

const initial = {
  initialised: false,
};

export class Bloc {
  callback;

  codesetApi;
  insuranceApi;
  paymentApi;

  constructor(props, callback, onBack) {
    this.codesetApi = props.codesetApi;
    this.insuranceApi = props.insuranceApi;
    this.paymentApi = props.paymentApi;
    this.appointmentApi = props.appointmentApi;

    this.subject = new rxjs.BehaviorSubject({
      ...initial,
      ...props,
      mode: 'standard',
      // providerStorage.isKiosk() && providerStorage.getPersistedTerminalLocation() !== ''
      //     ? 'terminal'
      //     : 'standard',
      terminalId: providerStorage.getCurrentTerminal(),
      terminalLocation: '534d2f62-52f5-11eb-a8eb-42010a924011',
    });

    this.events = new rxjs.Subject();

    this.callback = callback;
    this.onBack = onBack;
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  __publishEvent = (type, data) =>
    this.events.next({
      type: type,
      data: data,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = async () => {
    const {appointment, amount, accountAmount, visitAmount, mode} = this.subject.value;

    // this.appointmentApi
    //   .paymentCommand(appointment.id, {
    //     payload: {
    //       command: 'create_payment_intent',
    //       mode: mode,
    //       amount: (amount * 100).toFixed(0),
    //       requestId: appointment.id + '-' + new Date().getTime(),
    //       items: [
    //         {
    //           code: 'visitcost',
    //           amount: (visitAmount * 100).toFixed(0),
    //         },
    //         {
    //           code: 'accountamount',
    //           amount: (accountAmount * 100).toFixed(0),
    //         },
    //       ],
    //     },
    //   })
    //   .then(
    //     (data) => {
          this.__updateSubject({
            stripePromise: loadStripe(process.env.REACT_APP_PAYMENT_STRIPE_PUBLISHABLE_KEY),
            // stripeOptions: {
            //   clientSecret: data.data.clientSecret,
            // },
            // terminalToken: data.data.terminalToken,
            initialised: true,
          });
      //   },
      //   (reason) => {
      //     this.__updateSubject({error: true});
      //     notificationService.httpError(reason);
      //   },
      // )
      // .finally(() => {
      //   this.__updateSubject({initialised: true});
      // });
  };

  __terminalToken = async () => {
    const {appointment} = this.subject.value;

    return this.appointmentApi
      .paymentCommand(appointment.id, {
        payload: {
          command: 'create_terminal_connection_token',
        },
      })
      .then(
        (value) => value.data.terminalToken,
        (reason) => notificationService.error('Error connecting to terminal - ' + reason.message),
      );
  };

  __initializeBackendClientAndTerminal = async () => {
    const StripeTerminal = await loadStripeTerminal();

    // 1b. Initialize the StripeTerminal object
    this.terminal = StripeTerminal.create({
      // 1c. Create a callback that retrieves a new ConnectionToken from the example backend
      onFetchConnectionToken: async () => this.__terminalToken(),
      // // 1c. (Optional) Create a callback that will be called if the reader unexpectedly disconnects.
      // // You can use this callback to alert your user that the reader is no longer connected and will need to be reconnected.
      onUnexpectedReaderDisconnect: (event) => {
        console.log(event);
      },
      // // 1c. (Optional) Create a callback that will be called when the reader's connection status changes.
      // // You can use this callback to update your UI with the reader's connection status.
      // onConnectionStatusChange: Logger.tracedFn(
      //     "onConnectionStatusChange",
      //     "https://stripe.com/docs/terminal/js-api-reference#stripeterminal-create",
      //     ev => {
      //       this.setState({ connectionStatus: ev.status, reader: null });
      //     }
      // )
    });
  };

  resetTerminal = () => {
    this.__updateSubject({terminalState: 'error'});
  };

  __connectToReader = async () => {
    const {terminalId, terminalLocation} = this.subject.value;

    const config = {
      simulated: process.env.REACT_APP_PAYMENT_STRIPE_TERMINAL_SIMULATED === '1',
      location: terminalLocation,
    };

    if (config.simulated) {
      this.terminal.setSimulatorConfiguration({testCardNumber: '4242424242424242'});
      // this.terminal.setSimulatorConfiguration({ testCardNumber: '4242424242424241' });
    }
    const discoverResult = await this.terminal.discoverReaders(config);
    if (discoverResult.error) {
      notificationService.error('Failed to discover: ', discoverResult.error);
      return {error: discoverResult.error};
    } else if (discoverResult.discoveredReaders.length === 0) {
      notificationService.error('No available readers.');
      return {error: 'No available readers.'};
    } else {
      const selectedReader = discoverResult.discoveredReaders.find(
        (reader) => reader.id === terminalId,
      );
      const connectResult = await this.terminal.connectReader(selectedReader);
      let props = {};
      if (connectResult.error) {
        notificationService.error('Failed to connect:', connectResult.error);
        return {error: connectResult.error};
      } else {
        props.terminalState = 'connected';
      }

      this.__updateSubject({selectedReader: selectedReader, ...props});
      return {};
    }
  };

  cancelPayment = async () => {
    const response = await this.terminal.cancelCollectPaymentMethod();
    if (response.error) {
      notificationService.error('Error cancelling response. Please try again.');
    } else {
      this.__updateSubject({terminalState: ''});
    }
  };

  collectTerminalPayment = async () => {
    const {appointment, stripeOptions} = this.subject.value;
    await this.__initializeBackendClientAndTerminal();
    await this.__connectToReader();
    const paymentMethod = await this.terminal.collectPaymentMethod(stripeOptions.clientSecret);
    if (paymentMethod.error) {
      notificationService.error('Error collecting payment - ' + paymentMethod.error.message);
    } else {
      // Placeholder for processing result.paymentIntent
      const result = await this.terminal.processPayment(paymentMethod.paymentIntent);
      if (result.error) {
        // Placeholder for handling result.error
        notificationService.error('Error confirming payment - ' + result.error.message);
        this.logAnalyticsEvent(AnalyticsEvent.PAYMENT_ERROR, {
          appointment: appointment.id,
          message: result.error.message,
        });
        this.__updateSubject({terminalState: 'error'});
      } else if (result.paymentIntent) {
        this.__updateSubject({terminalState: 'success'});
        try {
          await this.__recordTransaction(result.paymentIntent);
        } catch (e) {
        } finally {
          this.accept();
        }
      } else {
        this.accept();
      }
    }
  };

  __recordTransaction = (paymentIntent) => {
    const {appointment} = this.subject.value;

    const request = {
      vendor: 'stripe',
      payload: {
        requestId: paymentIntent.id,
        vendor: 'stripe',
        amount: paymentIntent.amount_received,
        reference: paymentIntent.id,
        command: 'record_payment',
        detail: {
          appointment: appointment.id,
          vendor: 'stripe',
          reference: paymentIntent.id,
          stripe: {
            paymentIntent: paymentIntent.id,
          },
        },
      },
    };

    return appointmentApi.paymentCommand(appointment.id, request);
  };

  isVirtual = () => {
    const {appointment} = this.subject.value;

    return appointment?.type === 'VIRTUAL';
  };

  closeAccept = () => {
    this.__updateSubject({confirm: false});
  };
  accept = () => {
    this.callback();
  };

  end = () => {
    const {appointment} = this.subject.value;

    this.callback('exception');
    return paymentApi.skipPayment(appointment.id);
  };

  close = () => {
  };
}

export class Constants {
}

export class BlocEvent {
}
