import { Box, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { providerUtil } from '../../../utils/provider';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { serviceUtil } from '../../../utils/service';
import { TextMediumStandard } from '../Typography';
import { dateUtil } from '../../../utils/date';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { logger } from '../../../utils/logging';
import { appointmentApi } from '../../../utils/services/appointments.api';
import {providerStorage} from "../../../utils/provider.qs";
import {AppointmentConstants} from "../../../const";

const useStyles = makeStyles((theme) => ({
  fill: {
    flex: '1 1 auto',
  },
  card: {
    width: '320px',
    minWidth: '320px',
    maxWidth: '320px',
    margin: '12px 24px 12px 0',
    backgroundColor: '#F6F6F6',
    border: '0 solid #ffffff',
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '85vw',
      minWidth: '85vw',
      margin: '0',
    },
  },

  cardHeader: {},

  cardHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#000000',
  },

  cardHeaderTitleSubtitle: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '32px',
    color: '#777777',
  },
  cardSelectedHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBody: {
    paddingTop: '24px',
    paddingBottom: '6px !important',
  },

  cardBodyLine: {
    display: 'flex',
    marginBottom: '14px',
  },

  cardBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
  },
  cardSelectedBodyLineIcon: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
  },
  cardBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#5C5D5F',
    paddingLeft: '18px',
  },
  cardSelectedBodyLineText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFF',
    paddingLeft: '18px',
  },

  buttonHolder: {
    marginTop: '18px',
  },
  button: {
    [theme.breakpoints.up('xs')]: {
      width: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  iconLarge: {
    fontSize: '18px',
  },
  iconLargeWhite: {
    fontSize: '18px',
    color: '#fff',
  },
  documents: {
    paddingTop: '14px',
    paddingBottom: '14px',
    lineHeight: '14px',
  },
  checkBox: {
    transform: 'scale(1.5)',
    marginRight: '1em',
    marginLeft: '0.5em',
  },
  locationTerm: {
    color: theme.palette.primary.main,
  },
}));

export const AppointmentCard = ({ appointment, organisation, controls }) => {
  const classes = useStyles();
  const serviceName = serviceUtil.friendlyNameSync(appointment.service);

  const [state, setState] = React.useState({
    start: null,
    getInLine: false,
    renderGetInLineFeatures: false,
    isKiosk: providerStorage.isKiosk(),
  });

  useEffect(() => {
    Promise.all([
      appointmentApi.getAppointmentStatus(appointment.id),
      appointmentApi.getAppointmentQueueStatus(appointment.id),
    ]).then((response) => {
      const waitTimeInMinutes = response[1].data.waitTime;
      const projectedStartTime = new Date(
        new Date(response[0]?.data?.start).getTime() + waitTimeInMinutes * 60000,
      );

      const start = dateUtil.formatTimeDisplayAlt(projectedStartTime.toString());
      setState((prevState) => ({
        ...prevState,
        start: start,
      }));
      if (response[0].data?.service === AppointmentConstants.DEFAULT_SERVICE) {
        currentSpotInLine();
      }
    });
  }, [appointment]);

  const currentSpotInLine = () => {
    appointmentApi
      .getAppointmentQueueStatus(appointment.id)
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          positionInQueue: response?.data?.positionInQueue,
          currentWaitTime: response?.data?.waitTime,
          renderGetInLineFeatures: true,
        }));
      })
      .catch((error) => {
        logger.info('Error fetching appointment status:', error);
      });
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        classes={{
          title: classes.cardHeaderTitle,
          subheader: classes.cardHeaderTitleSubtitle,
        }}
        className={classes.cardHeader}
        title={serviceName}
        subheader={`at ${organisation?.name}`}
      />
      <CardContent className={classes.cardBody}>
        <div className={classes.cardBodyLine}>
          <span className={classes.cardBodyLineIcon}>
            <LocationOnIcon
              classes={{
                fontSizeLarge: classes.iconLarge,
              }}
              fontSize={'large'}
              color={'primary'}
            />
          </span>
          <TextMediumStandard className={classes.cardBodyLineText}>
            {appointment.type === 'VIRTUAL'
              ? 'Virtual'
              : providerUtil.formatOrganisationAddress(organisation)}
          </TextMediumStandard>
        </div>
        <div className={classes.cardBodyLine}>
          <span className={classes.cardBodyLineIcon}>
            <QueryBuilderIcon
              classes={{
                fontSizeLarge: classes.iconLarge,
              }}
              fontSize={'large'}
              color={'primary'}
            />
          </span>
          {state.renderGetInLineFeatures ? (
            <Box
              gap={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <Typography className={classes.cardBodyLineText}>
                You are number {state.positionInQueue} in line
              </Typography>
              <Typography className={classes.cardBodyLineText}>
                Estimated wait time - {state.currentWaitTime} min
              </Typography>
              {!state.isKiosk &&
                <Typography fontWeight="600">
                Please arrive by {state.start} to avoid losing your place in line.
              </Typography>
              }
            </Box>
          ) : (
            <TextMediumStandard className={classes.cardBodyLineText}>
              {state.start} {dateUtil.getUserTimeZoneAbbreviation()}
            </TextMediumStandard>
          )}
        </div>
        <div className={classes.cardBodyLine}></div>
      </CardContent>
      {controls && <CardActions>{controls}</CardActions>}
    </Card>
  );
};
