import React, {useEffect, useRef, useState} from 'react'
import {userInfoUtil} from "../../utils/user";
import {keyframes, styled} from "@mui/system";
import {Box, Button, Grid} from "@mui/material";
import {registrationApi} from "../../utils/services/register.api";
import {authService} from "../../utils/auth";
import {notificationService} from "../../utils/notification";
import ProfileDialog from "./components/ProfileDialog";
import {DecodedChat} from "@decodedhealth/react-component-lib";
import {assistantApi, AssistantApi} from "../../utils/services/assistant.api";
import {useHistory, useParams} from "react-router-dom";
import {AnalyticsEvent, analyticsEventLogger} from "../../utils/events";
import {linksApi} from "../../utils/services/links.api";
import {appointmentApi} from "../../utils/services/appointments.api";
import {uriStorage} from "../../utils/storage";
import {providerStorage} from "../../utils/provider.qs";
import Loading from "../shared/Loading";
import ErrorScreen from "./components/Error";
import defaultTheme from "./theme";
import PageContainer from "../common/PageContainer";
import ScrollableContainer, {
    DefaultDisconnectedPageFooter,
    ScrollableQuinnContainer
} from "../common/ScrollableContainer";
import {logger} from "../../utils/logging";
import {globalBloc, GlobalBlocEvent} from "../global.bloc";
import SessionDialog from "./components/SessionDialog";
import { routeUtil} from "../../utils/route.name";

// http://localhost:3000/link/intake/123/345/pass


const avatar = 'https://storage.googleapis.com/dh-web-asssets/assistant/quinn.png'


const StartIntakeButton = styled(Button)(({theme, animate}) => ({
    padding: '10px 20px',
    backgroundColor: '#003261',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
}))

const Intake = () => {
    const [state, setState] = useState({
        loading: false,
        message: 'Processing link.',
        error: null,
        token: null,
        // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb25zdW1lci1hcGkiLCJhdWQiOiJhc3Npc3RhbnQtYXBpIiwiZGhfc3ViIjoiOGFhMWMxOWMtOTFiZC00ZDkwLTlhYjAtNmM5NDk5Y2E1MzE4IiwiZGhfdG50IjoiZGVtbyIsInN1YiI6IjhhYTFjMTljLTkxYmQtNGQ5MC05YWIwLTZjOTQ5OWNhNTMxOCIsImlhdCI6MTcxMjY3MDM1NCwiZXhwIjoxNzQ0MjA2MzU0LCJlbWFpbCI6IjhhYTFjMTljLTkxYmQtNGQ5MC05YWIwLTZjOTQ5OWNhNTMxOEBkZWNvZGVkLmNvbSJ9.WDt8hoozsHAMVtUwJOfBumal5BeJY6lm8y1WSHqnZbc',
        showChat: false,
    })

    const [dialogOpen, setDialogOpen] = useState(false)
    const params = useParams()
    const history = useHistory()

    const appointmentId = params.appointmentId

    useEffect(() => {
        globalBloc.updateGlobalBloc({showBanner: false}); // hide 'emergency' banner
        sessionStorage.removeItem('chatHistory')

        if (sessionStorage.getItem('savedSessionId')) {
            setDialogOpen(true)
        }
        initialise(params)
    }, [])

    const initialise = (params) => {
        analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_OPEN, {
            appointmentId: params.appointmentId,
        });

        linksApi
            .authenticateLink('intake.link.authentication', params)
            .then((value) => authenticateUser(params, value))
            .catch((reason) => {
                analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_ERROR, {
                    appointmentId: params.appointmentId, reason: `${reason}`,
                });


                setState((prevState) => ({
                    ...prevState, loading: false, error: reason,
                }))
            });
    };

    const authenticateUser = (params, value) => {
        const appointmentId = params.appointmentId;

        analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_VALID, {
            appointmentId: params.appointmentId,
        });
        // setState((prevState) => ({
        //     ...prevState,
        //     message: 'Almost done.',
        //     token: value.data?.token,
        // }))

        authService
            .loginWithToken(value.data.token)
            .then((user) => {
                analyticsEventLogger.setUser(user.uid);
                assistantApi
                    .generateToken().then((value) => {
                    setState((prevState) => ({
                        ...prevState,
                        message: 'Almost done.',
                        token: value.data?.token,
                    }))

                })
                globalBloc.events.next(new GlobalBlocEvent(GlobalBlocEvent.FETCH_STATUS, appointmentId));

                user.getIdToken()
                    .then((token) => {
                        analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_AUTHENTICATED, {
                            appointmentId: appointmentId,
                        });

                        setState((prevState) => ({
                            ...prevState, message: 'Checking appointment.', loading: false,
                        }))


                        appointmentApi.getAppointmentStatus(appointmentId).then((result) => {
                            analyticsEventLogger.log(AnalyticsEvent.BOOKING_STATUS_RETRIEVAL_SUCCESS);
                            if (result.data.status === 'COMPLETE') {
                                authService.logout().then(() => {
                                    uriStorage.clearPath();
                                    providerStorage.clearProvider();
                                });

                                setState((prevState) => ({
                                    ...prevState,
                                    message: 'Link invalid.',
                                    error: 'Appointment link expired.',
                                    loading: false,
                                }))

                            } else {

                                setState((prevState) => ({
                                    ...prevState, message: 'Redirecting...', loading: false,
                                }))
                            }
                        });
                    })
                    .catch((reason) => authenticationError(appointmentId, reason));
            })
            .catch((reason) => authenticationError(appointmentId, reason));
    };

    const authenticationError = (reason) => {
        analyticsEventLogger.log(AnalyticsEvent.LINK_CHECKIN_ERROR, {
            reason: `${reason}`,
        });
        setState((prevState) => ({
            ...prevState, loading: false, error: reason,
        }))

    };

    const handleStartChatClick = () => {
        setState((prevState) => ({
            ...prevState,
            showChat: true,
        }))
    }

    const onCompleted = () => {
        globalBloc.updateGlobalBloc({intakeComplete: true})
        setTimeout(() => {
            history.push(
                routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
                    appointmentId,
                    'IN_PERSON',
                ),
            )
        }, 5000)
    }

    const formApiFunction = (form) => {
        return new Promise((resolve, reject) => {
            logger.debug('formApiFunction passed from  parent:', form)
            resolve('Mock result')
            //   reject('Mock error')
        })
    }


    if (!state.loading && state.error) {
        return <ErrorScreen/>
    }

    if (state.loading || !state.token) {
        return (
            <Box sx={{
                overflow: 'auto',
                overscrollBehavior: 'contain',
                position: 'absolute',
                minHeight: '100%',
                height: '100%',
                width: '100%',
                color: '#fff',
            }}>
                <Loading message={state.message}/>
            </Box>
        );
    }


    return (

        <PageContainer loading={false}>
            {!state.showChat && (
                <ScrollableQuinnContainer
                    messageId={`link.intake.bubble`}
                    message="Welcome to Decoded Health! Please click the button below to start your intake process with our assistant Quinn."
                >
                </ScrollableQuinnContainer>
            )}
            {state.showChat && (
                <ScrollableContainer>
                    <Box>
                        <DecodedChat
                            botName={'Quinn'}
                            customData={{
                                lang: 'en', location: '', // appointmentType: '',
                            }}
                            socketUrl={process.env.REACT_APP_ASSISTANT_NEXT_URL}
                            socketPath={process.env.REACT_APP_ASSISTANT_NEXT_PATH}
                            socketTransport={['websocket']}
                            avatar={avatar}
                            token={state.token}
                            formApiFunction={formApiFunction}
                            theme={defaultTheme}
                            appointmentId={appointmentId}
                            onCompleted={onCompleted}
                        />
                    </Box>
                </ScrollableContainer>
            )}
            <DefaultDisconnectedPageFooter>
                <Grid
                    xs={12}
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                }}>
                    {!state.showChat && (
                        <StartIntakeButton
                            onClick={handleStartChatClick}>
                            Start Chat
                        </StartIntakeButton>
                    )}
                </Grid>
            </DefaultDisconnectedPageFooter>
            <SessionDialog setDialogOpen={setDialogOpen} dialogOpen={dialogOpen}/>
        </PageContainer>
    )
}

export default Intake
