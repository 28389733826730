import logo from '../assets/full-logo.svg';
import mobileLogo from '../assets/mobile.svg';

export const getLogoBasedOnTenant = () => {
  return logo;
};

export const getMobileLogoBasedOnTenant = () => {
  return mobileLogo;
};

export const getFullMobileLogoBasedOnTenant = () => {
  return logo;
};
