import React from 'react'
import {withRouter} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import { FormControlLabel, Grid} from '@mui/material'
import {ValidatorForm} from 'react-material-ui-form-validator'
import PageContainer from '../../common/PageContainer'
import {FormattedMarkdown} from '@decodedhealth/react-library'
import {Checkbox} from '../../shared/components/Checkbox'
import {withTheme} from '@mui/styles'
import ScrollableContainer, {PageContent, PageFooter} from '../../common/ScrollableContainer'
import {providerStorage} from '../../../utils/provider.qs'
import {H2TextTitleContent, H2TextTitleSecondaryContent} from '../../shared/Typography'
import DecodedButton, {DecodedSubmitButton} from '../../common/DecodedButton'
import DecodedComponent from '../../shared/DecodedComponent'
import {Bloc} from './bloc'
import {authService} from '../../../utils/auth'
import {logger} from '../../../utils/logging'
import LandingOrgSelector from '../components/LandingOrgSelector/LandingOrgSelector'
import KioskWelcome from './KioskWelcome/KioskWelcome'
import {styles} from './styles'

const GridItem = (props) => <Grid {...props} item />



class Welcome extends DecodedComponent {
  constructor(props) {
    super(props)

    const queryParams = new URLSearchParams(props.location.search)

    const isKiosk = providerStorage.isKiosk()
    const isWalkin = queryParams.has('provider') || providerStorage.isWalkin()
    const isLoggedIn = authService.getUser()

    this.bloc = new Bloc({
      isKiosk: isKiosk,
      isWalkin: isWalkin,
      isLoggedIn: isLoggedIn,
      showWalkin: isWalkin,
      onNext: this.props.handleNext,
      props: props,
    })

    this.state = {
      initialising: true,
      showAvailability: true,
      isWalkin: isWalkin,
      isKiosk: isKiosk,
      isLoggedIn: isLoggedIn,
    }
  }

  componentDidMount() {
    logger.debug('Welcome has mounted')
    super.componentDidMount()
    this.bloc.initialise()
  }

  walkin = () => {
    this.bloc.unsetCheckin()
    if (this.state.firstWalkinSlot) {
      this.bloc.showWalkinTime()
      this.props.handleNext()

    } else {
      this.bloc.hideWalkin()
       this.props.handleNext()
    }
  }

  checkin= () => {
    this.bloc.setCheckin()
    this.props.handleNext()
  }

  render() {
    let {
      classes,
      acceptLocationTerm,
      handleNext,
      handleCheckboxChange,
      loading,
      location,
      theme,
    } = this.props

    let {
      isWalkin,
      isKiosk,
      initialising,
      showAvailability,
      organisations,
    } = this.state

    const queryParams = new URLSearchParams(location.search)

    const showCheckbox = !isKiosk && (location.search === '' || !queryParams.has('provider'))

    if (initialising) {
      return (
        <PageContainer isEnd={true} loading={true}>
          <ScrollableContainer applyFormat={true}></ScrollableContainer>
        </PageContainer>
      )
    }

    if (showAvailability && organisations.length !== 0 && !isKiosk && !isWalkin ) {
      return (
        <PageContainer
          isEnd={true}
          loading={false}
          onBack={
            !isWalkin
              ? undefined
              : () => {
                this.bloc.showWalkin()
              }
          }
        >
          {this.__renderOrgSelection(organisations)}
        </PageContainer>
      )
    }

    return (
      <>
        {isKiosk ? (
          <KioskWelcome handleNext={handleNext} walkin={this.walkin} checkin={this.checkin} />
        ) : (
          <PageContainer isEnd={true} loading={loading}>
            <ScrollableContainer applyFormat={true}>
              <PageContent>
                <H2TextTitleContent className={isKiosk ? classes.kioskOpening : classes.opening}>
                  <FormattedMarkdown
                    id="welcome.bubble"
                    defaultMessage="In just a few steps, we can help you find the medical care you need."
                  />
                </H2TextTitleContent>
              </PageContent>
              <PageFooter>
                <ValidatorForm autoComplete="off" onSubmit={handleNext} className={classes.form}>
                  {showCheckbox ? (
                    <FormControlLabel
                      style={{fontSize: '1.2em', textAlign: 'left'}}
                      control={
                        <Checkbox
                          required
                          checked={acceptLocationTerm}
                          onChange={handleCheckboxChange}
                          name="acceptLocationTerm"
                          color="primary"
                          className={classes.checkBox}
                        />
                      }
                      label={
                        <div className={classes.locationTerm}>
                          <FormattedMarkdown
                            id="welcome.location.confirmation"
                            defaultMessage="Please confirm that you will be physically located in the state of California at the time of your visit."
                          />
                        </div>
                      }
                    />
                  ) : null}

                  <div className={classes.button}>
                    <DecodedSubmitButton id={'lets-start-btn'}>Let's start!</DecodedSubmitButton>
                  </div>

                  <div className={classes.warning}>
                    <Typography
                      variant="body1"
                      component={'div'}
                      sx={{
                        color: theme.palette.accent.main,
                      }}
                    >
                      <FormattedMarkdown
                        id="global.instruction.emergency"
                        defaultMessage="In case of a medical emergency, call 911 immediately."
                      />
                    </Typography>
                  </div>
                </ValidatorForm>
              </PageFooter>
            </ScrollableContainer>
          </PageContainer>
        )}
      </>
    )
  }

  __renderOrgSelection = (organisations) => {
      return (
        <>
          <LandingOrgSelector
            organisations={organisations}
            isSelected={true}
            handleNavigation={this.props.handleNavigation}
          />
        </>
      )

  }

  __renderNoAvailability = (organisations) => {
    const {isWalkin } = this.state

    if (isWalkin) {
      return (
        <ScrollableContainer applyFormat={true}>
          <Grid sx={{paddingBottom: '24px'}} spacing={2} container>
            <Grid item direction="column">
              <H2TextTitleContent component={GridItem}>
                <FormattedMarkdown
                  id="interaction.appointment.walkin.full.message1"
                  defaultMessage={`**We have reached our patient capacity for the today. We apologize for the inconvenience.**`}
                />
              </H2TextTitleContent>
              <H2TextTitleSecondaryContent component={GridItem}>
                <FormattedMarkdown
                  id="interaction.appointment.walkin.full.message2"
                  defaultMessage={`**Please go to the Front Desk if you are experiencing any of the following symptoms including but not limited to Chest Pain, Shortness of Breath, possible Stroke have an open Wound or Laceration, or a possible Broken Bone.**`}
                />
              </H2TextTitleSecondaryContent>
            </Grid>
            <Grid item xs={12}>
              <DecodedButton
                onClick={() => {
                  providerStorage.clearProviderOnly() //TODO WHY REMOVE?
                  window.location = '/'
                }}
              >
                Click Here to View Availability at Our Other Clinics
              </DecodedButton>
            </Grid>
          </Grid>
        </ScrollableContainer>
      )
    } else {
      return (
        <>
          <LandingOrgSelector
            organisations={organisations}
            isSelected={true}
            handleNavigation={this.props.handleNavigation}
          />
        </>
      )
    }
  }



}

export default withTheme(withStyles(styles)(withRouter(Welcome)))
